import { BasicRes } from '@api/base/base_pb';
import { PartnerUserServiceClient } from '@api/partner/web_partner/partner_user_grpc_web_pb';
import {
  ChangePasswordReq,
  GetMeReq,
  GetMeRes,
  UpdateMeReq,
} from '@api/partner/web_partner/partner_user_pb';

import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class PartnerUserApi extends BaseApi<PartnerUserServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new PartnerUserServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }
  getMe = (): Promise<GetMeRes.AsObject> => {
    const req = new GetMeReq();
    return this.grpc<GetMeReq, GetMeRes, GetMeRes.AsObject>(
      this.serviceClient.getMe,
      req,
      {}
    );
  };
  changePassword = ({ oldPassword, password }: ChangePasswordReq.AsObject) => {
    const req = new ChangePasswordReq();
    req.setOldPassword(oldPassword);
    req.setPassword(password);
    return this.grpc<ChangePasswordReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.changePassword,
      req,
      {}
    );
  };
  updateMe = ({ status, email, name, phone }: UpdateMeReq.AsObject) => {
    const req = new UpdateMeReq();

    email && req.setEmail(email);
    name && req.setName(name);
    phone && req.setPhone(phone);
    req.setStatus(status);

    return this.grpc<UpdateMeReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.updateMe,
      req,
      {}
    );
  };
}

export const partnerUserServiceClient = new PartnerUserApi();
export default partnerUserServiceClient;
