import { CheckFillIcon, InformationFillIcon } from '@icon';
import { EModalMode } from '@web-admin/types';
import cn from 'classnames';
import React from 'react';

type Props = {
  title?: string;
  content?: React.ReactNode;
  mode: EModalMode;
  className?: string;
  classNameContent?: string;
};

const IconModal = {
  [EModalMode.CONFIRM]: (
    <InformationFillIcon className='w-[64px] h-[64px] text-common-info' />
  ),
  [EModalMode.SUCCESS]: (
    <CheckFillIcon className='w-[64px] h-[64px] text-common-success' />
  ),
  [EModalMode.WARNING]: (
    <InformationFillIcon className='w-[64px] h-[64px] text-common-warning' />
  ),
  [EModalMode.ERROR]: (
    <InformationFillIcon className='w-[60px] h-[60px] text-common-warning' />
  ),
};

const ModalContent = ({
  title,
  content,
  mode,
  className,
  classNameContent,
}: Props) => {
  return (
    <div className={cn('flex flex-col items-center w-[380px]', className)}>
      {mode !== EModalMode.ACTION && (
        <div className='flex flex-col items-center space-y-4'>
          {IconModal[mode]}
          <span className='text-lg font-semibold'>{title}</span>
        </div>
      )}
      {typeof content === 'string' ? (
        <span
          className={cn(
            'text-base text-neutral-8 font-semibold text-center',
            classNameContent
          )}>
          {content}
        </span>
      ) : (
        content
      )}
    </div>
  );
};

export default ModalContent;
