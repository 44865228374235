import { ConfirmBookingInfoCtn } from './BookingConfirm/';

type Props = {
  //
};

export function BookingConfirm({}: Props) {
  return <ConfirmBookingInfoCtn />;
}

export default BookingConfirm;
