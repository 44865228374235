import { Button, CloseIcon, Form } from '@common-ui';
import { getApiErrorMessages } from '@core/utils';
import {
  IFormChangePassword,
  partnerUserServiceClient,
} from '@tixlabs/grpc-client/web-partner';
import { useAppMutation, useToast } from '@web-booker/hooks/internals';
import { useAppDispatch } from '@web-booker/stores';
import { logout } from '@web-booker/stores/reducers/user';
import { EModalMode, IModal } from '@web-booker/types';
import { Modal } from 'antd';
import { SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import ModalContent from '../Modal/ModalContent';
import FormChangePassword from './FormChangePassword';

export enum ESwitchModal {
  NONE = 'NONE',
  CONFIRM_LOGOUT = 'CONFIRM_LOGOUT',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  SUCCESS_CHANGE_PASSWORD = 'SUCCESS_CHANGE_PASSWORD',
}

export type TModalHeader = IModal & {
  state: ESwitchModal;
};

type IFormMenuModal = IFormChangePassword;

type Props = {
  modalInfo: TModalHeader;
  handleUpdateModalInfo: (value: SetStateAction<TModalHeader>) => void;
  handleLogout: () => void;
};

const generateModalTitle = (state: ESwitchModal) => {
  const content = {
    [ESwitchModal.CONFIRM_LOGOUT]: null,
    [ESwitchModal.CHANGE_PASSWORD]: 'Đổi mật khẩu',
  };
  return content[state];
};

const generateModalContent = (state: ESwitchModal, onCancel: () => void) => {
  const content = {
    [ESwitchModal.CONFIRM_LOGOUT]: (
      <ModalContent
        mode={EModalMode.CONFIRM}
        title='Xác nhận đăng xuất'
        content='Bạn có muốn thoát khỏi phiên làm việc này ?'
      />
    ),
    [ESwitchModal.CHANGE_PASSWORD]: <FormChangePassword onCancel={onCancel} />,
    [ESwitchModal.SUCCESS_CHANGE_PASSWORD]: (
      <ModalContent mode={EModalMode.SUCCESS} title='Đổi mật khẩu thành công' />
    ),
  };
  return content[state];
};

const generateModalFooter = (
  state: ESwitchModal,
  onOk: () => void,
  onCancel: () => void
) => {
  const content = {
    [ESwitchModal.CONFIRM_LOGOUT]: (
      <div className='flex w-full space-x-2.5 whitespace-nowrap'>
        <Button theme='neutral' className='flex-1' onClick={onCancel}>
          Hủy bỏ
        </Button>
        <Button className='flex-1' onClick={onOk}>
          Đăng xuất
        </Button>
      </div>
    ),
    [ESwitchModal.CHANGE_PASSWORD]: null,
    [ESwitchModal.SUCCESS_CHANGE_PASSWORD]: (
      <div className='flex w-full space-x-2.5 whitespace-nowrap'>
        <Button className='flex-1' onClick={onOk}>
          Đóng
        </Button>
      </div>
    ),
  };
  return content[state];
};

const MenuHeaderModal = ({
  modalInfo,
  handleUpdateModalInfo,
  handleLogout,
}: Props) => {
  const dispatch = useAppDispatch();

  const toast = useToast();

  const { mutateAsync: changePassword } = useAppMutation({
    mutationKey: ['partnerUserServiceClient', 'changePasswordReq'],
    mutationFn: partnerUserServiceClient?.changePassword,
  });

  const methods = useForm<IFormMenuModal>({
    defaultValues: {
      oldPassword: '',
      password: '',
    },
  });

  const handleSubmitForm = async (data: IFormMenuModal) => {
    switch (modalInfo.state) {
      case ESwitchModal.CHANGE_PASSWORD:
        try {
          const { isSuccess, errorCode } = await changePassword({ ...data });
          if (!isSuccess) {
            throw new Error(errorCode);
          }
          handleUpdateModalInfo((pre) => ({
            ...pre,
            state: ESwitchModal.SUCCESS_CHANGE_PASSWORD,
          }));
          return;
        } catch (error) {
          const errMessage = getApiErrorMessages(error);
          if (
            errMessage[0] === 'VALIDATOR_PASSWORD' ||
            errMessage[0] === 'PARTNER_OLD_PASSWORD_WRONG'
          ) {
            methods.setError('oldPassword', {
              message: 'Mật khẩu bạn nhập không chính xác',
            });
          } else {
            toast.error(errMessage[0]);
          }
        }
        break;
      default:
        break;
    }
  };

  const handleOk = () => {
    switch (modalInfo.state) {
      case ESwitchModal.CONFIRM_LOGOUT:
        handleLogout();
        break;
      case ESwitchModal.SUCCESS_CHANGE_PASSWORD:
        dispatch(logout());
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        break;
      default:
        break;
    }
  };

  const handleCancel = () => {
    switch (modalInfo.state) {
      case ESwitchModal.CONFIRM_LOGOUT:
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        break;
      case ESwitchModal.CHANGE_PASSWORD:
        methods.reset();
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      {...modalInfo}
      closeIcon={
        modalInfo.closeIcon ? (
          <CloseIcon
            className='w-5 h-5 text-[#999999] '
            onClick={handleCancel}
          />
        ) : null
      }
      title={generateModalTitle(modalInfo.state)}
      footer={generateModalFooter(modalInfo.state, handleOk, handleCancel)}>
      <Form methods={methods} onSubmit={handleSubmitForm}>
        {generateModalContent(modalInfo.state, handleCancel)}
      </Form>
    </Modal>
  );
};

export default MenuHeaderModal;
