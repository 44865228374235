import { Card, Refund2LineIcon } from '@common-ui';

import PriceSegmentInfo from '../../Flights/PriceSegmentInfo';
import { IBookingItinerary } from '@tixlabs/grpc-client';

type Props = {
  data: IBookingItinerary;
};

export function BookingFlightPriceInfo({ data }: Props) {
  // const { formatPrice, currentCurrency } = useCurrency();

  return (
    <Card
      classNameTitle='!px-5'
      className='border-none'
      icon={<Refund2LineIcon className='w-6 h-6 text-neutral-7' />}
      title='Thông tin giá vé'>
      <div className='p-5 space-y-2.5'>
        {data.segmentsList?.map((segmentItem, index) => (
          <PriceSegmentInfo
            hubItinerary={data}
            segmentInfo={segmentItem}
            key={`${data.itineraryId}_${index}`}
          />
        ))}
      </div>
    </Card>
  );
}

export default BookingFlightPriceInfo;
