import {
  Button,
  User3FillIcon,
  Form,
  LoadingLogoFullPage,
  FormInput,
} from '@common-ui';
import { useUserStore } from '@web-booker/hooks/stores';
import {
  EModalEditUser,
  EModalMode,
  EStepChangePassword,
} from '@web-booker/types';
import React, { useEffect, useState } from 'react';
import { ModalSuccess, ModalCancel, ModalChangePassword } from './';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';

import {
  patternValidateEmail,
  patternValidateNameBooker,
  patternValidatePhoneNumber,
} from '@web-booker/utils';
import {
  IUpdateMeReq,
  partnerUserServiceClient,
} from '@tixlabs/grpc-client/web-partner';
import { useAppMutation } from '@web-booker/hooks/internals';
import ModalContent from '@web-booker/components/Modal/ModalContent';
import { useAppDispatch } from '@web-booker/stores';
import { logout } from '@web-booker/stores/reducers/user';

type Props = {
  //
};

export const UpdateProfile = (props: Props) => {
  const { userData } = useUserStore();
  const dispatch = useAppDispatch();
  const methods = useForm<IUpdateMeReq>({
    defaultValues: {
      email: userData?.email,
      name: userData?.name,
      phone: userData?.phone,
    },
  });
  const navigate = useNavigate();
  const [stepChangePassword, setStepChangePassword] =
    useState<EStepChangePassword>(EStepChangePassword.CHANGE_PASSWORD);
  const [stateModal, setStateModal] = useState<EModalEditUser>(
    EModalEditUser.CONFIRM
  );
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { mutateAsync: updateMe } = useAppMutation({
    mutationKey: ['partnerUserServiceClient', 'updateMe'],
    mutationFn: partnerUserServiceClient.updateMe,
    onSuccess: ({ isSuccess }) => {
      //
      if (isSuccess) {
        setIsOpenModal(true);
      } else {
        // handle errorCode when not successful
      }
    },
    onError: (error) => {
      // handle error (system, network)
    },
  });

  const genarateModalContent = (state: EModalEditUser) => {
    const content = {
      [EModalEditUser.CONFIRM]: (
        <ModalContent
          mode={EModalMode.SUCCESS}
          className='w-full'
          content={'Cập nhật thông tin thành công.'}
        />
      ),
      [EModalEditUser.CHANGE_PASSWORD]: {
        [EStepChangePassword.CHANGE_PASSWORD]: (
          <ModalChangePassword
            onChangePasswordSuccess={() =>
              setStepChangePassword(EStepChangePassword.SUCCESS)
            }
            onCloseModal={() => {
              setIsOpenModal(false);
              setStepChangePassword(EStepChangePassword.CHANGE_PASSWORD);
              setStateModal(EModalEditUser.CONFIRM);
            }}
          />
        ),
        [EStepChangePassword.SUCCESS]: (
          <ModalContent
            mode={EModalMode.SUCCESS}
            className='w-full'
            title='Đổi mật khẩu thành công'
            content='Vui lòng đăng nhập lại sau khi đổi mật khẩu.'
          />
        ),
      },
    };
    if (state === EModalEditUser.CHANGE_PASSWORD) {
      return content[state][stepChangePassword];
    }
    return content[state];
  };

  const genarateModalFooter = (state: EModalEditUser, onClick: () => void) => {
    const content = {
      [EModalEditUser.CONFIRM]: (
        <div className='flex flex-col'>
          <Button
            onClick={() => {
              setIsOpenModal(false);
            }}
            className='px-7 py-2.5'>
            Đóng
          </Button>
        </div>
      ),
      [EModalEditUser.CANCEL]: (
        <div className='flex w-full space-x-2.5'>
          <Button
            theme='neutral'
            className='flex-1'
            onClick={() => {
              navigate('/profile');
            }}>
            Xác nhận huỷ bỏ
          </Button>
          <Button
            className='flex-1'
            onClick={() => {
              setIsOpenModal(false);
              setStateModal(EModalEditUser.CONFIRM);
            }}>
            Tiếp tục chỉnh sửa
          </Button>
        </div>
      ),
      [EModalEditUser.CHANGE_PASSWORD]: {
        [EStepChangePassword.SUCCESS]: (
          <div className='flex flex-col'>
            <Button onClick={onClick} className='px-7 py-2.5'>
              Đóng
            </Button>
          </div>
        ),
        [EStepChangePassword.CHANGE_PASSWORD]: null,
      },
    };

    if (state === EModalEditUser.CHANGE_PASSWORD) {
      return content[state][stepChangePassword];
    }

    return content[state];
  };

  const handleOk = () => {
    setIsOpenModal(false);
    dispatch(logout());
  };

  const handleSubmit = async (data: IUpdateMeReq) => {
    if (userData) {
      await updateMe({ ...data, status: userData.status });
    }
  };
  const data = methods.watch();
  useEffect(() => {
    if (userData) {
      methods.reset({
        email: userData.email,
        name: userData.name,
        phone: userData.phone,
        status: userData.status,
      });
    }
  }, [userData]);

  return userData ? (
    <>
      <Modal
        open={isOpenModal}
        closeIcon={false}
        footer={genarateModalFooter(stateModal, handleOk)}
        centered={true}>
        {genarateModalContent(stateModal)}
      </Modal>
      <Form
        methods={methods}
        onSubmit={handleSubmit}
        className='flex flex-col space-y-3 max-w-[900px]'>
        <div className='flex flex-col space-y-5'>
          <div className='flex space-x-2.5 items-center'>
            <User3FillIcon className='w-5 h-5 shrink-0' />
            <span className='font-semibold text-lg'>Thông tin tài khoản</span>
          </div>
          <div className='flex flex-col space-y-5 bg-white border rounded p-4'>
            <div className='flex flex-col space-y-1.5'>
              <div className='flex flex-col space-y-2 5'>
                <h3 className='text-[15px] leading-[20px] font-semibold text-black'>
                  Thông tin tài khoản
                </h3>
                <div className='border-b border-neutral-5'></div>
              </div>
              <div className='flex items-baseline space-x-2.5'>
                <FormInput
                  name='userName'
                  placeholder='Nhập tên đăng nhập'
                  label='Tên đăng nhập'
                  inputProps={{
                    disabled: true,
                  }}
                  value={userData?.userName}
                />

                <div className='flex-1 self-end'>
                  <Button
                    className='min-h-[39px] min-w-[180px]'
                    onClick={() => {
                      setIsOpenModal(true);
                      setStateModal(EModalEditUser.CHANGE_PASSWORD);
                      setStepChangePassword(
                        EStepChangePassword.CHANGE_PASSWORD
                      );
                    }}>
                    Đổi mật khẩu
                  </Button>
                </div>
              </div>
            </div>
            <div className='flex flex-col space-y-1.5'>
              <div className='flex flex-col space-y-2 5'>
                <h3 className='text-[15px] leading-[20px] font-semibold text-black'>
                  Thông tin liên hệ
                </h3>
                <div className='border-b border-neutral-5'></div>
              </div>
              <div className='flex items-baseline space-x-2.5'>
                <FormInput
                  rules={{
                    minLength: {
                      value: 1,
                      message: 'Tên thành viên ít nhất 1 kí tự',
                    },
                    maxLength: {
                      value: 20,
                      message: 'Tên thành viên tối đa 20 kí tự',
                    },
                    pattern: {
                      value: patternValidateNameBooker,
                      message: 'Tên thành viên không được chứa kí tự đặc biệt',
                    },
                  }}
                  name='name'
                  placeholder='Nhập tên tài khoản'
                  label='Tên tài khoản'
                  inputProps={{
                    defaultValue: userData?.name,
                  }}
                />
                <FormInput
                  rules={{
                    pattern: {
                      value: patternValidateEmail,
                      message: 'Email không đúng định dạng',
                    },
                  }}
                  name='email'
                  label='Email'
                  placeholder='Nhập email'
                  inputProps={{
                    defaultValue: userData?.email,
                  }}
                />
                <FormInput
                  rules={{
                    pattern: {
                      value: patternValidatePhoneNumber,
                      message: 'Số điện thoại chỉ bao gồm số.',
                    },
                    maxLength: {
                      value: 13,
                      message: 'Số điện thoại tối đa 13 kí tự.',
                    },
                  }}
                  name='phone'
                  label='Số điện thoại'
                  placeholder='Nhập số điện thoại'
                  inputProps={{
                    defaultValue: userData?.phone,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-row items-center justify-end gap-4'>
          <Button
            theme='primary'
            type='submit'
            disabled={
              userData?.phone === data.phone &&
              userData?.email === data.email &&
              userData?.name === data.name &&
              userData?.status === +data.status
            }>
            Cập nhật
          </Button>
        </div>
      </Form>
    </>
  ) : (
    <LoadingLogoFullPage />
  );
};

export default UpdateProfile;
