import { Card, FilterAirLineIcon, FormCheckbox } from '@common-ui';

import { IFormFilterFlight } from '@web-booker/types/ui/flight';

import { useFieldArray, useFormContext } from 'react-hook-form';

type Props = {
  //
};

const AirlineBrand = (props: Props) => {
  const methods = useFormContext<IFormFilterFlight>();
  const { fields } = useFieldArray({
    control: methods.control,
    name: 'airlines',
  });

  if (fields.length === 0) return;

  return (
    <div className='shrink-0'>
      <Card
        isCollapse
        icon={<FilterAirLineIcon />}
        title='Hãng hàng không'
        classNameTitle='bg-[#E8E8E8]'
        classNameChildren='p-2.5'
        defaultShow>
        <div className='flex min-w-[340px] flex-col space-y-2.5'>
          {fields.map((item, index) => {
            return (
              <div className='grid grid-cols-2 items-center' key={item.id}>
                <FormCheckbox
                  className='col-span-1'
                  name={`airlines[${index}].isSelected`}
                  label={item.label}
                />
                {/* <div className='col-span-1 flex items-center justify-end'>
              <span className='text-xs text-[#F9813D]'>{item.price}</span>
            </div> */}
              </div>
            );
          })}
        </div>
      </Card>
    </div>
  );
};

export default AirlineBrand;
