import { EItineraryType } from '@web-booker/types';
import { itineraryTypeLabel } from '@web-booker/utils';
import cn from 'classnames';

type TProps = {
  type: EItineraryType;
  className?: string;
};

export const ItineraryType = ({ type, className }: TProps) => {
  return (
    <div
      className={cn(
        'rounded-md px-2 py-1 text-xs font-thin text-white',
        {
          'bg-flight-type-one': type === EItineraryType.ONE_WAY,
          'bg-flight-type-round': type === EItineraryType.ROUND_TRIP,
          'bg-flight-type-multiple': type === EItineraryType.MULTIPLE_TRIP,
        },
        className
      )}>
      {itineraryTypeLabel[type]}
    </div>
  );
};
