import { CheckLineIcon } from '@icon';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';

const STEP_RENDER = [
  {
    route: 'search',
    title: 'Chuyến bay',
    description: 'Chọn chuyến bay của bạn',
  },
  {
    route: 'information',
    title: 'Hành khách',
    description: 'Nhập thông tin hành khách',
  },
  {
    route: 'confirm',
    title: 'Hoàn thành',
    description: 'Xác nhận thông tin & thanh toán',
  },
];

const StepItem = ({
  index,
  title,
  description,
}: {
  index: number;
  title: string;
  description: string;
}) => {
  const { pathname } = useLocation();
  const isActive =
    index <=
    STEP_RENDER.findIndex((item) => item.route === pathname.split('/')[2]);
  const isPassed =
    index <
    STEP_RENDER.findIndex((item) => item.route === pathname.split('/')[2]);
  return (
    <div
      className={cn(
        'flex space-x-2.5 bg-theme-gray-200 items-center py-2.5 px-4 flex-1',
        {
          '!bg-green-6': isActive,

          'rounded-l': index === 0,
          'rounded-r': index === STEP_RENDER.length - 1,
        }
      )}>
      <div
        className={cn(
          'w-9 h-9 rounded-full bg-white flex justify-center items-center text-[#666666] font-semibold',
          {
            'text-green-6': isActive,
          }
        )}>
        {isPassed ? <CheckLineIcon /> : index + 1}
      </div>
      <div className='flex flex-col'>
        <span
          className={cn('text-md font-semibold text-[#333333]', {
            'text-white': isActive,
          })}>
          {title}
        </span>
        <span
          className={cn('text-[#666666]', {
            'text-white': isActive,
          })}>
          {description}
        </span>
      </div>
    </div>
  );
};

export const StepBar = () => {
  return (
    <div id='search-step-bar' className='flex space-x-[3px]'>
      {STEP_RENDER.map((item, index) => {
        return (
          <StepItem
            key={index}
            index={index}
            title={item.title}
            description={item.description}
          />
        );
      })}
    </div>
  );
};

export default StepBar;
