import ModalContent from '@web-admin/components/Modal/ModalContent';
import { EModalMode } from '@web-admin/types';
import React from 'react';

type Props = {
  //
  title?: string;
  content?: string;
};

export const ModalSuccess = ({ title, content }: Props) => {
  return (
    <ModalContent mode={EModalMode.SUCCESS} title={title} content={content} />
  );
};
