import { BaseApi as BaseApiCore, BaseApiOptions } from '@core/grpc-client';

type TBasicRes = {
  isSuccess: boolean;
  errorCode: string;
  errorMessage: string;
};

export function instanceOfBaseRes(object: any): object is TBasicRes {
  return 'isSuccess' in object && 'errorCode' in object;
}

export class BaseApi<S> extends BaseApiCore<S> {
  static messageErrorData: Record<string, string> = {};

  static addMessageError(data: Record<string, string>) {
    BaseApi.messageErrorData = data;
  }

  transformData<As>(data: As): As & {
    errorMessage: string;
  } {
    if (instanceOfBaseRes(data)) {
      if (!data.isSuccess && data.errorCode) {
        return {
          ...data,
          errorMessage: BaseApi.messageErrorData[data.errorCode] || '',
        };
      }
    }

    return {
      ...data,
      errorMessage: '',
    };
  }

  constructor(options: BaseApiOptions = {}) {
    super(options);
  }
}
