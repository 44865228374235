import { Card, ProfileFillIcon } from '@common-ui';
import { EPassengerType, IBookingFlightPassenger } from '@tixlabs/types';
import { useCountry } from '@web-booker/hooks/apps';
import { useTime } from '@web-booker/hooks/internals';

type Props = {
  passengerType: EPassengerType;
  passenger: IBookingFlightPassenger;
};

const AdditionalInfo = ({ passengerType, passenger }: Props) => {
  const { formatDate } = useTime();
  const { countriesObjectByCode } = useCountry();
  return (
    <Card
      title='Thông tin bổ sung'
      icon={<ProfileFillIcon />}
      classNameTitle='text-sm'
      isCollapse
      classNameChildren='p-2.5'>
      <div className='flex flex-col space-y-5'>
        <div className='grid grid-flow-col grid-cols-12'>
          <div className='col-span-3 flex flex-col space-y-1'>
            <span>Số hộ chiếu</span>
            <span className='font-semibold'>{passenger.passportNumber}</span>
          </div>
          <div className='col-span-3 flex flex-col space-y-1'>
            <span>Quốc tịch</span>
            <span className='font-semibold'>
              {passenger.nationality &&
                countriesObjectByCode[passenger.nationality]?.country}
            </span>
          </div>
          <div className='col-span-3 flex flex-col space-y-1'>
            <span>Nơi cấp</span>
            <span className='font-semibold'>
              {passenger.idIssueCountry &&
                countriesObjectByCode[passenger.idIssueCountry]?.country}
            </span>
          </div>
          <div className='col-span-3 flex flex-col space-y-1'>
            <span>Ngày hết hạn</span>
            <span className='font-semibold'>
              {formatDate(passenger.expirationDate)}
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AdditionalInfo;
