/**
 * @fileoverview gRPC-Web generated client stub for customer.web
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: customer/web/auth_phone.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var validate_validate_pb = require('../../validate/validate_pb.js')

var base_base_pb = require('../../base/base_pb.js')

var customer_base_pb = require('../../customer/base_pb.js')

var notification_backend_verify_otp_pb = require('../../notification/backend/verify_otp_pb.js')
const proto = {};
proto.customer = {};
proto.customer.web = require('./auth_phone_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.customer.web.AuthPhoneServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.customer.web.AuthPhoneServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web.SendOtpByPhoneReq,
 *   !proto.customer.web.SendOtpByPhoneRes>}
 */
const methodDescriptor_AuthPhoneService_SendOtpByPhone = new grpc.web.MethodDescriptor(
  '/customer.web.AuthPhoneService/SendOtpByPhone',
  grpc.web.MethodType.UNARY,
  proto.customer.web.SendOtpByPhoneReq,
  proto.customer.web.SendOtpByPhoneRes,
  /**
   * @param {!proto.customer.web.SendOtpByPhoneReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.customer.web.SendOtpByPhoneRes.deserializeBinary
);


/**
 * @param {!proto.customer.web.SendOtpByPhoneReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.customer.web.SendOtpByPhoneRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer.web.SendOtpByPhoneRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web.AuthPhoneServiceClient.prototype.sendOtpByPhone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web.AuthPhoneService/SendOtpByPhone',
      request,
      metadata || {},
      methodDescriptor_AuthPhoneService_SendOtpByPhone,
      callback);
};


/**
 * @param {!proto.customer.web.SendOtpByPhoneReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer.web.SendOtpByPhoneRes>}
 *     Promise that resolves to the response
 */
proto.customer.web.AuthPhoneServicePromiseClient.prototype.sendOtpByPhone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web.AuthPhoneService/SendOtpByPhone',
      request,
      metadata || {},
      methodDescriptor_AuthPhoneService_SendOtpByPhone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web.VerifyPhoneNumberReq,
 *   !proto.base.Empty>}
 */
const methodDescriptor_AuthPhoneService_VerifyPhoneNumber = new grpc.web.MethodDescriptor(
  '/customer.web.AuthPhoneService/VerifyPhoneNumber',
  grpc.web.MethodType.UNARY,
  proto.customer.web.VerifyPhoneNumberReq,
  base_base_pb.Empty,
  /**
   * @param {!proto.customer.web.VerifyPhoneNumberReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.customer.web.VerifyPhoneNumberReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web.AuthPhoneServiceClient.prototype.verifyPhoneNumber =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web.AuthPhoneService/VerifyPhoneNumber',
      request,
      metadata || {},
      methodDescriptor_AuthPhoneService_VerifyPhoneNumber,
      callback);
};


/**
 * @param {!proto.customer.web.VerifyPhoneNumberReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.Empty>}
 *     Promise that resolves to the response
 */
proto.customer.web.AuthPhoneServicePromiseClient.prototype.verifyPhoneNumber =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web.AuthPhoneService/VerifyPhoneNumber',
      request,
      metadata || {},
      methodDescriptor_AuthPhoneService_VerifyPhoneNumber);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web.ResetPasswordByPhoneReq,
 *   !proto.base.Empty>}
 */
const methodDescriptor_AuthPhoneService_ResetPasswordByPhone = new grpc.web.MethodDescriptor(
  '/customer.web.AuthPhoneService/ResetPasswordByPhone',
  grpc.web.MethodType.UNARY,
  proto.customer.web.ResetPasswordByPhoneReq,
  base_base_pb.Empty,
  /**
   * @param {!proto.customer.web.ResetPasswordByPhoneReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.customer.web.ResetPasswordByPhoneReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web.AuthPhoneServiceClient.prototype.resetPasswordByPhone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web.AuthPhoneService/ResetPasswordByPhone',
      request,
      metadata || {},
      methodDescriptor_AuthPhoneService_ResetPasswordByPhone,
      callback);
};


/**
 * @param {!proto.customer.web.ResetPasswordByPhoneReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.Empty>}
 *     Promise that resolves to the response
 */
proto.customer.web.AuthPhoneServicePromiseClient.prototype.resetPasswordByPhone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web.AuthPhoneService/ResetPasswordByPhone',
      request,
      metadata || {},
      methodDescriptor_AuthPhoneService_ResetPasswordByPhone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web.VerifyOtpToResetPasswordByPhoneReq,
 *   !proto.customer.web.VerifyOtpToResetPasswordByPhoneRes>}
 */
const methodDescriptor_AuthPhoneService_VerifyOtpToResetPasswordByPhone = new grpc.web.MethodDescriptor(
  '/customer.web.AuthPhoneService/VerifyOtpToResetPasswordByPhone',
  grpc.web.MethodType.UNARY,
  proto.customer.web.VerifyOtpToResetPasswordByPhoneReq,
  proto.customer.web.VerifyOtpToResetPasswordByPhoneRes,
  /**
   * @param {!proto.customer.web.VerifyOtpToResetPasswordByPhoneReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.customer.web.VerifyOtpToResetPasswordByPhoneRes.deserializeBinary
);


/**
 * @param {!proto.customer.web.VerifyOtpToResetPasswordByPhoneReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.customer.web.VerifyOtpToResetPasswordByPhoneRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer.web.VerifyOtpToResetPasswordByPhoneRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web.AuthPhoneServiceClient.prototype.verifyOtpToResetPasswordByPhone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web.AuthPhoneService/VerifyOtpToResetPasswordByPhone',
      request,
      metadata || {},
      methodDescriptor_AuthPhoneService_VerifyOtpToResetPasswordByPhone,
      callback);
};


/**
 * @param {!proto.customer.web.VerifyOtpToResetPasswordByPhoneReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer.web.VerifyOtpToResetPasswordByPhoneRes>}
 *     Promise that resolves to the response
 */
proto.customer.web.AuthPhoneServicePromiseClient.prototype.verifyOtpToResetPasswordByPhone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web.AuthPhoneService/VerifyOtpToResetPasswordByPhone',
      request,
      metadata || {},
      methodDescriptor_AuthPhoneService_VerifyOtpToResetPasswordByPhone);
};


module.exports = proto.customer.web;

