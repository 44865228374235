import cn from 'classnames';

import Button, { ButtonType } from './Button';
import { LoadingProps, LoadingRing, TLoadingSize } from './Loading';

type Props = {
  loading?: boolean;
  loadingSize?: TLoadingSize;
  loadingProps?: LoadingProps;
  isShowChildWhenLoading?: boolean;
};

export function ButtonLoading({
  loading,
  loadingProps,
  isShowChildWhenLoading,
  children,
  loadingSize,
  isDisabled,
  ...rest
}: Props & ButtonType) {
  return (
    <Button
      isDisabled={loading || isDisabled}
      {...rest}
      prefixIcon={loading ? null : rest.prefixIcon}
      suffixIcon={loading ? null : rest.suffixIcon}>
      <div className='flex items-center justify-center space-x-1'>
        {loading && (
          <LoadingRing
            size={rest.size || loadingSize || 'sm'}
            className={cn('!border-t-theme-black/30', loadingProps?.className, {
              'mr-1': isShowChildWhenLoading,
            })}
          />
        )}
        {(isShowChildWhenLoading || (!isShowChildWhenLoading && !loading)) &&
          children}
      </div>
    </Button>
  );
}

export default ButtonLoading;
