import { default as getObjValue } from 'lodash.get';

import { useState } from 'react';

import Papa from 'papaparse';

type ColumnValues = Record<string, any>;
type TExportColumn<
  T extends ColumnValues = ColumnValues,
  TKey extends keyof T = keyof ColumnValues,
  TValue extends T[TKey] = ColumnValues[keyof ColumnValues]
> = {
  key: TKey;
  name: string;
  preventExport?: boolean;
  formatValue?: (value: TValue, row: T) => string | number | null;
};

type TExportAgr<T extends object> = {
  rows?: T[];
  columns: TExportColumn<T>[];
  filename?: string;
};
export function useExportData<T extends object>({
  rows,
  columns,
  filename = 'download',
}: TExportAgr<T>) {
  const [isProcessing, setIsProcessing] = useState(false);

  const downLoadFilename = `${filename}.csv`;

  function downloadByUrl(url: string) {
    const tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', downLoadFilename);
    tempLink.click();
  }

  function convertData(rows: T[], columns: TExportColumn<T>[]) {
    if (!columns?.length) {
      return {
        fields: [],
        data: [],
      };
    }

    const fields: string[] = [];
    const filterColumns: Omit<TExportColumn<T>, 'name' | 'preventExport'>[] =
      [];

    columns.forEach(({ name, preventExport, ...rest }) => {
      if (!preventExport) {
        fields.push(name);
        filterColumns.push(rest);
      }
    });

    return {
      fields,
      data: rows.map((row, index) => {
        return filterColumns.map(({ key, formatValue }) => {
          return typeof formatValue === 'function'
            ? formatValue(getObjValue(row, key), row)
            : getObjValue(row, key);
        });
      }),
    };
  }

  async function handleExport(rowsData: T[]) {
    try {
      setIsProcessing(true);

      // const t0 = performance.now()
      const { data, fields } = convertData(rowsData ?? rows, columns);
      // const t1 = performance.now()

      // console.log(`convertData: ${t1 - t0} milliseconds.`)

      const csv = Papa.unparse({
        data,
        fields,
      });

      // const t2 = performance.now()

      // console.log(`Papa.unparse: ${t2 - t1} milliseconds.`)

      const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      let csvURL = '';
      if ('msSaveBlob' in navigator) {
        csvURL = navigator.msSaveBlob(csvData, downLoadFilename);
      } else {
        csvURL = window.URL.createObjectURL(csvData);
      }

      // const t3 = performance.now()
      // console.log(`createObjectURL: ${t3 - t2} milliseconds.`)
      downloadByUrl(csvURL);

      // const t4 = performance.now()
      // console.log(`download: ${t4 - t3} milliseconds.`)
    } catch (error) {
      // const { message } = errorHandler(error);
      console.error('error', error);
    } finally {
      setIsProcessing(false);
    }
  }

  return {
    handleExport,
    isProcessing,
  };
}
export default useExportData;
