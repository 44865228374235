export interface IObservableData<T> {
  data: T;
  type?: string;
}

export type IObservableCallBack<T> = (name: IObservableData<T>) => void;

export default class Observable<T> {
  #observers: IObservableCallBack<T>[] = [];
  constructor() {
    this.#observers = [];
  }

  subscribe(func: IObservableCallBack<T>) {
    this.#observers.push(func);
  }

  unsubscribe(func: IObservableCallBack<T>) {
    this.#observers = this.#observers.filter((observer) => observer !== func);
  }

  notify(data: IObservableData<T>) {
    this.#observers.forEach((observer) => observer(data));
  }
}
