import ModalContent from '@web-booker/components/Modal/ModalContent';
import { EModalMode } from '@web-booker/types';
import React from 'react';

const ConfirmIssueTicket = () => {
  return (
    <ModalContent
      mode={EModalMode.CONFIRM}
      title='Xác nhận xuất vé'
      content='Bạn đang xuất vé chuyến bay, bạn có muốn tiếp tục?'
    />
  );
};

export default ConfirmIssueTicket;
