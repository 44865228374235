import { AccessToken } from '@api/customer/base_pb';
import { AuthServiceClient } from '@api/customer/web/auth_grpc_web_pb';
import {
  LoginReq,
  RegisterByOtpReq,
  RegisterReq,
} from '@api/customer/web/auth_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from './base-api';

export { LoginReq, RegisterByOtpReq };

class AuthApi extends BaseApi<AuthServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new AuthServiceClient(this.url, null, this.optsDev);
  }

  convertRegisterReq({
    authenticationType,
    name,
    password,
    phoneCode,
    phoneNumber,
  }: RegisterReq.AsObject): RegisterReq {
    const req = new RegisterReq();
    req.setAuthenticationType(authenticationType);
    req.setName(name);
    req.setPassword(password);
    req.setPhoneCode(phoneCode);
    req.setPhoneNumber(phoneNumber);

    return req;
  }

  register = ({
    otp,
    info,
  }: RegisterByOtpReq.AsObject): Promise<AccessToken.AsObject> => {
    const req = new RegisterByOtpReq();
    req.setOtp(otp);
    if (info) {
      req.setInfo(this.convertRegisterReq(info));
    }

    return this.grpc<RegisterByOtpReq, AccessToken, AccessToken.AsObject>(
      this.serviceClient?.register,
      req,
      {}
    );
  };

  registerAnonymousUser = ({
    otp,
    info,
  }: RegisterByOtpReq.AsObject): Promise<AccessToken.AsObject> => {
    const req = new RegisterByOtpReq();
    req.setOtp(otp);
    if (info) {
      req.setInfo(this.convertRegisterReq(info));
    }

    return this.grpc<RegisterByOtpReq, AccessToken, AccessToken.AsObject>(
      this.serviceClient?.register,
      req,
      {
        useAnonymousToken: true,
      }
    );
  };
  login = ({
    password,
    phoneCode,
    phoneNumber,
  }: LoginReq.AsObject): Promise<AccessToken.AsObject> => {
    const req = new LoginReq();

    req.setPassword(password);
    req.setPhoneCode(phoneCode);
    req.setPhoneNumber(phoneNumber);

    return this.grpc<LoginReq, AccessToken, AccessToken.AsObject>(
      this.serviceClient?.login,
      req,
      {}
    );
  };
}

export const authServiceClientApi = new AuthApi();
export default authServiceClientApi;
