import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import sample from './sample';
// import samplePersist from './sample-persist';
// import additionalBaggage from './additional-baggage';
// import booking from './booking';
import common from './common';
// import searchFlight from './searchFlight';
import user from './user';

export const rootReducer = combineReducers({
  common: persistReducer(
    {
      key: 'common-persist',
      version: 1.2,
      storage,
    },
    common
  ),
  user,
});

export default rootReducer;
