// source: airplane/web_partner/service_fee.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var airplane_base_pb = require('../../airplane/base_pb.js');
goog.object.extend(proto, airplane_base_pb);
var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
goog.exportSymbol('proto.airplane.web_partner.ListFlightServiceFeeReq', null, global);
goog.exportSymbol('proto.airplane.web_partner.ListFlightServiceFeeRes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.ListFlightServiceFeeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partner.ListFlightServiceFeeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.ListFlightServiceFeeReq.displayName = 'proto.airplane.web_partner.ListFlightServiceFeeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partner.ListFlightServiceFeeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partner.ListFlightServiceFeeRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partner.ListFlightServiceFeeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partner.ListFlightServiceFeeRes.displayName = 'proto.airplane.web_partner.ListFlightServiceFeeRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.ListFlightServiceFeeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.ListFlightServiceFeeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.ListFlightServiceFeeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ListFlightServiceFeeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.ListFlightServiceFeeReq}
 */
proto.airplane.web_partner.ListFlightServiceFeeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.ListFlightServiceFeeReq;
  return proto.airplane.web_partner.ListFlightServiceFeeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.ListFlightServiceFeeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.ListFlightServiceFeeReq}
 */
proto.airplane.web_partner.ListFlightServiceFeeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.ListFlightServiceFeeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.ListFlightServiceFeeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.ListFlightServiceFeeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ListFlightServiceFeeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional base.PaginationReq pagination = 1;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web_partner.ListFlightServiceFeeReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 1));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web_partner.ListFlightServiceFeeReq} returns this
*/
proto.airplane.web_partner.ListFlightServiceFeeReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.ListFlightServiceFeeReq} returns this
 */
proto.airplane.web_partner.ListFlightServiceFeeReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.ListFlightServiceFeeReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partner.ListFlightServiceFeeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partner.ListFlightServiceFeeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_base_pb.FlightServiceFee.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partner.ListFlightServiceFeeRes}
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partner.ListFlightServiceFeeRes;
  return proto.airplane.web_partner.ListFlightServiceFeeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partner.ListFlightServiceFeeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partner.ListFlightServiceFeeRes}
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_base_pb.FlightServiceFee;
      reader.readMessage(value,airplane_base_pb.FlightServiceFee.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partner.ListFlightServiceFeeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partner.ListFlightServiceFeeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      airplane_base_pb.FlightServiceFee.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partner.ListFlightServiceFeeRes} returns this
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partner.ListFlightServiceFeeRes} returns this
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated airplane.FlightServiceFee items = 3;
 * @return {!Array<!proto.airplane.FlightServiceFee>}
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.FlightServiceFee>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_base_pb.FlightServiceFee, 3));
};


/**
 * @param {!Array<!proto.airplane.FlightServiceFee>} value
 * @return {!proto.airplane.web_partner.ListFlightServiceFeeRes} returns this
*/
proto.airplane.web_partner.ListFlightServiceFeeRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.FlightServiceFee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.FlightServiceFee}
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.FlightServiceFee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partner.ListFlightServiceFeeRes} returns this
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web_partner.ListFlightServiceFeeRes} returns this
*/
proto.airplane.web_partner.ListFlightServiceFeeRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partner.ListFlightServiceFeeRes} returns this
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partner.ListFlightServiceFeeRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.airplane.web_partner);
