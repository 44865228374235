import {
  SCREEN_BREAK_POINT_VALUE,
  TScreenBreakpoint,
} from '@web-admin/types/constants';
import { useEffect, useState } from 'react';

/**
 *
 * @param {SCREEN_BREAK_POINTE} query "sm" | "md" | "lg" | "xl" for mobile first
 *
 * - Or you can use custom media query string for specical case
 *
 */

export function useMediaQuery(query: TScreenBreakpoint | string): boolean {
  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
      return window.matchMedia(getMediaQueryString(query)).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState<boolean>(getMatches(query));

  function handleChange() {
    setMatches(getMatches(query));
  }

  function getMediaQueryString(query: string) {
    let mediaQuery = query;

    if (Object.keys(SCREEN_BREAK_POINT_VALUE).includes(mediaQuery)) {
      mediaQuery = `(min-width: ${
        SCREEN_BREAK_POINT_VALUE[query as TScreenBreakpoint]
      })`;
    }

    return mediaQuery;
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(getMediaQueryString(query));

    // Triggered at the first client-side load and if query changes
    handleChange();

    matchMedia.addEventListener('change', handleChange);

    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
}

export default useMediaQuery;
