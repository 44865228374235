import { Card } from '@common-ui';
import { EPassengerType, IBookingFlightPassenger } from '@tixlabs/types';
import { useTime } from '@web-booker/hooks/internals';
import {
  GENDER_TYPE_LABEL,
  PASSENGER_TYPE_LABEL,
} from '@web-booker/utils/constants/booking';
import AdditionalInfo from './AdditionalInfo';

type Props = {
  indexPassenger: number;
  passengerType: EPassengerType;
  passenger: IBookingFlightPassenger;
};

export const ConfirmInfoByTypePassenger = ({
  indexPassenger,
  passengerType,
  passenger,
}: Props) => {
  const { formatDate } = useTime();

  return (
    <Card
      title={PASSENGER_TYPE_LABEL[passengerType]}
      icon={
        <div className='w-5 h-5 text-center bg-primary rounded-full text-white text-sm'>
          {indexPassenger + 1}
        </div>
      }
      className='!bg-[#F9F9F9]'
      classNameTitle='text-sm'
      isCollapse
      defaultShow
      classNameChildren='p-2.5'>
      <div className='flex flex-col space-y-4'>
        <div className='px-[11px]'>
          <div className='grid grid-flow-col grid-cols-12'>
            <div className='ml-[-11px] col-span-3 flex flex-col space-y-1'>
              <span>Họ và tên</span>
              <span className='font-semibold'>{`${passenger.lastName} ${passenger.firstName}`}</span>
            </div>
            <div className='col-span-3 flex flex-col space-y-1'>
              <span>Giới tính</span>
              <span className='font-semibold'>
                {GENDER_TYPE_LABEL[passenger.gender]}
              </span>
            </div>

            <div className='col-span-3 flex flex-col space-y-1'>
              <span>Ngày sinh</span>
              <span className='font-semibold'>{formatDate(passenger.dob)}</span>
            </div>
          </div>
        </div>
        <div className='flex flex-col space-y-2.5'>
          {passenger.passportNumber && (
            <AdditionalInfo
              passenger={passenger}
              passengerType={passengerType}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default ConfirmInfoByTypePassenger;
