import { flightApiService } from '@tixlabs/grpc-client/web-partner';
import {
  IAirportInfo,
  IGroupAirport,
  TGroupAirport,
} from '@tixlabs/grpc-client/web-partner';
import { useState } from 'react';
import { useAppMutation } from '../internals';
import useCities from './useCities';
import useCountry from './useCountry';

export function useSearchAirport() {
  const [airportsList, setAirportsList] = useState<IAirportInfo[]>([]);
  const { countriesObjectByCode } = useCountry();
  const { getCityNameByCode } = useCities();

  const {
    mutate: getAirports,
    isLoading,
    isError,
    isSuccess,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'searchAirports'],
    mutationFn: flightApiService.searchAirports,
    onSuccess: (data) => {
      setAirportsList(data.itemsList);
    },
  });
  function groupFlightByCityName(data: IAirportInfo[]): IGroupAirport[] {
    const results: TGroupAirport = {};
    data.forEach((item) => {
      if (item.cityCode && item.countryCode) {
        const patternKey = `${item.countryCode}_${item.cityCode}`;
        const currentData = results[patternKey];
        if (currentData) {
          results[patternKey].airportList = [...currentData.airportList, item];
        } else {
          results[patternKey] = {
            label: [
              getCityNameByCode(item.cityCode),
              countriesObjectByCode[item.countryCode]?.country ||
                item.countryCode,
            ].join(', '),
            airportList: [
              {
                ...item,
                city: getCityNameByCode(item.cityCode),
                country: countriesObjectByCode[item.countryCode]?.country,
              },
            ],
          };
        }
      }
    });

    return Object.values(results);
  }

  function handleSearch(throttleQuery: string) {
    if (throttleQuery) {
      getAirports({
        filter: { searchText: throttleQuery },
      });
    }
  }

  return {
    airportsList,
    groupFlightData: groupFlightByCityName(airportsList),
    isLoading,
    isError,
    isSuccess,
    //
    groupFlightByCityName,
    handleSearch,
  };
}

export default useSearchAirport;
