import { ConfigureStoreOptions, configureStore } from '@reduxjs/toolkit';

import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistStore,
} from 'redux-persist';
export function configureAppStore<T = unknown>(
  params: ConfigureStoreOptions<T>
) {
  const store = configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    ...params,
    devTools: process.env['NODE_ENV'] !== 'production',
  });
  const persistor = persistStore(store);

  return { store, persistor };
}
