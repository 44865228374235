import TextField, { TextFieldProps } from './TextField';

import Checkbox, { CheckboxProps } from '../UI/Checkbox';

export type FormCheckboxType = TextFieldProps & { inputProps?: CheckboxProps };
export function FormCheckbox({ inputProps = {}, ...rest }: FormCheckboxType) {
  return (
    <TextField
      {...rest}
      labelProps={{
        layout: 'horizontal',
        // className: 'flex-row-reverse',
        labelClassName: 'cursor-pointer',
        isReverse: true,
        ...rest.labelProps,
      }}>
      <Checkbox {...inputProps} />
    </TextField>
  );
}

export default FormCheckbox;
