// source: customer/web/auth_phone.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var customer_base_pb = require('../../customer/base_pb.js');
goog.object.extend(proto, customer_base_pb);
var notification_backend_verify_otp_pb = require('../../notification/backend/verify_otp_pb.js');
goog.object.extend(proto, notification_backend_verify_otp_pb);
goog.exportSymbol('proto.customer.web.ResetPasswordByPhoneReq', null, global);
goog.exportSymbol('proto.customer.web.SendOtpByPhoneReq', null, global);
goog.exportSymbol('proto.customer.web.SendOtpByPhoneRes', null, global);
goog.exportSymbol('proto.customer.web.VerifyOtpToResetPasswordByPhoneReq', null, global);
goog.exportSymbol('proto.customer.web.VerifyOtpToResetPasswordByPhoneRes', null, global);
goog.exportSymbol('proto.customer.web.VerifyPhoneNumberReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web.SendOtpByPhoneReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.web.SendOtpByPhoneReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web.SendOtpByPhoneReq.displayName = 'proto.customer.web.SendOtpByPhoneReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web.VerifyPhoneNumberReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.web.VerifyPhoneNumberReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web.VerifyPhoneNumberReq.displayName = 'proto.customer.web.VerifyPhoneNumberReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.web.VerifyOtpToResetPasswordByPhoneReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web.VerifyOtpToResetPasswordByPhoneReq.displayName = 'proto.customer.web.VerifyOtpToResetPasswordByPhoneReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web.ResetPasswordByPhoneReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.web.ResetPasswordByPhoneReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web.ResetPasswordByPhoneReq.displayName = 'proto.customer.web.ResetPasswordByPhoneReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web.SendOtpByPhoneRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.web.SendOtpByPhoneRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web.SendOtpByPhoneRes.displayName = 'proto.customer.web.SendOtpByPhoneRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.web.VerifyOtpToResetPasswordByPhoneRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web.VerifyOtpToResetPasswordByPhoneRes.displayName = 'proto.customer.web.VerifyOtpToResetPasswordByPhoneRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web.SendOtpByPhoneReq.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web.SendOtpByPhoneReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web.SendOtpByPhoneReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web.SendOtpByPhoneReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    phoneCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    otpType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web.SendOtpByPhoneReq}
 */
proto.customer.web.SendOtpByPhoneReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web.SendOtpByPhoneReq;
  return proto.customer.web.SendOtpByPhoneReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web.SendOtpByPhoneReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web.SendOtpByPhoneReq}
 */
proto.customer.web.SendOtpByPhoneReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 3:
      var value = /** @type {!proto.customer.PhoneOtpType} */ (reader.readEnum());
      msg.setOtpType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web.SendOtpByPhoneReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web.SendOtpByPhoneReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web.SendOtpByPhoneReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web.SendOtpByPhoneReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOtpType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string phone_code = 1;
 * @return {string}
 */
proto.customer.web.SendOtpByPhoneReq.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web.SendOtpByPhoneReq} returns this
 */
proto.customer.web.SendOtpByPhoneReq.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.customer.web.SendOtpByPhoneReq.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web.SendOtpByPhoneReq} returns this
 */
proto.customer.web.SendOtpByPhoneReq.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional customer.PhoneOtpType otp_type = 3;
 * @return {!proto.customer.PhoneOtpType}
 */
proto.customer.web.SendOtpByPhoneReq.prototype.getOtpType = function() {
  return /** @type {!proto.customer.PhoneOtpType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.customer.PhoneOtpType} value
 * @return {!proto.customer.web.SendOtpByPhoneReq} returns this
 */
proto.customer.web.SendOtpByPhoneReq.prototype.setOtpType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web.VerifyPhoneNumberReq.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web.VerifyPhoneNumberReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web.VerifyPhoneNumberReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web.VerifyPhoneNumberReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    phoneCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    otp: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web.VerifyPhoneNumberReq}
 */
proto.customer.web.VerifyPhoneNumberReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web.VerifyPhoneNumberReq;
  return proto.customer.web.VerifyPhoneNumberReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web.VerifyPhoneNumberReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web.VerifyPhoneNumberReq}
 */
proto.customer.web.VerifyPhoneNumberReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web.VerifyPhoneNumberReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web.VerifyPhoneNumberReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web.VerifyPhoneNumberReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web.VerifyPhoneNumberReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOtp();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string phone_code = 1;
 * @return {string}
 */
proto.customer.web.VerifyPhoneNumberReq.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web.VerifyPhoneNumberReq} returns this
 */
proto.customer.web.VerifyPhoneNumberReq.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.customer.web.VerifyPhoneNumberReq.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web.VerifyPhoneNumberReq} returns this
 */
proto.customer.web.VerifyPhoneNumberReq.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string otp = 3;
 * @return {string}
 */
proto.customer.web.VerifyPhoneNumberReq.prototype.getOtp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web.VerifyPhoneNumberReq} returns this
 */
proto.customer.web.VerifyPhoneNumberReq.prototype.setOtp = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneReq.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web.VerifyOtpToResetPasswordByPhoneReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web.VerifyOtpToResetPasswordByPhoneReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    phoneCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    otp: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web.VerifyOtpToResetPasswordByPhoneReq}
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web.VerifyOtpToResetPasswordByPhoneReq;
  return proto.customer.web.VerifyOtpToResetPasswordByPhoneReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web.VerifyOtpToResetPasswordByPhoneReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web.VerifyOtpToResetPasswordByPhoneReq}
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web.VerifyOtpToResetPasswordByPhoneReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web.VerifyOtpToResetPasswordByPhoneReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOtp();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string phone_code = 1;
 * @return {string}
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneReq.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web.VerifyOtpToResetPasswordByPhoneReq} returns this
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneReq.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneReq.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web.VerifyOtpToResetPasswordByPhoneReq} returns this
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneReq.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string otp = 3;
 * @return {string}
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneReq.prototype.getOtp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web.VerifyOtpToResetPasswordByPhoneReq} returns this
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneReq.prototype.setOtp = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web.ResetPasswordByPhoneReq.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web.ResetPasswordByPhoneReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web.ResetPasswordByPhoneReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web.ResetPasswordByPhoneReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    newPassword: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web.ResetPasswordByPhoneReq}
 */
proto.customer.web.ResetPasswordByPhoneReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web.ResetPasswordByPhoneReq;
  return proto.customer.web.ResetPasswordByPhoneReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web.ResetPasswordByPhoneReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web.ResetPasswordByPhoneReq}
 */
proto.customer.web.ResetPasswordByPhoneReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web.ResetPasswordByPhoneReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web.ResetPasswordByPhoneReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web.ResetPasswordByPhoneReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web.ResetPasswordByPhoneReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string new_password = 1;
 * @return {string}
 */
proto.customer.web.ResetPasswordByPhoneReq.prototype.getNewPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web.ResetPasswordByPhoneReq} returns this
 */
proto.customer.web.ResetPasswordByPhoneReq.prototype.setNewPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web.SendOtpByPhoneRes.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web.SendOtpByPhoneRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web.SendOtpByPhoneRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web.SendOtpByPhoneRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeRemaining: jspb.Message.getFieldWithDefault(msg, 1, 0),
    provider: jspb.Message.getFieldWithDefault(msg, 2, 0),
    method: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web.SendOtpByPhoneRes}
 */
proto.customer.web.SendOtpByPhoneRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web.SendOtpByPhoneRes;
  return proto.customer.web.SendOtpByPhoneRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web.SendOtpByPhoneRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web.SendOtpByPhoneRes}
 */
proto.customer.web.SendOtpByPhoneRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTimeRemaining(value);
      break;
    case 2:
      var value = /** @type {!proto.notification.backend.Provider} */ (reader.readEnum());
      msg.setProvider(value);
      break;
    case 3:
      var value = /** @type {!proto.customer.OTPMethod} */ (reader.readEnum());
      msg.setMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web.SendOtpByPhoneRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web.SendOtpByPhoneRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web.SendOtpByPhoneRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web.SendOtpByPhoneRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeRemaining();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getProvider();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional uint32 time_remaining = 1;
 * @return {number}
 */
proto.customer.web.SendOtpByPhoneRes.prototype.getTimeRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.web.SendOtpByPhoneRes} returns this
 */
proto.customer.web.SendOtpByPhoneRes.prototype.setTimeRemaining = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional notification.backend.Provider provider = 2;
 * @return {!proto.notification.backend.Provider}
 */
proto.customer.web.SendOtpByPhoneRes.prototype.getProvider = function() {
  return /** @type {!proto.notification.backend.Provider} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.notification.backend.Provider} value
 * @return {!proto.customer.web.SendOtpByPhoneRes} returns this
 */
proto.customer.web.SendOtpByPhoneRes.prototype.setProvider = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional customer.OTPMethod method = 3;
 * @return {!proto.customer.OTPMethod}
 */
proto.customer.web.SendOtpByPhoneRes.prototype.getMethod = function() {
  return /** @type {!proto.customer.OTPMethod} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.customer.OTPMethod} value
 * @return {!proto.customer.web.SendOtpByPhoneRes} returns this
 */
proto.customer.web.SendOtpByPhoneRes.prototype.setMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneRes.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web.VerifyOtpToResetPasswordByPhoneRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web.VerifyOtpToResetPasswordByPhoneRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ttl: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web.VerifyOtpToResetPasswordByPhoneRes}
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web.VerifyOtpToResetPasswordByPhoneRes;
  return proto.customer.web.VerifyOtpToResetPasswordByPhoneRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web.VerifyOtpToResetPasswordByPhoneRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web.VerifyOtpToResetPasswordByPhoneRes}
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTtl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web.VerifyOtpToResetPasswordByPhoneRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web.VerifyOtpToResetPasswordByPhoneRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTtl();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneRes.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web.VerifyOtpToResetPasswordByPhoneRes} returns this
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneRes.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 ttl = 2;
 * @return {number}
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneRes.prototype.getTtl = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.web.VerifyOtpToResetPasswordByPhoneRes} returns this
 */
proto.customer.web.VerifyOtpToResetPasswordByPhoneRes.prototype.setTtl = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.customer.web);
