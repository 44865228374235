type Props = {
  //
};

export function LoadingLogoFullPage({}: Props) {
  return (
    <div className='logoLoadingFullPage'>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default LoadingLogoFullPage;
