import { useCurrency, useTime } from '@web-booker/hooks/internals';
import cn from 'classnames';

type TMinFareItem = {
  price: number;
  date: Date | number;
};

type MinFareProps = {
  minFareItem: TMinFareItem;
  isActive?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
};

export function MinFareItem({
  minFareItem,
  isActive,
  onClick,
  isLoading,
}: MinFareProps) {
  const { formatDateCustom, formatDateGMT7 } = useTime();
  const { formatPrice } = useCurrency();
  const formatDay = formatDateCustom('dddd');

  return (
    <button
      disabled={isLoading}
      className={cn(
        'flex flex-col items-center min-w-[130px] px-2 py-4 gap-0.5 border-r cursor-pointer disabled:cursor-default hover:bg-theme-white disabled:bg-theme-gray-50',
        { 'bg-theme-white disabled:!bg-primary-1': isActive }
      )}
      onClick={onClick}>
      <span className='text-xs font-semibold text-neutral-10 capitalize'>
        {formatDay(minFareItem.date)}
      </span>
      <span className='text-xs  text-neutral-10'>
        {formatDateGMT7(minFareItem.date)}
      </span>
      {/* <span className='font-semibold text-red-6'>
        {formatPrice(minFareItem.price)}
      </span> */}
    </button>
  );
}
