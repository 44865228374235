/**
 * @fileoverview gRPC-Web generated client stub for customer.web
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: customer/web/auth.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var customer_base_pb = require('../../customer/base_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.customer = {};
proto.customer.web = require('./auth_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.customer.web.AuthServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.customer.web.AuthServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web.RegisterByOtpReq,
 *   !proto.customer.AccessToken>}
 */
const methodDescriptor_AuthService_Register = new grpc.web.MethodDescriptor(
  '/customer.web.AuthService/Register',
  grpc.web.MethodType.UNARY,
  proto.customer.web.RegisterByOtpReq,
  customer_base_pb.AccessToken,
  /**
   * @param {!proto.customer.web.RegisterByOtpReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_base_pb.AccessToken.deserializeBinary
);


/**
 * @param {!proto.customer.web.RegisterByOtpReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.customer.AccessToken)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer.AccessToken>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web.AuthServiceClient.prototype.register =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web.AuthService/Register',
      request,
      metadata || {},
      methodDescriptor_AuthService_Register,
      callback);
};


/**
 * @param {!proto.customer.web.RegisterByOtpReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer.AccessToken>}
 *     Promise that resolves to the response
 */
proto.customer.web.AuthServicePromiseClient.prototype.register =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web.AuthService/Register',
      request,
      metadata || {},
      methodDescriptor_AuthService_Register);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web.RegisterByOtpReq,
 *   !proto.customer.AccessToken>}
 */
const methodDescriptor_AuthService_RegisterAnonymousUser = new grpc.web.MethodDescriptor(
  '/customer.web.AuthService/RegisterAnonymousUser',
  grpc.web.MethodType.UNARY,
  proto.customer.web.RegisterByOtpReq,
  customer_base_pb.AccessToken,
  /**
   * @param {!proto.customer.web.RegisterByOtpReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_base_pb.AccessToken.deserializeBinary
);


/**
 * @param {!proto.customer.web.RegisterByOtpReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.customer.AccessToken)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer.AccessToken>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web.AuthServiceClient.prototype.registerAnonymousUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web.AuthService/RegisterAnonymousUser',
      request,
      metadata || {},
      methodDescriptor_AuthService_RegisterAnonymousUser,
      callback);
};


/**
 * @param {!proto.customer.web.RegisterByOtpReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer.AccessToken>}
 *     Promise that resolves to the response
 */
proto.customer.web.AuthServicePromiseClient.prototype.registerAnonymousUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web.AuthService/RegisterAnonymousUser',
      request,
      metadata || {},
      methodDescriptor_AuthService_RegisterAnonymousUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web.LoginReq,
 *   !proto.customer.AccessToken>}
 */
const methodDescriptor_AuthService_Login = new grpc.web.MethodDescriptor(
  '/customer.web.AuthService/Login',
  grpc.web.MethodType.UNARY,
  proto.customer.web.LoginReq,
  customer_base_pb.AccessToken,
  /**
   * @param {!proto.customer.web.LoginReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_base_pb.AccessToken.deserializeBinary
);


/**
 * @param {!proto.customer.web.LoginReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.customer.AccessToken)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer.AccessToken>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web.AuthServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login,
      callback);
};


/**
 * @param {!proto.customer.web.LoginReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer.AccessToken>}
 *     Promise that resolves to the response
 */
proto.customer.web.AuthServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web.RefreshTokenReq,
 *   !proto.customer.AccessToken>}
 */
const methodDescriptor_AuthService_RefreshToken = new grpc.web.MethodDescriptor(
  '/customer.web.AuthService/RefreshToken',
  grpc.web.MethodType.UNARY,
  proto.customer.web.RefreshTokenReq,
  customer_base_pb.AccessToken,
  /**
   * @param {!proto.customer.web.RefreshTokenReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_base_pb.AccessToken.deserializeBinary
);


/**
 * @param {!proto.customer.web.RefreshTokenReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.customer.AccessToken)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer.AccessToken>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web.AuthServiceClient.prototype.refreshToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web.AuthService/RefreshToken',
      request,
      metadata || {},
      methodDescriptor_AuthService_RefreshToken,
      callback);
};


/**
 * @param {!proto.customer.web.RefreshTokenReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer.AccessToken>}
 *     Promise that resolves to the response
 */
proto.customer.web.AuthServicePromiseClient.prototype.refreshToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web.AuthService/RefreshToken',
      request,
      metadata || {},
      methodDescriptor_AuthService_RefreshToken);
};


module.exports = proto.customer.web;

