import ModalContent from '@web-booker/components/Modal/ModalContent';
import { EModalMode } from '@web-booker/types';

const ConfirmReserveTicket = () => {
  return (
    <ModalContent
      mode={EModalMode.CONFIRM}
      title='Xác nhận giữ chỗ'
      content='Bạn đang giữ chỗ chuyến bay, bạn có muốn tiếp tục?'
    />
  );
};

export default ConfirmReserveTicket;
