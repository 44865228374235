import { Card, FormCheckbox, MapPin2FillIcon } from '@common-ui';

import { IFormFilterFlight } from '@web-booker/types/ui/flight';
import { useFieldArray, useFormContext } from 'react-hook-form';

type Props = {
  //
};

const StopPoint = (props: Props) => {
  const methods = useFormContext<IFormFilterFlight>();
  const { fields } = useFieldArray({
    control: methods.control,
    name: 'stopPointType',
  });

  return (
    <Card
      isCollapse
      icon={<MapPin2FillIcon />}
      title='Điểm dừng'
      classNameTitle='bg-[#E8E8E8]'
      classNameChildren='p-2.5'
      defaultShow>
      <div className='flex min-w-[340px] flex-col space-y-2.5'>
        {fields.map((item, index) => {
          return (
            <FormCheckbox
              key={item.id}
              className='col-span-1'
              name={`stopPointType[${index}].isSelected`}
              label={item.label}
            />
          );
        })}
      </div>
    </Card>
  );
};

export default StopPoint;
