import { useCities } from '@web-booker/hooks/apps';
import { useAirlines } from '@web-booker/hooks/stores';
import cn from 'classnames';

type Props = {
  isBold?: boolean;
  name?: string;
  codeAirport: string;
  className?: string;
  classNameDesc?: string;
  description?: string;
};

export const AirportName = ({
  isBold = false,
  name,
  codeAirport,
  description,
  className,
  classNameDesc,
}: Props) => {
  const { getCityNameByCode } = useCities();
  const { getAirlineNameByCode } = useAirlines();
  return (
    <div className='flex flex-col space-y-1 text-xs w-full text-ellipsis overflow-hidden'>
      <span
        className={cn('', {
          'font-bold': isBold,
          [className || '']: !!className,
        })}>
        {name ? name : getCityNameByCode(codeAirport)}
        <span className='text-theme-black/20'>
          {codeAirport ? ` (${codeAirport})` : null}
        </span>
      </span>
      {description && (
        <span
          className={cn(' text-theme-black/50', {
            [classNameDesc || '']: !!classNameDesc,
          })}>
          {getAirlineNameByCode(description)}
        </span>
      )}
    </div>
  );
};
