import cn from 'classnames';
import { PropsWithChildren, Suspense } from 'react';
import LoadingRing, { LoadingProps } from './LoadingRing';
type Props = {
  isLoading: boolean;
  loadingClassName?: string;
  mode?: 'ring' | 'skeleton';
  ringSize?: LoadingProps['size'];
};

export function LoadingComponent({
  isLoading,
  mode = 'ring',
  loadingClassName,
  children,
  ringSize = 'sm',
}: PropsWithChildren<Props>) {
  const loadingElement =
    mode === 'ring' ? (
      <div
        className={cn(
          'flex justify-center items-center w-full py-4',
          loadingClassName
        )}>
        <LoadingRing size={ringSize} />
      </div>
    ) : (
      <div
        className={cn(
          'relative animate-pulse bg-neutral-5 duration-300 py-4',
          loadingClassName
        )}
      />
    );

  if (isLoading) {
    return loadingElement;
  }

  return <Suspense fallback={loadingElement}>{children}</Suspense>;
}

export default LoadingComponent;
