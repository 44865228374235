import { TUseBooleanReturn, useBoolean } from '@web-booker/hooks/internals';
import { ReactNode, createContext, useMemo } from 'react';

type THideContentContext = {
  // value: boolean;
  // setValue: Dispatch<SetStateAction<boolean>>;
  // setTrue: () => void;
  // setFalse: () => void;
  // toggle: () => void;
} & TUseBooleanReturn;

interface Props {
  children?: ReactNode;
}
export const HideContentContext = createContext<THideContentContext | null>(
  null
);

export const HideContentContextProvider = ({ children }: Props) => {
  const useBooleanReturn = useBoolean(false);

  const valueMemo = useMemo<THideContentContext>(
    () => ({
      ...useBooleanReturn,
    }),
    [useBooleanReturn.value]
  );

  return (
    <HideContentContext.Provider value={valueMemo}>
      {children}
    </HideContentContext.Provider>
  );
};
