import React, { useState } from 'react';
import cn from 'classnames';
import { Card, TimeFillIcon } from '@common-ui';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IFormFilterFlight } from '@web-booker/types/ui/flight';

type Props = {
  //
};

export const Landing = (props: Props) => {
  const methods = useFormContext<IFormFilterFlight>();
  const { fields, update } = useFieldArray({
    control: methods.control,
    name: 'landingTimes',
  });
  const handleClickDuration = (indexDuration: number) => {
    update(indexDuration, {
      ...fields[indexDuration],
      isSelected: !fields[indexDuration].isSelected,
    });
  };
  return (
    <Card
      isCollapse
      icon={<TimeFillIcon />}
      title='Giờ hạ cánh'
      classNameTitle='bg-[#E8E8E8]'
      defaultShow>
      <div className='grid grid-cols-2 gap-3 px-2.5 py-4 '>
        {fields.map((item, index) => {
          return (
            <div
              key={item.id}
              onClick={() => {
                handleClickDuration(index);
              }}
              className={cn(
                'col-span-1 flex min-w-[160px] cursor-pointer flex-col space-y-2 rounded-lg border py-2 text-center hover:text-primary hover:border-primary',
                {
                  'text-primary border-primary': item.isSelected,
                }
              )}>
              <span className='font-semibold'>{item.label}</span>
              <span>{item.durationLabel}</span>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default Landing;
