import { AppLogicProvider } from '@web-booker/containers';
import { Outlet } from 'react-router-dom';

type Props = {
  //
};

export function ExternalLayout({}: Props) {
  return (
    <AppLogicProvider>
      <Outlet />
    </AppLogicProvider>
  );
}

export default ExternalLayout;
