export function addDay(currentDate: Date | string, numberDay: number) {
  // 7 * 86400 * 1000
  return new Date(new Date(currentDate).getTime() + numberDay * 86400 * 1000);
}

export function subDay(currentDate: Date | string, numberDay: number) {
  // 7 * 86400 * 1000
  return new Date(new Date(currentDate).getTime() - numberDay * 86400 * 1000);
}

export function isSameDay(
  d1: Date | null | undefined,
  d2: Date | null | undefined
) {
  if (!d1 || !d2) {
    return false;
  }
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export function convertLocalToUTCDate(date: Date | number | null): Date {
  if (!date) {
    return new Date();
  }
  date = new Date(date);
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  return date;
}
