import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TBookingLayout = {
  triggerShowSearch: number;
  triggerShowSummary: number;
  triggerShowFilter: number;
};

type TLayoutProperty = {
  bookingFlight: TBookingLayout;
};

const initialState: TLayoutProperty = {
  bookingFlight: {
    triggerShowSearch: 0,
    triggerShowSummary: 0,
    triggerShowFilter: 0,
  },
};

const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState,
  reducers: {
    updateLayoutBooking(state, action: PayloadAction<Partial<TBookingLayout>>) {
      state.bookingFlight = { ...state.bookingFlight, ...action.payload };
    },
  },
});

export const { updateLayoutBooking } = layoutSlice.actions;
export default layoutSlice.reducer;
