// source: customer/customer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.customer.Gender', null, global);
goog.exportSymbol('proto.customer.GetMeReq', null, global);
goog.exportSymbol('proto.customer.GetMeRes', null, global);
goog.exportSymbol('proto.customer.Location', null, global);
goog.exportSymbol('proto.customer.SocialType', null, global);
goog.exportSymbol('proto.customer.UserInfo', null, global);
goog.exportSymbol('proto.customer.UserSimpleInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.Location.displayName = 'proto.customer.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.UserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.UserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.UserInfo.displayName = 'proto.customer.UserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.UserSimpleInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.UserSimpleInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.UserSimpleInfo.displayName = 'proto.customer.UserSimpleInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.GetMeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.GetMeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.GetMeReq.displayName = 'proto.customer.GetMeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.GetMeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.GetMeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.GetMeRes.displayName = 'proto.customer.GetMeRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    pb_long: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.Location}
 */
proto.customer.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.Location;
  return proto.customer.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.Location}
 */
proto.customer.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLong(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLong();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double lat = 1;
 * @return {number}
 */
proto.customer.Location.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.customer.Location} returns this
 */
proto.customer.Location.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double long = 2;
 * @return {number}
 */
proto.customer.Location.prototype.getLong = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.customer.Location} returns this
 */
proto.customer.Location.prototype.setLong = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.UserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.UserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.UserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.UserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    phoneVerified: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    emailVerified: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    isOfficialAccount: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 11, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 12, ""),
    profilePicture: jspb.Message.getFieldWithDefault(msg, 13, ""),
    addr: jspb.Message.getFieldWithDefault(msg, 14, ""),
    city: jspb.Message.getFieldWithDefault(msg, 15, ""),
    district: jspb.Message.getFieldWithDefault(msg, 16, ""),
    ward: jspb.Message.getFieldWithDefault(msg, 17, ""),
    location: (f = msg.getLocation()) && proto.customer.Location.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 19, ""),
    inviterId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    birthday: jspb.Message.getFieldWithDefault(msg, 21, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 22, 0),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 23, ""),
    isSocialAccount: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    socialType: jspb.Message.getFieldWithDefault(msg, 25, 0),
    country: jspb.Message.getFieldWithDefault(msg, 26, ""),
    lastLoginAt: jspb.Message.getFieldWithDefault(msg, 27, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 28, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 29, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.UserInfo}
 */
proto.customer.UserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.UserInfo;
  return proto.customer.UserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.UserInfo}
 */
proto.customer.UserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhoneVerified(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmailVerified(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOfficialAccount(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicture(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddr(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistrict(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setWard(value);
      break;
    case 18:
      var value = new proto.customer.Location;
      reader.readMessage(value,proto.customer.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setInviterId(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBirthday(value);
      break;
    case 22:
      var value = /** @type {!proto.customer.Gender} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSocialAccount(value);
      break;
    case 25:
      var value = /** @type {!proto.customer.SocialType} */ (reader.readEnum());
      msg.setSocialType(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastLoginAt(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.UserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.UserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.UserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhoneVerified();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getEmailVerified();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getIsOfficialAccount();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getProfilePicture();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAddr();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDistrict();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getWard();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.customer.Location.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getInviterId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getBirthday();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getIsSocialAccount();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getSocialType();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getLastLoginAt();
  if (f !== 0) {
    writer.writeInt64(
      27,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.customer.UserInfo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 updated_at = 3;
 * @return {number}
 */
proto.customer.UserInfo.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool phone_verified = 7;
 * @return {boolean}
 */
proto.customer.UserInfo.prototype.getPhoneVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setPhoneVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_enabled = 8;
 * @return {boolean}
 */
proto.customer.UserInfo.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool email_verified = 9;
 * @return {boolean}
 */
proto.customer.UserInfo.prototype.getEmailVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setEmailVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool is_official_account = 10;
 * @return {boolean}
 */
proto.customer.UserInfo.prototype.getIsOfficialAccount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setIsOfficialAccount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string phone_number = 11;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string phone_code = 12;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string profile_picture = 13;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getProfilePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setProfilePicture = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string addr = 14;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string city = 15;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string district = 16;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getDistrict = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setDistrict = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string ward = 17;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getWard = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setWard = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional Location location = 18;
 * @return {?proto.customer.Location}
 */
proto.customer.UserInfo.prototype.getLocation = function() {
  return /** @type{?proto.customer.Location} */ (
    jspb.Message.getWrapperField(this, proto.customer.Location, 18));
};


/**
 * @param {?proto.customer.Location|undefined} value
 * @return {!proto.customer.UserInfo} returns this
*/
proto.customer.UserInfo.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.UserInfo.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string description = 19;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string inviter_id = 20;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getInviterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setInviterId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional int64 birthday = 21;
 * @return {number}
 */
proto.customer.UserInfo.prototype.getBirthday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setBirthday = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional Gender gender = 22;
 * @return {!proto.customer.Gender}
 */
proto.customer.UserInfo.prototype.getGender = function() {
  return /** @type {!proto.customer.Gender} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {!proto.customer.Gender} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional string partnership_id = 23;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional bool is_social_account = 24;
 * @return {boolean}
 */
proto.customer.UserInfo.prototype.getIsSocialAccount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setIsSocialAccount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional SocialType social_type = 25;
 * @return {!proto.customer.SocialType}
 */
proto.customer.UserInfo.prototype.getSocialType = function() {
  return /** @type {!proto.customer.SocialType} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.customer.SocialType} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setSocialType = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional string country = 26;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional int64 last_login_at = 27;
 * @return {number}
 */
proto.customer.UserInfo.prototype.getLastLoginAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setLastLoginAt = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional string first_name = 28;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string last_name = 29;
 * @return {string}
 */
proto.customer.UserInfo.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserInfo} returns this
 */
proto.customer.UserInfo.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.UserSimpleInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.UserSimpleInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.UserSimpleInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.UserSimpleInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePicture: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    birthday: jspb.Message.getFieldWithDefault(msg, 6, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 7, 0),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.UserSimpleInfo}
 */
proto.customer.UserSimpleInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.UserSimpleInfo;
  return proto.customer.UserSimpleInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.UserSimpleInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.UserSimpleInfo}
 */
proto.customer.UserSimpleInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicture(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBirthday(value);
      break;
    case 7:
      var value = /** @type {!proto.customer.Gender} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.UserSimpleInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.UserSimpleInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.UserSimpleInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.UserSimpleInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePicture();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBirthday();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.customer.UserSimpleInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserSimpleInfo} returns this
 */
proto.customer.UserSimpleInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.customer.UserSimpleInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserSimpleInfo} returns this
 */
proto.customer.UserSimpleInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture = 3;
 * @return {string}
 */
proto.customer.UserSimpleInfo.prototype.getProfilePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserSimpleInfo} returns this
 */
proto.customer.UserSimpleInfo.prototype.setProfilePicture = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_number = 4;
 * @return {string}
 */
proto.customer.UserSimpleInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserSimpleInfo} returns this
 */
proto.customer.UserSimpleInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_code = 5;
 * @return {string}
 */
proto.customer.UserSimpleInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserSimpleInfo} returns this
 */
proto.customer.UserSimpleInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 birthday = 6;
 * @return {number}
 */
proto.customer.UserSimpleInfo.prototype.getBirthday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.UserSimpleInfo} returns this
 */
proto.customer.UserSimpleInfo.prototype.setBirthday = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional Gender gender = 7;
 * @return {!proto.customer.Gender}
 */
proto.customer.UserSimpleInfo.prototype.getGender = function() {
  return /** @type {!proto.customer.Gender} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.customer.Gender} value
 * @return {!proto.customer.UserSimpleInfo} returns this
 */
proto.customer.UserSimpleInfo.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string partnership_id = 8;
 * @return {string}
 */
proto.customer.UserSimpleInfo.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.UserSimpleInfo} returns this
 */
proto.customer.UserSimpleInfo.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.GetMeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.GetMeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.GetMeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.GetMeReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.GetMeReq}
 */
proto.customer.GetMeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.GetMeReq;
  return proto.customer.GetMeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.GetMeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.GetMeReq}
 */
proto.customer.GetMeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.GetMeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.GetMeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.GetMeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.GetMeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.GetMeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.GetMeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.GetMeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.GetMeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    userInfo: (f = msg.getUserInfo()) && proto.customer.UserInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.GetMeRes}
 */
proto.customer.GetMeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.GetMeRes;
  return proto.customer.GetMeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.GetMeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.GetMeRes}
 */
proto.customer.GetMeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customer.UserInfo;
      reader.readMessage(value,proto.customer.UserInfo.deserializeBinaryFromReader);
      msg.setUserInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.GetMeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.GetMeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.GetMeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.GetMeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.customer.UserInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserInfo user_info = 1;
 * @return {?proto.customer.UserInfo}
 */
proto.customer.GetMeRes.prototype.getUserInfo = function() {
  return /** @type{?proto.customer.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.customer.UserInfo, 1));
};


/**
 * @param {?proto.customer.UserInfo|undefined} value
 * @return {!proto.customer.GetMeRes} returns this
*/
proto.customer.GetMeRes.prototype.setUserInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.GetMeRes} returns this
 */
proto.customer.GetMeRes.prototype.clearUserInfo = function() {
  return this.setUserInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.GetMeRes.prototype.hasUserInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * @enum {number}
 */
proto.customer.Gender = {
  GENDER_INVALID: 0,
  GENDER_MALE: 1,
  GENDER_FEMALE: 2,
  GENDER_OTHER: 3
};

/**
 * @enum {number}
 */
proto.customer.SocialType = {
  SOCIAL_TYPE_INVALID: 0,
  SOCIAL_TYPE_FACEBOOK: 1,
  SOCIAL_TYPE_GOOGLE: 2,
  SOCIAL_TYPE_APPLE: 3
};

goog.object.extend(exports, proto.customer);
