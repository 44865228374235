import { Button } from '@common-ui';
import { SortAscIcon, SortDescIcon } from '@icon';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import { updateLayoutBooking } from '@web-booker/stores/reducers/layout';
import { IFormSortFlight } from '@web-booker/types/ui/flight';
import cn from 'classnames';
import { useFieldArray, useFormContext } from 'react-hook-form';

export const SortFlightCtn = () => {
  const dispatch = useAppDispatch();
  const { triggerShowFilter, triggerShowSearch } = useAppSelector(
    (state) => state.layout.bookingFlight
  );
  const { control } = useFormContext<IFormSortFlight>();
  const { fields, update } = useFieldArray({
    name: 'sortsList',
    control: control,
  });
  const handleChangeSortType = (indexSortType: number) => {
    if (fields[indexSortType].isSelected) {
      if (fields[indexSortType].asc) {
        update(indexSortType, {
          ...fields[indexSortType],
          asc: false,
        });
        return;
      }
      update(indexSortType, {
        ...fields[indexSortType],
        asc: true,
      });
      return;
    }
    fields.forEach((item, index) => {
      update(index, {
        ...fields[index],
        isSelected: index === indexSortType,
      });
    });
  };

  const handleOpenSearch = () => {
    dispatch(updateLayoutBooking({ triggerShowSearch: triggerShowSearch + 1 }));
  };
  const handleOpenFilter = () => {
    dispatch(updateLayoutBooking({ triggerShowFilter: triggerShowFilter + 1 }));
  };
  return (
    <div
      id='sort-flight'
      className='py-1.5 px-2.5 bg-white items-center flex justify-between'>
      <div className='flex space-x-10 select-none'>
        <div className='flex space-x-2.5 text-[#666666] items-center'>
          <span>Sắp xếp theo:</span>
        </div>
        <div className='flex space-x-7'>
          {fields.map((item, index) => {
            return (
              <div
                className={cn(
                  'cursor-pointer hover:text-secondary flex space-x-1 items-center',
                  {
                    'text-secondary': item.isSelected,
                  }
                )}
                onClick={() => handleChangeSortType(index)}
                key={item.id}>
                <span>{item.label}</span>
                <div className='min-w-[20px]'>
                  {item.isSelected && item.asc && (
                    <SortAscIcon className='w-5 h-5' />
                  )}
                  {item.isSelected && !item.asc && (
                    <SortDescIcon className='w-5 h-5' />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className='flex space-x-2.5'>
        <Button theme='secondary' className='px-5' onClick={handleOpenSearch}>
          Đổi tìm kiếm
        </Button>
        <Button className='px-5' theme='neutral' onClick={handleOpenFilter}>
          Bộ lọc
        </Button>
      </div>
    </div>
  );
};

export default SortFlightCtn;
