import {
  ElementType,
  FunctionComponentElement,
  ReactNode,
  Suspense,
} from 'react';
import { LoadingRing } from '../Loading';

export function withSuspense<P = unknown>(
  Component: ElementType,
  fallback?: ReactNode
) {
  return function (props: P): FunctionComponentElement<P> {
    return (
      <Suspense
        fallback={
          fallback ? (
            fallback
          ) : (
            <div className='flex justify-center items-center h-[500px] w-full'>
              <LoadingRing />
            </div>
          )
        }>
        <Component {...props} />
      </Suspense>
    );
  };
}

export default withSuspense;
