export const SCREEN_BREAK_POINT = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
  '2XL': '2xl',
} as const;

export const SCREEN_BREAK_POINT_VALUE = {
  [SCREEN_BREAK_POINT.XS]: '376px',
  [SCREEN_BREAK_POINT.SM]: '480px',
  // [SCREEN_BREAK_POINT.MD]: '768px',
  [SCREEN_BREAK_POINT.MD]: '960px',
  [SCREEN_BREAK_POINT.LG]: '1280px',
  [SCREEN_BREAK_POINT.XL]: '1440px',
  [SCREEN_BREAK_POINT['2XL']]: '1920px',
} as const;

export type TScreenBreakpoint = ValueOf<typeof SCREEN_BREAK_POINT>;
