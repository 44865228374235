import { InformationFillIcon } from '@icon';
import { useCountry } from '@web-booker/hooks/apps';
import { useTime } from '@web-booker/hooks/internals';
import { IFormBooking } from '@web-booker/types/ui/booking';

type Props = {
  data: IFormBooking;
};

const ConfirmInfo = ({ data }: Props) => {
  const { formatDate } = useTime();
  const { contactInfo, passengersList } = data;
  const { countriesObjectByCode } = useCountry();
  return (
    <div className='space-y-5'>
      <div className='bg-secondary-1 rounded flex space-x-2.5 p-3 text-secondary-6'>
        <InformationFillIcon className='w-4 h-4 shrink-0' />
        <div>
          Vui lòng kiểm tra kỹ thông tin hành khách. Thông tin sai có thể khiến
          hành khách không được phép lên chuyến bay.
        </div>
      </div>

      <div className='space-y-2.5'>
        <div className='font-semibold text-md'>Thông tin liên hệ</div>
        <div className='rounded border'>
          <div className='flex p-3'>
            <div className='flex-1 space-y-1'>
              <div className='text-[#666666]'>Họ</div>
              <div className='font-semibold'>{contactInfo.lastName}</div>
            </div>
            <div className='flex-1 space-y-1'>
              <div className='text-[#666666]'>Tên đệm & tên</div>
              <div className='font-semibold'>{contactInfo.firstName}</div>
            </div>
          </div>
          <div className='flex p-3'>
            <div className='flex-1 space-y-1'>
              <div className='text-[#666666]'>Số điện thoại</div>
              <div className='font-semibold'>{`${contactInfo.phoneCode}${contactInfo.phoneNumber}`}</div>
            </div>
            <div className='flex-1 space-y-1'>
              <div className='text-[#666666]'>Email</div>
              <div className='font-semibold'>{contactInfo.email}</div>
            </div>
          </div>
        </div>
      </div>

      <div className='space-y-2.5'>
        <div className='font-semibold text-md '>Thông tin hành khách</div>
        <div className='max-h-[500px] overflow-auto space-y-2.5'>
          {passengersList.map((passenger, index) => {
            return (
              <div className='rounded border ' key={index}>
                <div className='flex p-3 space-x-2.5 border-b'>
                  <div className='bg-primary-1 text-primary w-6 h-6 rounded-full text-center'>
                    {index + 1}
                  </div>
                  <div className='font-semibold'>{`${passenger.lastName} ${passenger.firstName}`}</div>
                </div>
                <div className='flex p-3'>
                  <div className='flex-1 space-y-1'>
                    <div className='text-[#666666]'>Họ</div>
                    <div className='font-semibold'>{passenger.lastName}</div>
                  </div>
                  <div className='flex-1 space-y-1'>
                    <div className='text-[#666666]'>Tên đệm & tên</div>
                    <div className='font-semibold'>{passenger.firstName}</div>
                  </div>
                </div>
                <div className='p-3 space-y-3 bg-neutral-3'>
                  {(passenger.nationality || passenger.passportNumber) && (
                    <div className='flex'>
                      {passenger.passportNumber && (
                        <div className='flex-1 space-y-1'>
                          <div className='text-[#666666]'>Số hộ chiếu</div>
                          <div className='font-semibold'>
                            {passenger.passportNumber}
                          </div>
                        </div>
                      )}
                      {passenger.nationality && (
                        <div className='flex-1 space-y-1'>
                          <div className='text-[#666666]'>Quốc tịch</div>
                          <div className='font-semibold'>
                            {
                              countriesObjectByCode[passenger.nationality]
                                ?.country
                            }
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {(passenger.idIssueCountry || passenger.expirationDate) && (
                    <div className='flex '>
                      {passenger.idIssueCountry && (
                        <div className='flex-1 space-y-1'>
                          <div className='text-[#666666]'>Nơi cấp</div>
                          <div className='font-semibold'>
                            {
                              countriesObjectByCode[passenger.idIssueCountry]
                                ?.country
                            }
                          </div>
                        </div>
                      )}
                      {passenger.expirationDate && (
                        <div className='flex-1 space-y-1'>
                          <div className='text-[#666666]'>Ngày hết hạn</div>
                          <div className='font-semibold'>
                            {formatDate(passenger.expirationDate)}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ConfirmInfo;
