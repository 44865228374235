// source: order/web/order.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var order_base_pb = require('../../order/base_pb.js');
goog.object.extend(proto, order_base_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.order.web.BuyDirectlyReq', null, global);
goog.exportSymbol('proto.order.web.BuyDirectlyRes', null, global);
goog.exportSymbol('proto.order.web.OrderCustomerInfo', null, global);
goog.exportSymbol('proto.order.web.OrderInfo', null, global);
goog.exportSymbol('proto.order.web.OrderProductInfo', null, global);
goog.exportSymbol('proto.order.web.OrderProductInfoItem', null, global);
goog.exportSymbol('proto.order.web.OrderPromotionInfo', null, global);
goog.exportSymbol('proto.order.web.PlaceOrderReq', null, global);
goog.exportSymbol('proto.order.web.PlaceOrderRes', null, global);
goog.exportSymbol('proto.order.web.Product', null, global);
goog.exportSymbol('proto.order.web.RetrieveOrderReq', null, global);
goog.exportSymbol('proto.order.web.RetrieveOrderRes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.web.OrderCustomerInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.web.OrderCustomerInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.web.OrderCustomerInfo.displayName = 'proto.order.web.OrderCustomerInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.web.PlaceOrderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.web.PlaceOrderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.web.PlaceOrderReq.displayName = 'proto.order.web.PlaceOrderReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.web.BuyDirectlyReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order.web.BuyDirectlyReq.repeatedFields_, null);
};
goog.inherits(proto.order.web.BuyDirectlyReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.web.BuyDirectlyReq.displayName = 'proto.order.web.BuyDirectlyReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.web.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.web.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.web.Product.displayName = 'proto.order.web.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.web.RetrieveOrderReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.web.RetrieveOrderReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.web.RetrieveOrderReq.displayName = 'proto.order.web.RetrieveOrderReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.web.BuyDirectlyRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.web.BuyDirectlyRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.web.BuyDirectlyRes.displayName = 'proto.order.web.BuyDirectlyRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.web.PlaceOrderRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.web.PlaceOrderRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.web.PlaceOrderRes.displayName = 'proto.order.web.PlaceOrderRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.web.OrderInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order.web.OrderInfo.repeatedFields_, null);
};
goog.inherits(proto.order.web.OrderInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.web.OrderInfo.displayName = 'proto.order.web.OrderInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.web.OrderPromotionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.web.OrderPromotionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.web.OrderPromotionInfo.displayName = 'proto.order.web.OrderPromotionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.web.OrderProductInfoItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.web.OrderProductInfoItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.web.OrderProductInfoItem.displayName = 'proto.order.web.OrderProductInfoItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.web.OrderProductInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order.web.OrderProductInfo.repeatedFields_, null);
};
goog.inherits(proto.order.web.OrderProductInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.web.OrderProductInfo.displayName = 'proto.order.web.OrderProductInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order.web.RetrieveOrderRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order.web.RetrieveOrderRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order.web.RetrieveOrderRes.displayName = 'proto.order.web.RetrieveOrderRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.web.OrderCustomerInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.order.web.OrderCustomerInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.web.OrderCustomerInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.OrderCustomerInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    salutation: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    idType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    idNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    isChildren: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    idGrantCountry: jspb.Message.getFieldWithDefault(msg, 11, ""),
    idExpiredAt: jspb.Message.getFieldWithDefault(msg, 12, 0),
    nationality: jspb.Message.getFieldWithDefault(msg, 13, ""),
    dob: jspb.Message.getFieldWithDefault(msg, 14, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.web.OrderCustomerInfo}
 */
proto.order.web.OrderCustomerInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.web.OrderCustomerInfo;
  return proto.order.web.OrderCustomerInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.web.OrderCustomerInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.web.OrderCustomerInfo}
 */
proto.order.web.OrderCustomerInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSalutation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 5:
      var value = /** @type {!proto.order.IdType} */ (reader.readEnum());
      msg.setIdType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsChildren(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdGrantCountry(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIdExpiredAt(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationality(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDob(value);
      break;
    case 15:
      var value = /** @type {!proto.base.GENDER} */ (reader.readEnum());
      msg.setGender(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.web.OrderCustomerInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.web.OrderCustomerInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.web.OrderCustomerInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.OrderCustomerInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSalutation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {!proto.order.IdType} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIdGrantCountry();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIdExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getNationality();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDob();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
};


/**
 * optional string salutation = 1;
 * @return {string}
 */
proto.order.web.OrderCustomerInfo.prototype.getSalutation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.setSalutation = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.order.web.OrderCustomerInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 3;
 * @return {string}
 */
proto.order.web.OrderCustomerInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_name = 4;
 * @return {string}
 */
proto.order.web.OrderCustomerInfo.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional order.IdType id_type = 5;
 * @return {!proto.order.IdType}
 */
proto.order.web.OrderCustomerInfo.prototype.getIdType = function() {
  return /** @type {!proto.order.IdType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.order.IdType} value
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.setIdType = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.clearIdType = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.web.OrderCustomerInfo.prototype.hasIdType = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string id_number = 6;
 * @return {string}
 */
proto.order.web.OrderCustomerInfo.prototype.getIdNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.setIdNumber = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.clearIdNumber = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.web.OrderCustomerInfo.prototype.hasIdNumber = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.order.web.OrderCustomerInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phone_code = 8;
 * @return {string}
 */
proto.order.web.OrderCustomerInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string phone_number = 9;
 * @return {string}
 */
proto.order.web.OrderCustomerInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool is_children = 10;
 * @return {boolean}
 */
proto.order.web.OrderCustomerInfo.prototype.getIsChildren = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.setIsChildren = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.clearIsChildren = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.web.OrderCustomerInfo.prototype.hasIsChildren = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string id_grant_country = 11;
 * @return {string}
 */
proto.order.web.OrderCustomerInfo.prototype.getIdGrantCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.setIdGrantCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 id_expired_at = 12;
 * @return {number}
 */
proto.order.web.OrderCustomerInfo.prototype.getIdExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.setIdExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string nationality = 13;
 * @return {string}
 */
proto.order.web.OrderCustomerInfo.prototype.getNationality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.setNationality = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 dob = 14;
 * @return {number}
 */
proto.order.web.OrderCustomerInfo.prototype.getDob = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.setDob = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional base.GENDER gender = 15;
 * @return {!proto.base.GENDER}
 */
proto.order.web.OrderCustomerInfo.prototype.getGender = function() {
  return /** @type {!proto.base.GENDER} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.base.GENDER} value
 * @return {!proto.order.web.OrderCustomerInfo} returns this
 */
proto.order.web.OrderCustomerInfo.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.web.PlaceOrderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.order.web.PlaceOrderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.web.PlaceOrderReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.PlaceOrderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.web.PlaceOrderReq}
 */
proto.order.web.PlaceOrderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.web.PlaceOrderReq;
  return proto.order.web.PlaceOrderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.web.PlaceOrderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.web.PlaceOrderReq}
 */
proto.order.web.PlaceOrderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.web.PlaceOrderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.web.PlaceOrderReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.web.PlaceOrderReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.PlaceOrderReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order.web.PlaceOrderReq.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.PlaceOrderReq} returns this
 */
proto.order.web.PlaceOrderReq.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order.web.BuyDirectlyReq.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.web.BuyDirectlyReq.prototype.toObject = function(opt_includeInstance) {
  return proto.order.web.BuyDirectlyReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.web.BuyDirectlyReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.BuyDirectlyReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.order.web.Product.toObject, includeInstance),
    customer: (f = msg.getCustomer()) && proto.order.web.OrderCustomerInfo.toObject(includeInstance, f),
    passengersList: jspb.Message.toObjectList(msg.getPassengersList(),
    proto.order.web.OrderCustomerInfo.toObject, includeInstance),
    timezone: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.web.BuyDirectlyReq}
 */
proto.order.web.BuyDirectlyReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.web.BuyDirectlyReq;
  return proto.order.web.BuyDirectlyReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.web.BuyDirectlyReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.web.BuyDirectlyReq}
 */
proto.order.web.BuyDirectlyReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.order.web.Product;
      reader.readMessage(value,proto.order.web.Product.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    case 2:
      var value = new proto.order.web.OrderCustomerInfo;
      reader.readMessage(value,proto.order.web.OrderCustomerInfo.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 3:
      var value = new proto.order.web.OrderCustomerInfo;
      reader.readMessage(value,proto.order.web.OrderCustomerInfo.deserializeBinaryFromReader);
      msg.addPassengers(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.web.BuyDirectlyReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.web.BuyDirectlyReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.web.BuyDirectlyReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.BuyDirectlyReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.order.web.Product.serializeBinaryToWriter
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.order.web.OrderCustomerInfo.serializeBinaryToWriter
    );
  }
  f = message.getPassengersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.order.web.OrderCustomerInfo.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * repeated Product products = 1;
 * @return {!Array<!proto.order.web.Product>}
 */
proto.order.web.BuyDirectlyReq.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.order.web.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.order.web.Product, 1));
};


/**
 * @param {!Array<!proto.order.web.Product>} value
 * @return {!proto.order.web.BuyDirectlyReq} returns this
*/
proto.order.web.BuyDirectlyReq.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.order.web.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.order.web.Product}
 */
proto.order.web.BuyDirectlyReq.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.order.web.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.web.BuyDirectlyReq} returns this
 */
proto.order.web.BuyDirectlyReq.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * optional OrderCustomerInfo customer = 2;
 * @return {?proto.order.web.OrderCustomerInfo}
 */
proto.order.web.BuyDirectlyReq.prototype.getCustomer = function() {
  return /** @type{?proto.order.web.OrderCustomerInfo} */ (
    jspb.Message.getWrapperField(this, proto.order.web.OrderCustomerInfo, 2));
};


/**
 * @param {?proto.order.web.OrderCustomerInfo|undefined} value
 * @return {!proto.order.web.BuyDirectlyReq} returns this
*/
proto.order.web.BuyDirectlyReq.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.web.BuyDirectlyReq} returns this
 */
proto.order.web.BuyDirectlyReq.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.web.BuyDirectlyReq.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated OrderCustomerInfo passengers = 3;
 * @return {!Array<!proto.order.web.OrderCustomerInfo>}
 */
proto.order.web.BuyDirectlyReq.prototype.getPassengersList = function() {
  return /** @type{!Array<!proto.order.web.OrderCustomerInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.order.web.OrderCustomerInfo, 3));
};


/**
 * @param {!Array<!proto.order.web.OrderCustomerInfo>} value
 * @return {!proto.order.web.BuyDirectlyReq} returns this
*/
proto.order.web.BuyDirectlyReq.prototype.setPassengersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.order.web.OrderCustomerInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.order.web.OrderCustomerInfo}
 */
proto.order.web.BuyDirectlyReq.prototype.addPassengers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.order.web.OrderCustomerInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.web.BuyDirectlyReq} returns this
 */
proto.order.web.BuyDirectlyReq.prototype.clearPassengersList = function() {
  return this.setPassengersList([]);
};


/**
 * optional string timezone = 4;
 * @return {string}
 */
proto.order.web.BuyDirectlyReq.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.BuyDirectlyReq} returns this
 */
proto.order.web.BuyDirectlyReq.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.web.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.order.web.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.web.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    productId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.web.Product}
 */
proto.order.web.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.web.Product;
  return proto.order.web.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.web.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.web.Product}
 */
proto.order.web.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.web.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.web.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.web.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string product_id = 1;
 * @return {string}
 */
proto.order.web.Product.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.Product} returns this
 */
proto.order.web.Product.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 quantity = 2;
 * @return {number}
 */
proto.order.web.Product.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.Product} returns this
 */
proto.order.web.Product.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.web.RetrieveOrderReq.prototype.toObject = function(opt_includeInstance) {
  return proto.order.web.RetrieveOrderReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.web.RetrieveOrderReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.RetrieveOrderReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.web.RetrieveOrderReq}
 */
proto.order.web.RetrieveOrderReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.web.RetrieveOrderReq;
  return proto.order.web.RetrieveOrderReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.web.RetrieveOrderReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.web.RetrieveOrderReq}
 */
proto.order.web.RetrieveOrderReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.web.RetrieveOrderReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.web.RetrieveOrderReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.web.RetrieveOrderReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.RetrieveOrderReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.order.web.RetrieveOrderReq.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.RetrieveOrderReq} returns this
 */
proto.order.web.RetrieveOrderReq.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.web.BuyDirectlyRes.prototype.toObject = function(opt_includeInstance) {
  return proto.order.web.BuyDirectlyRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.web.BuyDirectlyRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.BuyDirectlyRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    order: (f = msg.getOrder()) && proto.order.web.OrderInfo.toObject(includeInstance, f),
    customerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorsMap: (f = msg.getErrorsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.web.BuyDirectlyRes}
 */
proto.order.web.BuyDirectlyRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.web.BuyDirectlyRes;
  return proto.order.web.BuyDirectlyRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.web.BuyDirectlyRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.web.BuyDirectlyRes}
 */
proto.order.web.BuyDirectlyRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = new proto.order.web.OrderInfo;
      reader.readMessage(value,proto.order.web.OrderInfo.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 4:
      var value = msg.getErrorsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.web.BuyDirectlyRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.web.BuyDirectlyRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.web.BuyDirectlyRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.BuyDirectlyRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.order.web.OrderInfo.serializeBinaryToWriter
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.order.web.BuyDirectlyRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order.web.BuyDirectlyRes} returns this
 */
proto.order.web.BuyDirectlyRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional OrderInfo order = 2;
 * @return {?proto.order.web.OrderInfo}
 */
proto.order.web.BuyDirectlyRes.prototype.getOrder = function() {
  return /** @type{?proto.order.web.OrderInfo} */ (
    jspb.Message.getWrapperField(this, proto.order.web.OrderInfo, 2));
};


/**
 * @param {?proto.order.web.OrderInfo|undefined} value
 * @return {!proto.order.web.BuyDirectlyRes} returns this
*/
proto.order.web.BuyDirectlyRes.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.web.BuyDirectlyRes} returns this
 */
proto.order.web.BuyDirectlyRes.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.web.BuyDirectlyRes.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string customer_id = 3;
 * @return {string}
 */
proto.order.web.BuyDirectlyRes.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.BuyDirectlyRes} returns this
 */
proto.order.web.BuyDirectlyRes.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * map<string, string> errors = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.order.web.BuyDirectlyRes.prototype.getErrorsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.order.web.BuyDirectlyRes} returns this
 */
proto.order.web.BuyDirectlyRes.prototype.clearErrorsMap = function() {
  this.getErrorsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.web.PlaceOrderRes.prototype.toObject = function(opt_includeInstance) {
  return proto.order.web.PlaceOrderRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.web.PlaceOrderRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.PlaceOrderRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.web.PlaceOrderRes}
 */
proto.order.web.PlaceOrderRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.web.PlaceOrderRes;
  return proto.order.web.PlaceOrderRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.web.PlaceOrderRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.web.PlaceOrderRes}
 */
proto.order.web.PlaceOrderRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.web.PlaceOrderRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.web.PlaceOrderRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.web.PlaceOrderRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.PlaceOrderRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.order.web.PlaceOrderRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order.web.PlaceOrderRes} returns this
 */
proto.order.web.PlaceOrderRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order.web.OrderInfo.repeatedFields_ = [10,22,24,25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.web.OrderInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.order.web.OrderInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.web.OrderInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.OrderInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdBy: jspb.Message.getFieldWithDefault(msg, 4, ""),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 5, ""),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cartId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    currency: (f = msg.getCurrency()) && order_base_pb.OrderCurrencyInfo.toObject(includeInstance, f),
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.order.web.OrderProductInfoItem.toObject, includeInstance),
    totalProductAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    totalDiscountAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 13, 0),
    shippingFeeAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    feeAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    totalAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    orderCode: jspb.Message.getFieldWithDefault(msg, 17, ""),
    orderType: jspb.Message.getFieldWithDefault(msg, 18, 0),
    note: jspb.Message.getFieldWithDefault(msg, 19, ""),
    expiredTime: jspb.Message.getFieldWithDefault(msg, 20, 0),
    customer: (f = msg.getCustomer()) && proto.order.web.OrderCustomerInfo.toObject(includeInstance, f),
    passengersList: jspb.Message.toObjectList(msg.getPassengersList(),
    proto.order.web.OrderCustomerInfo.toObject, includeInstance),
    timezone: jspb.Message.getFieldWithDefault(msg, 23, ""),
    promotionsList: jspb.Message.toObjectList(msg.getPromotionsList(),
    proto.order.web.OrderPromotionInfo.toObject, includeInstance),
    extraServicesList: jspb.Message.toObjectList(msg.getExtraServicesList(),
    proto.order.web.OrderProductInfoItem.toObject, includeInstance),
    extraServicesAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 26, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.web.OrderInfo}
 */
proto.order.web.OrderInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.web.OrderInfo;
  return proto.order.web.OrderInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.web.OrderInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.web.OrderInfo}
 */
proto.order.web.OrderInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCartId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 9:
      var value = new order_base_pb.OrderCurrencyInfo;
      reader.readMessage(value,order_base_pb.OrderCurrencyInfo.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 10:
      var value = new proto.order.web.OrderProductInfoItem;
      reader.readMessage(value,proto.order.web.OrderProductInfoItem.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalProductAmount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalDiscountAmount(value);
      break;
    case 13:
      var value = /** @type {!proto.order.OrderStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setShippingFeeAmount(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeeAmount(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalAmount(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    case 18:
      var value = /** @type {!proto.order.OrderType} */ (reader.readEnum());
      msg.setOrderType(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredTime(value);
      break;
    case 21:
      var value = new proto.order.web.OrderCustomerInfo;
      reader.readMessage(value,proto.order.web.OrderCustomerInfo.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 22:
      var value = new proto.order.web.OrderCustomerInfo;
      reader.readMessage(value,proto.order.web.OrderCustomerInfo.deserializeBinaryFromReader);
      msg.addPassengers(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 24:
      var value = new proto.order.web.OrderPromotionInfo;
      reader.readMessage(value,proto.order.web.OrderPromotionInfo.deserializeBinaryFromReader);
      msg.addPromotions(value);
      break;
    case 25:
      var value = new proto.order.web.OrderProductInfoItem;
      reader.readMessage(value,proto.order.web.OrderProductInfoItem.deserializeBinaryFromReader);
      msg.addExtraServices(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setExtraServicesAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.web.OrderInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.web.OrderInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.web.OrderInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.OrderInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCartId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      order_base_pb.OrderCurrencyInfo.serializeBinaryToWriter
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.order.web.OrderProductInfoItem.serializeBinaryToWriter
    );
  }
  f = message.getTotalProductAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getTotalDiscountAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getShippingFeeAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getFeeAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getTotalAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getOrderType();
  if (f !== 0.0) {
    writer.writeEnum(
      18,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getExpiredTime();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.order.web.OrderCustomerInfo.serializeBinaryToWriter
    );
  }
  f = message.getPassengersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto.order.web.OrderCustomerInfo.serializeBinaryToWriter
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getPromotionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      proto.order.web.OrderPromotionInfo.serializeBinaryToWriter
    );
  }
  f = message.getExtraServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.order.web.OrderProductInfoItem.serializeBinaryToWriter
    );
  }
  f = message.getExtraServicesAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      26,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.order.web.OrderInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.order.web.OrderInfo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 updated_at = 3;
 * @return {number}
 */
proto.order.web.OrderInfo.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string created_by = 4;
 * @return {string}
 */
proto.order.web.OrderInfo.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string updated_by = 5;
 * @return {string}
 */
proto.order.web.OrderInfo.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string partnership_id = 6;
 * @return {string}
 */
proto.order.web.OrderInfo.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string cart_id = 7;
 * @return {string}
 */
proto.order.web.OrderInfo.prototype.getCartId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setCartId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string user_id = 8;
 * @return {string}
 */
proto.order.web.OrderInfo.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional order.OrderCurrencyInfo currency = 9;
 * @return {?proto.order.OrderCurrencyInfo}
 */
proto.order.web.OrderInfo.prototype.getCurrency = function() {
  return /** @type{?proto.order.OrderCurrencyInfo} */ (
    jspb.Message.getWrapperField(this, order_base_pb.OrderCurrencyInfo, 9));
};


/**
 * @param {?proto.order.OrderCurrencyInfo|undefined} value
 * @return {!proto.order.web.OrderInfo} returns this
*/
proto.order.web.OrderInfo.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.web.OrderInfo.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated OrderProductInfoItem products = 10;
 * @return {!Array<!proto.order.web.OrderProductInfoItem>}
 */
proto.order.web.OrderInfo.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.order.web.OrderProductInfoItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.order.web.OrderProductInfoItem, 10));
};


/**
 * @param {!Array<!proto.order.web.OrderProductInfoItem>} value
 * @return {!proto.order.web.OrderInfo} returns this
*/
proto.order.web.OrderInfo.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.order.web.OrderProductInfoItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.order.web.OrderProductInfoItem}
 */
proto.order.web.OrderInfo.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.order.web.OrderProductInfoItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * optional double total_product_amount = 11;
 * @return {number}
 */
proto.order.web.OrderInfo.prototype.getTotalProductAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setTotalProductAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double total_discount_amount = 12;
 * @return {number}
 */
proto.order.web.OrderInfo.prototype.getTotalDiscountAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setTotalDiscountAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional order.OrderStatus status = 13;
 * @return {!proto.order.OrderStatus}
 */
proto.order.web.OrderInfo.prototype.getStatus = function() {
  return /** @type {!proto.order.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.order.OrderStatus} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional double shipping_fee_amount = 14;
 * @return {number}
 */
proto.order.web.OrderInfo.prototype.getShippingFeeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setShippingFeeAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double fee_amount = 15;
 * @return {number}
 */
proto.order.web.OrderInfo.prototype.getFeeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setFeeAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double total_amount = 16;
 * @return {number}
 */
proto.order.web.OrderInfo.prototype.getTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional string order_code = 17;
 * @return {string}
 */
proto.order.web.OrderInfo.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional order.OrderType order_type = 18;
 * @return {!proto.order.OrderType}
 */
proto.order.web.OrderInfo.prototype.getOrderType = function() {
  return /** @type {!proto.order.OrderType} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {!proto.order.OrderType} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setOrderType = function(value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};


/**
 * optional string note = 19;
 * @return {string}
 */
proto.order.web.OrderInfo.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int64 expired_time = 20;
 * @return {number}
 */
proto.order.web.OrderInfo.prototype.getExpiredTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setExpiredTime = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional OrderCustomerInfo customer = 21;
 * @return {?proto.order.web.OrderCustomerInfo}
 */
proto.order.web.OrderInfo.prototype.getCustomer = function() {
  return /** @type{?proto.order.web.OrderCustomerInfo} */ (
    jspb.Message.getWrapperField(this, proto.order.web.OrderCustomerInfo, 21));
};


/**
 * @param {?proto.order.web.OrderCustomerInfo|undefined} value
 * @return {!proto.order.web.OrderInfo} returns this
*/
proto.order.web.OrderInfo.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.web.OrderInfo.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * repeated OrderCustomerInfo passengers = 22;
 * @return {!Array<!proto.order.web.OrderCustomerInfo>}
 */
proto.order.web.OrderInfo.prototype.getPassengersList = function() {
  return /** @type{!Array<!proto.order.web.OrderCustomerInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.order.web.OrderCustomerInfo, 22));
};


/**
 * @param {!Array<!proto.order.web.OrderCustomerInfo>} value
 * @return {!proto.order.web.OrderInfo} returns this
*/
proto.order.web.OrderInfo.prototype.setPassengersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.order.web.OrderCustomerInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.order.web.OrderCustomerInfo}
 */
proto.order.web.OrderInfo.prototype.addPassengers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.order.web.OrderCustomerInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.clearPassengersList = function() {
  return this.setPassengersList([]);
};


/**
 * optional string timezone = 23;
 * @return {string}
 */
proto.order.web.OrderInfo.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * repeated OrderPromotionInfo promotions = 24;
 * @return {!Array<!proto.order.web.OrderPromotionInfo>}
 */
proto.order.web.OrderInfo.prototype.getPromotionsList = function() {
  return /** @type{!Array<!proto.order.web.OrderPromotionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.order.web.OrderPromotionInfo, 24));
};


/**
 * @param {!Array<!proto.order.web.OrderPromotionInfo>} value
 * @return {!proto.order.web.OrderInfo} returns this
*/
proto.order.web.OrderInfo.prototype.setPromotionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.order.web.OrderPromotionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.order.web.OrderPromotionInfo}
 */
proto.order.web.OrderInfo.prototype.addPromotions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.order.web.OrderPromotionInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.clearPromotionsList = function() {
  return this.setPromotionsList([]);
};


/**
 * repeated OrderProductInfoItem extra_services = 25;
 * @return {!Array<!proto.order.web.OrderProductInfoItem>}
 */
proto.order.web.OrderInfo.prototype.getExtraServicesList = function() {
  return /** @type{!Array<!proto.order.web.OrderProductInfoItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.order.web.OrderProductInfoItem, 25));
};


/**
 * @param {!Array<!proto.order.web.OrderProductInfoItem>} value
 * @return {!proto.order.web.OrderInfo} returns this
*/
proto.order.web.OrderInfo.prototype.setExtraServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.order.web.OrderProductInfoItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.order.web.OrderProductInfoItem}
 */
proto.order.web.OrderInfo.prototype.addExtraServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.order.web.OrderProductInfoItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.clearExtraServicesList = function() {
  return this.setExtraServicesList([]);
};


/**
 * optional double extra_services_amount = 26;
 * @return {number}
 */
proto.order.web.OrderInfo.prototype.getExtraServicesAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 26, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.OrderInfo} returns this
 */
proto.order.web.OrderInfo.prototype.setExtraServicesAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 26, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.web.OrderPromotionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.order.web.OrderPromotionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.web.OrderPromotionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.OrderPromotionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortDesc: jspb.Message.getFieldWithDefault(msg, 3, ""),
    code: jspb.Message.getFieldWithDefault(msg, 4, ""),
    coverImage: jspb.Message.getFieldWithDefault(msg, 5, ""),
    discountPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.web.OrderPromotionInfo}
 */
proto.order.web.OrderPromotionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.web.OrderPromotionInfo;
  return proto.order.web.OrderPromotionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.web.OrderPromotionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.web.OrderPromotionInfo}
 */
proto.order.web.OrderPromotionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortDesc(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoverImage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDiscountPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.web.OrderPromotionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.web.OrderPromotionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.web.OrderPromotionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.OrderPromotionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortDesc();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCoverImage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDiscountPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.order.web.OrderPromotionInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderPromotionInfo} returns this
 */
proto.order.web.OrderPromotionInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.order.web.OrderPromotionInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderPromotionInfo} returns this
 */
proto.order.web.OrderPromotionInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_desc = 3;
 * @return {string}
 */
proto.order.web.OrderPromotionInfo.prototype.getShortDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderPromotionInfo} returns this
 */
proto.order.web.OrderPromotionInfo.prototype.setShortDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string code = 4;
 * @return {string}
 */
proto.order.web.OrderPromotionInfo.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderPromotionInfo} returns this
 */
proto.order.web.OrderPromotionInfo.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cover_image = 5;
 * @return {string}
 */
proto.order.web.OrderPromotionInfo.prototype.getCoverImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderPromotionInfo} returns this
 */
proto.order.web.OrderPromotionInfo.prototype.setCoverImage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double discount_percent = 6;
 * @return {number}
 */
proto.order.web.OrderPromotionInfo.prototype.getDiscountPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.OrderPromotionInfo} returns this
 */
proto.order.web.OrderPromotionInfo.prototype.setDiscountPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.web.OrderProductInfoItem.prototype.toObject = function(opt_includeInstance) {
  return proto.order.web.OrderProductInfoItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.web.OrderProductInfoItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.OrderProductInfoItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    productInfo: (f = msg.getProductInfo()) && proto.order.web.OrderProductInfo.toObject(includeInstance, f),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.web.OrderProductInfoItem}
 */
proto.order.web.OrderProductInfoItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.web.OrderProductInfoItem;
  return proto.order.web.OrderProductInfoItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.web.OrderProductInfoItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.web.OrderProductInfoItem}
 */
proto.order.web.OrderProductInfoItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.order.web.OrderProductInfo;
      reader.readMessage(value,proto.order.web.OrderProductInfo.deserializeBinaryFromReader);
      msg.setProductInfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.web.OrderProductInfoItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.web.OrderProductInfoItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.web.OrderProductInfoItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.OrderProductInfoItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.order.web.OrderProductInfo.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional OrderProductInfo product_info = 1;
 * @return {?proto.order.web.OrderProductInfo}
 */
proto.order.web.OrderProductInfoItem.prototype.getProductInfo = function() {
  return /** @type{?proto.order.web.OrderProductInfo} */ (
    jspb.Message.getWrapperField(this, proto.order.web.OrderProductInfo, 1));
};


/**
 * @param {?proto.order.web.OrderProductInfo|undefined} value
 * @return {!proto.order.web.OrderProductInfoItem} returns this
*/
proto.order.web.OrderProductInfoItem.prototype.setProductInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.web.OrderProductInfoItem} returns this
 */
proto.order.web.OrderProductInfoItem.prototype.clearProductInfo = function() {
  return this.setProductInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.web.OrderProductInfoItem.prototype.hasProductInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 quantity = 2;
 * @return {number}
 */
proto.order.web.OrderProductInfoItem.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.OrderProductInfoItem} returns this
 */
proto.order.web.OrderProductInfoItem.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order.web.OrderProductInfo.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.web.OrderProductInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.order.web.OrderProductInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.web.OrderProductInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.OrderProductInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mediasList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    tagsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    sku: jspb.Message.getFieldWithDefault(msg, 6, ""),
    listedPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    discountPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    uom: jspb.Message.getFieldWithDefault(msg, 10, ""),
    barcode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    currency: (f = msg.getCurrency()) && order_base_pb.OrderProductCurrencyInfo.toObject(includeInstance, f),
    brand: jspb.Message.getFieldWithDefault(msg, 13, ""),
    unlimited: jspb.Message.getFieldWithDefault(msg, 14, 0),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    country: jspb.Message.getFieldWithDefault(msg, 16, ""),
    language: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.web.OrderProductInfo}
 */
proto.order.web.OrderProductInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.web.OrderProductInfo;
  return proto.order.web.OrderProductInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.web.OrderProductInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.web.OrderProductInfo}
 */
proto.order.web.OrderProductInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.order.ProductStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addMedias(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setListedPrice(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDiscountPercent(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUom(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBarcode(value);
      break;
    case 12:
      var value = new order_base_pb.OrderProductCurrencyInfo;
      reader.readMessage(value,order_base_pb.OrderProductCurrencyInfo.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrand(value);
      break;
    case 14:
      var value = /** @type {!proto.order.Boolean} */ (reader.readEnum());
      msg.setUnlimited(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.web.OrderProductInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.web.OrderProductInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.web.OrderProductInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.OrderProductInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMediasList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getListedPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getDiscountPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getUom();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBarcode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      order_base_pb.OrderProductCurrencyInfo.serializeBinaryToWriter
    );
  }
  f = message.getBrand();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getUnlimited();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.order.web.OrderProductInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional order.ProductStatus status = 2;
 * @return {!proto.order.ProductStatus}
 */
proto.order.web.OrderProductInfo.prototype.getStatus = function() {
  return /** @type {!proto.order.ProductStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.order.ProductStatus} value
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.order.web.OrderProductInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string medias = 4;
 * @return {!Array<string>}
 */
proto.order.web.OrderProductInfo.prototype.getMediasList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.setMediasList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.addMedias = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.clearMediasList = function() {
  return this.setMediasList([]);
};


/**
 * repeated string tags = 5;
 * @return {!Array<string>}
 */
proto.order.web.OrderProductInfo.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional string sku = 6;
 * @return {string}
 */
proto.order.web.OrderProductInfo.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.setSku = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional double listed_price = 7;
 * @return {number}
 */
proto.order.web.OrderProductInfo.prototype.getListedPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.setListedPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double discount_percent = 8;
 * @return {number}
 */
proto.order.web.OrderProductInfo.prototype.getDiscountPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.setDiscountPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double price = 9;
 * @return {number}
 */
proto.order.web.OrderProductInfo.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string uom = 10;
 * @return {string}
 */
proto.order.web.OrderProductInfo.prototype.getUom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.setUom = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string barcode = 11;
 * @return {string}
 */
proto.order.web.OrderProductInfo.prototype.getBarcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.setBarcode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional order.OrderProductCurrencyInfo currency = 12;
 * @return {?proto.order.OrderProductCurrencyInfo}
 */
proto.order.web.OrderProductInfo.prototype.getCurrency = function() {
  return /** @type{?proto.order.OrderProductCurrencyInfo} */ (
    jspb.Message.getWrapperField(this, order_base_pb.OrderProductCurrencyInfo, 12));
};


/**
 * @param {?proto.order.OrderProductCurrencyInfo|undefined} value
 * @return {!proto.order.web.OrderProductInfo} returns this
*/
proto.order.web.OrderProductInfo.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.web.OrderProductInfo.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string brand = 13;
 * @return {string}
 */
proto.order.web.OrderProductInfo.prototype.getBrand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.setBrand = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional order.Boolean unlimited = 14;
 * @return {!proto.order.Boolean}
 */
proto.order.web.OrderProductInfo.prototype.getUnlimited = function() {
  return /** @type {!proto.order.Boolean} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.order.Boolean} value
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.setUnlimited = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional string partnership_id = 15;
 * @return {string}
 */
proto.order.web.OrderProductInfo.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string country = 16;
 * @return {string}
 */
proto.order.web.OrderProductInfo.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string language = 17;
 * @return {string}
 */
proto.order.web.OrderProductInfo.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.order.web.OrderProductInfo} returns this
 */
proto.order.web.OrderProductInfo.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order.web.RetrieveOrderRes.prototype.toObject = function(opt_includeInstance) {
  return proto.order.web.RetrieveOrderRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order.web.RetrieveOrderRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.RetrieveOrderRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.order.web.OrderInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order.web.RetrieveOrderRes}
 */
proto.order.web.RetrieveOrderRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order.web.RetrieveOrderRes;
  return proto.order.web.RetrieveOrderRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order.web.RetrieveOrderRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order.web.RetrieveOrderRes}
 */
proto.order.web.RetrieveOrderRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.order.web.OrderInfo;
      reader.readMessage(value,proto.order.web.OrderInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order.web.RetrieveOrderRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order.web.RetrieveOrderRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order.web.RetrieveOrderRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order.web.RetrieveOrderRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.order.web.OrderInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional OrderInfo info = 1;
 * @return {?proto.order.web.OrderInfo}
 */
proto.order.web.RetrieveOrderRes.prototype.getInfo = function() {
  return /** @type{?proto.order.web.OrderInfo} */ (
    jspb.Message.getWrapperField(this, proto.order.web.OrderInfo, 1));
};


/**
 * @param {?proto.order.web.OrderInfo|undefined} value
 * @return {!proto.order.web.RetrieveOrderRes} returns this
*/
proto.order.web.RetrieveOrderRes.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order.web.RetrieveOrderRes} returns this
 */
proto.order.web.RetrieveOrderRes.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order.web.RetrieveOrderRes.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.order.web);
