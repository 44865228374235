import { PlusLineIcon, SubLineIcon } from '@icon';

import { IFormSearchFlight } from '@web-booker/types/ui/flight';
import cn from 'classnames';
import { useController, useFormContext } from 'react-hook-form';
import { TItemInputPassenger } from '../SearchFlightCtn';
import { FormErrorMessage } from '@common-ui';
type Props = {
  passengerTypeArray: TItemInputPassenger[];
};

const ItemInputPassenger = ({
  title,
  icon,
  description,
  name,
  maxQuantity = Infinity,
  minQuantity = -Infinity,
}: TItemInputPassenger) => {
  const { control, register } = useFormContext();
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  return (
    <div className='flex justify-between'>
      <input {...register(name, {})} name={name} value={value} type='hidden' />
      <div className='flex space-x-1'>
        <div>{icon}</div>
        <div className='flex flex-col'>
          <span className='text-sm text-[#333333]'>{title}</span>
          <span className='text-xs text-[#666666]'>{description}</span>
        </div>
      </div>

      <div className='flex rounded border items-center'>
        <div
          className={cn(
            'items-center p-2 bg-[#EDEDED] cursor-pointer select-none'
          )}
          onClick={() => {
            if (value > minQuantity) {
              onChange(value - 1);
            }
          }}>
          <SubLineIcon className='w-5 h-5' />
        </div>
        <span className='min-w-[80px] text-center'>{value}</span>
        <div
          className='items-center p-2 bg-[#EDEDED] cursor-pointer select-none'
          onClick={() => {
            if (maxQuantity > value) {
              onChange(value + 1);
            }
          }}>
          <PlusLineIcon className='w-5 h-5' />
        </div>
      </div>
    </div>
  );
};

export const InputPassenger = ({ passengerTypeArray }: Props) => {
  const { formState } = useFormContext<IFormSearchFlight>();
  return (
    <div className='flex flex-col space-y-2.5'>
      {passengerTypeArray.map((type, index) => {
        return <ItemInputPassenger {...type} key={type.name} />;
      })}

      <FormErrorMessage name='passengerQuantity' errors={formState.errors} />
    </div>
  );
};

export default InputPassenger;
