import cn from 'classnames';
import { TextareaHTMLAttributes, forwardRef } from 'react';
import { InputSize, TBaseInputProps } from '@common-ui';

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> &
  TBaseInputProps;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, isError, inputSize = InputSize.MD, isDirty, ...rest }, ref) => (
    <textarea
      {...rest}
      ref={ref}
      className={cn(
        `base-input base-input-${inputSize}`,
        {
          error: isError,
        },
        className
      )}
    />
  )
);
Textarea.displayName = 'Textarea';

export default Textarea;
