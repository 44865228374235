import {
  ChangePasswordReq,
  CheckExistPhoneReq,
  GetMeReq,
  GetMeRes,
  UpdateMeReq,
} from '@api/customer/web/customer_pb';
import { BaseApiOptions } from '@core/grpc-client';

import { Empty } from '@api/base/base_pb';
import { DefaultRes } from '@api/customer/base_pb';
import { CustomerServiceClient } from '@api/customer/web/customer_grpc_web_pb';
import { BaseApi } from './base-api';

class CustomerApi extends BaseApi<CustomerServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new CustomerServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  getMe = (): Promise<GetMeRes.AsObject> => {
    const req = new GetMeReq();

    return this.grpc<GetMeReq, GetMeRes, GetMeRes.AsObject>(
      this.serviceClient?.getMe,
      req,
      {}
    );
  };

  checkAlreadyExistPhone = ({
    phoneCode,
    phoneNumber,
  }: CheckExistPhoneReq.AsObject): Promise<DefaultRes.AsObject> => {
    const req = new CheckExistPhoneReq();
    req.setPhoneCode(phoneCode);
    req.setPhoneNumber(phoneNumber);

    return this.grpc<CheckExistPhoneReq, DefaultRes, DefaultRes.AsObject>(
      this.serviceClient?.checkAlreadyExistPhone,
      req,
      {}
    );
  };
  updateMe = ({
    name,
    avatar,
    birthday,
    email,
    gender,
  }: UpdateMeReq.AsObject): Promise<Empty.AsObject> => {
    const req = new UpdateMeReq();
    if (name) {
      req.setName(name);
    }
    if (avatar) {
      req.setAvatar(avatar);
    }
    if (birthday) {
      req.setBirthday(birthday);
    }
    if (email) {
      req.setEmail(email);
    }
    if (gender) {
      req.setGender(gender);
    }

    return this.grpc<UpdateMeReq, Empty, Empty.AsObject>(
      this.serviceClient?.updateMe,
      req,
      {}
    );
  };
  changePassword = ({
    oldPassword,
    password,
  }: ChangePasswordReq.AsObject): Promise<Empty.AsObject> => {
    const req = new ChangePasswordReq();
    req.setOldPassword(oldPassword);
    req.setPassword(password);
    return this.grpc<ChangePasswordReq, Empty, Empty.AsObject>(
      this.serviceClient.changePassword,
      req,
      {}
    );
  };
}

export const customerServiceClientApi = new CustomerApi();
export default customerServiceClientApi;
