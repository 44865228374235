import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ISearchFlightFilter } from '@tixlabs/grpc-client/web-partner';

interface SearchFlightHistoryState {
  searchHistoryList: ISearchFlightFilter[];
  selectHistory: ISearchFlightFilter | null;
  isLoadingSync: boolean;
}

export const syncSearchHistory = createAsyncThunk(
  'searchFlightHistory/sync',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { isSuccess, itemsList } = await (
        await import('@tixlabs/grpc-client/web-partner/flight-api')
      ).flightApiService.searchFlightHistory();

      if (isSuccess) {
        return itemsList;
      } else {
        throw new Error('Authenticate fail!');
      }
    } catch (errors) {
      // await dispatch(logout());
      return rejectWithValue(errors);
    }
  }
);

const initialState: SearchFlightHistoryState = {
  searchHistoryList: [],
  selectHistory: null,
  isLoadingSync: false,
};

const searchFlightHistorySlice = createSlice({
  name: 'searchFlightHistory',
  initialState,
  reducers: {
    updateSelectHistory(
      state: SearchFlightHistoryState,
      action: PayloadAction<ISearchFlightFilter | null>
    ) {
      state.selectHistory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncSearchHistory.pending, (state, action) => {
        state.isLoadingSync = true;
      })
      .addCase(syncSearchHistory.rejected, (state, action) => {
        state.isLoadingSync = false;
      })
      .addCase(syncSearchHistory.fulfilled, (state, action) => {
        state.searchHistoryList = action.payload;
        state.isLoadingSync = false;
      });
  },
});

export const { updateSelectHistory } = searchFlightHistorySlice.actions;
export default searchFlightHistorySlice.reducer;
