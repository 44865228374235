import { PropsWithChildren, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

export function AppErrorBoundary({ children }: PropsWithChildren) {
  return (
    <ErrorBoundary fallback={<p>Something went wrong</p>}>
      {children}
    </ErrorBoundary>
  );
}
