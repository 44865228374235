// source: product/product.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var product_base_pb = require('../product/base_pb.js');
goog.object.extend(proto, product_base_pb);
goog.exportSymbol('proto.product.CreateProductReq', null, global);
goog.exportSymbol('proto.product.CreateProductRes', null, global);
goog.exportSymbol('proto.product.DeleteProductReq', null, global);
goog.exportSymbol('proto.product.InternalListProductByIdsReq', null, global);
goog.exportSymbol('proto.product.InternalListProductByIdsRes', null, global);
goog.exportSymbol('proto.product.ProductCurrencyInfo', null, global);
goog.exportSymbol('proto.product.ProductInfo', null, global);
goog.exportSymbol('proto.product.PublicListProductReq', null, global);
goog.exportSymbol('proto.product.PublicListProductRes', null, global);
goog.exportSymbol('proto.product.PublicRetrieveProductReq', null, global);
goog.exportSymbol('proto.product.PublicRetrieveProductRes', null, global);
goog.exportSymbol('proto.product.RetrieveProductReq', null, global);
goog.exportSymbol('proto.product.RetrieveProductRes', null, global);
goog.exportSymbol('proto.product.UpdateProductReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.product.ProductInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.product.ProductInfo.repeatedFields_, null);
};
goog.inherits(proto.product.ProductInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.product.ProductInfo.displayName = 'proto.product.ProductInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.product.ProductCurrencyInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.product.ProductCurrencyInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.product.ProductCurrencyInfo.displayName = 'proto.product.ProductCurrencyInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.product.CreateProductReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.product.CreateProductReq.repeatedFields_, null);
};
goog.inherits(proto.product.CreateProductReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.product.CreateProductReq.displayName = 'proto.product.CreateProductReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.product.UpdateProductReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.product.UpdateProductReq.repeatedFields_, null);
};
goog.inherits(proto.product.UpdateProductReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.product.UpdateProductReq.displayName = 'proto.product.UpdateProductReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.product.DeleteProductReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.product.DeleteProductReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.product.DeleteProductReq.displayName = 'proto.product.DeleteProductReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.product.PublicListProductReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.product.PublicListProductReq.repeatedFields_, null);
};
goog.inherits(proto.product.PublicListProductReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.product.PublicListProductReq.displayName = 'proto.product.PublicListProductReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.product.RetrieveProductReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.product.RetrieveProductReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.product.RetrieveProductReq.displayName = 'proto.product.RetrieveProductReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.product.PublicRetrieveProductReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.product.PublicRetrieveProductReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.product.PublicRetrieveProductReq.displayName = 'proto.product.PublicRetrieveProductReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.product.InternalListProductByIdsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.product.InternalListProductByIdsReq.repeatedFields_, null);
};
goog.inherits(proto.product.InternalListProductByIdsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.product.InternalListProductByIdsReq.displayName = 'proto.product.InternalListProductByIdsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.product.CreateProductRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.product.CreateProductRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.product.CreateProductRes.displayName = 'proto.product.CreateProductRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.product.RetrieveProductRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.product.RetrieveProductRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.product.RetrieveProductRes.displayName = 'proto.product.RetrieveProductRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.product.PublicRetrieveProductRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.product.PublicRetrieveProductRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.product.PublicRetrieveProductRes.displayName = 'proto.product.PublicRetrieveProductRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.product.InternalListProductByIdsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.product.InternalListProductByIdsRes.repeatedFields_, null);
};
goog.inherits(proto.product.InternalListProductByIdsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.product.InternalListProductByIdsRes.displayName = 'proto.product.InternalListProductByIdsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.product.PublicListProductRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.product.PublicListProductRes.repeatedFields_, null);
};
goog.inherits(proto.product.PublicListProductRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.product.PublicListProductRes.displayName = 'proto.product.PublicListProductRes';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.product.ProductInfo.repeatedFields_ = [9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.product.ProductInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.product.ProductInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.product.ProductInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.ProductInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdBy: jspb.Message.getFieldWithDefault(msg, 4, ""),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 5, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    trailerUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    mediasList: jspb.Message.toObjectList(msg.getMediasList(),
    product_base_pb.Media.toObject, includeInstance),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    bookingStartTime: jspb.Message.getFieldWithDefault(msg, 11, 0),
    bookingEndTime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 13, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 14, 0),
    sku: jspb.Message.getFieldWithDefault(msg, 15, ""),
    listedPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    discountPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    rental: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
    uom: jspb.Message.getFieldWithDefault(msg, 20, ""),
    barcode: jspb.Message.getFieldWithDefault(msg, 21, ""),
    weight: jspb.Message.getFieldWithDefault(msg, 22, 0),
    volume: jspb.Message.getFieldWithDefault(msg, 25, 0),
    ratingValue: jspb.Message.getFieldWithDefault(msg, 26, 0),
    ratingCount: jspb.Message.getFieldWithDefault(msg, 27, 0),
    currency: (f = msg.getCurrency()) && proto.product.ProductCurrencyInfo.toObject(includeInstance, f),
    brand: jspb.Message.getFieldWithDefault(msg, 39, ""),
    description: jspb.Message.getFieldWithDefault(msg, 30, ""),
    limited: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    inBooking: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 33, ""),
    additionalDataMap: (f = msg.getAdditionalDataMap()) ? f.toObject(includeInstance, undefined) : [],
    type: jspb.Message.getFieldWithDefault(msg, 35, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.product.ProductInfo}
 */
proto.product.ProductInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.product.ProductInfo;
  return proto.product.ProductInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.product.ProductInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.product.ProductInfo}
 */
proto.product.ProductInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerUrl(value);
      break;
    case 9:
      var value = new product_base_pb.Media;
      reader.readMessage(value,product_base_pb.Media.deserializeBinaryFromReader);
      msg.addMedias(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingStartTime(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingEndTime(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setListedPrice(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDiscountPercent(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRental(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setUom(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setBarcode(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWeight(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRatingValue(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRatingCount(value);
      break;
    case 28:
      var value = new proto.product.ProductCurrencyInfo;
      reader.readMessage(value,proto.product.ProductCurrencyInfo.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrand(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLimited(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInBooking(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    case 34:
      var value = msg.getAdditionalDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 35:
      var value = /** @type {!proto.product.ProductType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.product.ProductInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.product.ProductInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.product.ProductInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.ProductInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTrailerUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMediasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      product_base_pb.Media.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getBookingStartTime();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getBookingEndTime();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getListedPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getDiscountPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getRental();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getUom();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getBarcode();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getRatingValue();
  if (f !== 0) {
    writer.writeInt32(
      26,
      f
    );
  }
  f = message.getRatingCount();
  if (f !== 0) {
    writer.writeInt64(
      27,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.product.ProductCurrencyInfo.serializeBinaryToWriter
    );
  }
  f = message.getBrand();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getLimited();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getInBooking();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getAdditionalDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(34, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      35,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.product.ProductInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.product.ProductInfo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 updated_at = 3;
 * @return {number}
 */
proto.product.ProductInfo.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string created_by = 4;
 * @return {string}
 */
proto.product.ProductInfo.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string updated_by = 5;
 * @return {string}
 */
proto.product.ProductInfo.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool active = 6;
 * @return {boolean}
 */
proto.product.ProductInfo.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.product.ProductInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string trailer_url = 8;
 * @return {string}
 */
proto.product.ProductInfo.prototype.getTrailerUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setTrailerUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated Media medias = 9;
 * @return {!Array<!proto.product.Media>}
 */
proto.product.ProductInfo.prototype.getMediasList = function() {
  return /** @type{!Array<!proto.product.Media>} */ (
    jspb.Message.getRepeatedWrapperField(this, product_base_pb.Media, 9));
};


/**
 * @param {!Array<!proto.product.Media>} value
 * @return {!proto.product.ProductInfo} returns this
*/
proto.product.ProductInfo.prototype.setMediasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.product.Media=} opt_value
 * @param {number=} opt_index
 * @return {!proto.product.Media}
 */
proto.product.ProductInfo.prototype.addMedias = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.product.Media, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.clearMediasList = function() {
  return this.setMediasList([]);
};


/**
 * repeated string tags = 10;
 * @return {!Array<string>}
 */
proto.product.ProductInfo.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional int64 booking_start_time = 11;
 * @return {number}
 */
proto.product.ProductInfo.prototype.getBookingStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setBookingStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 booking_end_time = 12;
 * @return {number}
 */
proto.product.ProductInfo.prototype.getBookingEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setBookingEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 duration = 13;
 * @return {number}
 */
proto.product.ProductInfo.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 quantity = 14;
 * @return {number}
 */
proto.product.ProductInfo.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string sku = 15;
 * @return {string}
 */
proto.product.ProductInfo.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setSku = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional double listed_price = 16;
 * @return {number}
 */
proto.product.ProductInfo.prototype.getListedPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setListedPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double discount_percent = 17;
 * @return {number}
 */
proto.product.ProductInfo.prototype.getDiscountPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setDiscountPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double price = 18;
 * @return {number}
 */
proto.product.ProductInfo.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional bool rental = 29;
 * @return {boolean}
 */
proto.product.ProductInfo.prototype.getRental = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setRental = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional string uom = 20;
 * @return {string}
 */
proto.product.ProductInfo.prototype.getUom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setUom = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string barcode = 21;
 * @return {string}
 */
proto.product.ProductInfo.prototype.getBarcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setBarcode = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional int32 weight = 22;
 * @return {number}
 */
proto.product.ProductInfo.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int32 volume = 25;
 * @return {number}
 */
proto.product.ProductInfo.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setVolume = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional int32 rating_value = 26;
 * @return {number}
 */
proto.product.ProductInfo.prototype.getRatingValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setRatingValue = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional int64 rating_count = 27;
 * @return {number}
 */
proto.product.ProductInfo.prototype.getRatingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setRatingCount = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional ProductCurrencyInfo currency = 28;
 * @return {?proto.product.ProductCurrencyInfo}
 */
proto.product.ProductInfo.prototype.getCurrency = function() {
  return /** @type{?proto.product.ProductCurrencyInfo} */ (
    jspb.Message.getWrapperField(this, proto.product.ProductCurrencyInfo, 28));
};


/**
 * @param {?proto.product.ProductCurrencyInfo|undefined} value
 * @return {!proto.product.ProductInfo} returns this
*/
proto.product.ProductInfo.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.product.ProductInfo.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional string brand = 39;
 * @return {string}
 */
proto.product.ProductInfo.prototype.getBrand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setBrand = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string description = 30;
 * @return {string}
 */
proto.product.ProductInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional bool limited = 31;
 * @return {boolean}
 */
proto.product.ProductInfo.prototype.getLimited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setLimited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional bool in_booking = 32;
 * @return {boolean}
 */
proto.product.ProductInfo.prototype.getInBooking = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setInBooking = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional string partnership_id = 33;
 * @return {string}
 */
proto.product.ProductInfo.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * map<string, string> additional_data = 34;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.product.ProductInfo.prototype.getAdditionalDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 34, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.clearAdditionalDataMap = function() {
  this.getAdditionalDataMap().clear();
  return this;};


/**
 * optional ProductType type = 35;
 * @return {!proto.product.ProductType}
 */
proto.product.ProductInfo.prototype.getType = function() {
  return /** @type {!proto.product.ProductType} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {!proto.product.ProductType} value
 * @return {!proto.product.ProductInfo} returns this
 */
proto.product.ProductInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 35, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.product.ProductCurrencyInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.product.ProductCurrencyInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.product.ProductCurrencyInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.ProductCurrencyInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.product.ProductCurrencyInfo}
 */
proto.product.ProductCurrencyInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.product.ProductCurrencyInfo;
  return proto.product.ProductCurrencyInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.product.ProductCurrencyInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.product.ProductCurrencyInfo}
 */
proto.product.ProductCurrencyInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.product.ProductCurrencyInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.product.ProductCurrencyInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.product.ProductCurrencyInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.ProductCurrencyInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.product.ProductCurrencyInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.ProductCurrencyInfo} returns this
 */
proto.product.ProductCurrencyInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.product.ProductCurrencyInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.ProductCurrencyInfo} returns this
 */
proto.product.ProductCurrencyInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.product.ProductCurrencyInfo.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.ProductCurrencyInfo} returns this
 */
proto.product.ProductCurrencyInfo.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string icon = 4;
 * @return {string}
 */
proto.product.ProductCurrencyInfo.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.ProductCurrencyInfo} returns this
 */
proto.product.ProductCurrencyInfo.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.product.CreateProductReq.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.product.CreateProductReq.prototype.toObject = function(opt_includeInstance) {
  return proto.product.CreateProductReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.product.CreateProductReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.CreateProductReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    trailerUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mediasList: jspb.Message.toObjectList(msg.getMediasList(),
    product_base_pb.Media.toObject, includeInstance),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    bookingStartTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    bookingEndTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 7, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 8, 0),
    sku: jspb.Message.getFieldWithDefault(msg, 9, ""),
    listedPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    discountPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    rental: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    uom: jspb.Message.getFieldWithDefault(msg, 13, ""),
    weight: jspb.Message.getFieldWithDefault(msg, 14, 0),
    volume: jspb.Message.getFieldWithDefault(msg, 15, 0),
    currency: (f = msg.getCurrency()) && proto.product.ProductCurrencyInfo.toObject(includeInstance, f),
    brand: jspb.Message.getFieldWithDefault(msg, 17, ""),
    description: jspb.Message.getFieldWithDefault(msg, 18, ""),
    limited: jspb.Message.getBooleanFieldWithDefault(msg, 19, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.product.CreateProductReq}
 */
proto.product.CreateProductReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.product.CreateProductReq;
  return proto.product.CreateProductReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.product.CreateProductReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.product.CreateProductReq}
 */
proto.product.CreateProductReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerUrl(value);
      break;
    case 3:
      var value = new product_base_pb.Media;
      reader.readMessage(value,product_base_pb.Media.deserializeBinaryFromReader);
      msg.addMedias(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingStartTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingEndTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setListedPrice(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDiscountPercent(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRental(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUom(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWeight(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 16:
      var value = new proto.product.ProductCurrencyInfo;
      reader.readMessage(value,proto.product.ProductCurrencyInfo.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrand(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLimited(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.product.CreateProductReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.product.CreateProductReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.product.CreateProductReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.CreateProductReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTrailerUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMediasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      product_base_pb.Media.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getBookingStartTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getBookingEndTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getListedPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getDiscountPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getRental();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getUom();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.product.ProductCurrencyInfo.serializeBinaryToWriter
    );
  }
  f = message.getBrand();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getLimited();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.product.CreateProductReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string trailer_url = 2;
 * @return {string}
 */
proto.product.CreateProductReq.prototype.getTrailerUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setTrailerUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Media medias = 3;
 * @return {!Array<!proto.product.Media>}
 */
proto.product.CreateProductReq.prototype.getMediasList = function() {
  return /** @type{!Array<!proto.product.Media>} */ (
    jspb.Message.getRepeatedWrapperField(this, product_base_pb.Media, 3));
};


/**
 * @param {!Array<!proto.product.Media>} value
 * @return {!proto.product.CreateProductReq} returns this
*/
proto.product.CreateProductReq.prototype.setMediasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.product.Media=} opt_value
 * @param {number=} opt_index
 * @return {!proto.product.Media}
 */
proto.product.CreateProductReq.prototype.addMedias = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.product.Media, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.clearMediasList = function() {
  return this.setMediasList([]);
};


/**
 * repeated string tags = 4;
 * @return {!Array<string>}
 */
proto.product.CreateProductReq.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional int64 booking_start_time = 5;
 * @return {number}
 */
proto.product.CreateProductReq.prototype.getBookingStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setBookingStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 booking_end_time = 6;
 * @return {number}
 */
proto.product.CreateProductReq.prototype.getBookingEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setBookingEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 duration = 7;
 * @return {number}
 */
proto.product.CreateProductReq.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 quantity = 8;
 * @return {number}
 */
proto.product.CreateProductReq.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string sku = 9;
 * @return {string}
 */
proto.product.CreateProductReq.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setSku = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional double listed_price = 10;
 * @return {number}
 */
proto.product.CreateProductReq.prototype.getListedPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setListedPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double discount_percent = 11;
 * @return {number}
 */
proto.product.CreateProductReq.prototype.getDiscountPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setDiscountPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional bool rental = 12;
 * @return {boolean}
 */
proto.product.CreateProductReq.prototype.getRental = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setRental = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string uom = 13;
 * @return {string}
 */
proto.product.CreateProductReq.prototype.getUom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setUom = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 weight = 14;
 * @return {number}
 */
proto.product.CreateProductReq.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 volume = 15;
 * @return {number}
 */
proto.product.CreateProductReq.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setVolume = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional ProductCurrencyInfo currency = 16;
 * @return {?proto.product.ProductCurrencyInfo}
 */
proto.product.CreateProductReq.prototype.getCurrency = function() {
  return /** @type{?proto.product.ProductCurrencyInfo} */ (
    jspb.Message.getWrapperField(this, proto.product.ProductCurrencyInfo, 16));
};


/**
 * @param {?proto.product.ProductCurrencyInfo|undefined} value
 * @return {!proto.product.CreateProductReq} returns this
*/
proto.product.CreateProductReq.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.product.CreateProductReq.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string brand = 17;
 * @return {string}
 */
proto.product.CreateProductReq.prototype.getBrand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setBrand = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string description = 18;
 * @return {string}
 */
proto.product.CreateProductReq.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional bool limited = 19;
 * @return {boolean}
 */
proto.product.CreateProductReq.prototype.getLimited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.product.CreateProductReq} returns this
 */
proto.product.CreateProductReq.prototype.setLimited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.product.UpdateProductReq.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.product.UpdateProductReq.prototype.toObject = function(opt_includeInstance) {
  return proto.product.UpdateProductReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.product.UpdateProductReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.UpdateProductReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    trailerUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mediasList: jspb.Message.toObjectList(msg.getMediasList(),
    product_base_pb.Media.toObject, includeInstance),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    bookingStartTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    bookingEndTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 8, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 9, 0),
    sku: jspb.Message.getFieldWithDefault(msg, 10, ""),
    listedPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    discountPercent: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    rental: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    uom: jspb.Message.getFieldWithDefault(msg, 14, ""),
    weight: jspb.Message.getFieldWithDefault(msg, 15, 0),
    volume: jspb.Message.getFieldWithDefault(msg, 16, 0),
    currency: (f = msg.getCurrency()) && proto.product.ProductCurrencyInfo.toObject(includeInstance, f),
    brand: jspb.Message.getFieldWithDefault(msg, 18, ""),
    description: jspb.Message.getFieldWithDefault(msg, 19, ""),
    limited: jspb.Message.getBooleanFieldWithDefault(msg, 20, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.product.UpdateProductReq}
 */
proto.product.UpdateProductReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.product.UpdateProductReq;
  return proto.product.UpdateProductReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.product.UpdateProductReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.product.UpdateProductReq}
 */
proto.product.UpdateProductReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailerUrl(value);
      break;
    case 4:
      var value = new product_base_pb.Media;
      reader.readMessage(value,product_base_pb.Media.deserializeBinaryFromReader);
      msg.addMedias(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingStartTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBookingEndTime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setListedPrice(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDiscountPercent(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRental(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setUom(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWeight(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVolume(value);
      break;
    case 17:
      var value = new proto.product.ProductCurrencyInfo;
      reader.readMessage(value,proto.product.ProductCurrencyInfo.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrand(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLimited(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.product.UpdateProductReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.product.UpdateProductReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.product.UpdateProductReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.UpdateProductReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTrailerUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMediasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      product_base_pb.Media.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getBookingStartTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getBookingEndTime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getListedPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getDiscountPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getRental();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getUom();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getVolume();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.product.ProductCurrencyInfo.serializeBinaryToWriter
    );
  }
  f = message.getBrand();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getLimited();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.product.UpdateProductReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.product.UpdateProductReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string trailer_url = 3;
 * @return {string}
 */
proto.product.UpdateProductReq.prototype.getTrailerUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setTrailerUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Media medias = 4;
 * @return {!Array<!proto.product.Media>}
 */
proto.product.UpdateProductReq.prototype.getMediasList = function() {
  return /** @type{!Array<!proto.product.Media>} */ (
    jspb.Message.getRepeatedWrapperField(this, product_base_pb.Media, 4));
};


/**
 * @param {!Array<!proto.product.Media>} value
 * @return {!proto.product.UpdateProductReq} returns this
*/
proto.product.UpdateProductReq.prototype.setMediasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.product.Media=} opt_value
 * @param {number=} opt_index
 * @return {!proto.product.Media}
 */
proto.product.UpdateProductReq.prototype.addMedias = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.product.Media, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.clearMediasList = function() {
  return this.setMediasList([]);
};


/**
 * repeated string tags = 5;
 * @return {!Array<string>}
 */
proto.product.UpdateProductReq.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional int64 booking_start_time = 6;
 * @return {number}
 */
proto.product.UpdateProductReq.prototype.getBookingStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setBookingStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 booking_end_time = 7;
 * @return {number}
 */
proto.product.UpdateProductReq.prototype.getBookingEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setBookingEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 duration = 8;
 * @return {number}
 */
proto.product.UpdateProductReq.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 quantity = 9;
 * @return {number}
 */
proto.product.UpdateProductReq.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string sku = 10;
 * @return {string}
 */
proto.product.UpdateProductReq.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setSku = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional double listed_price = 11;
 * @return {number}
 */
proto.product.UpdateProductReq.prototype.getListedPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setListedPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double discount_percent = 12;
 * @return {number}
 */
proto.product.UpdateProductReq.prototype.getDiscountPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setDiscountPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional bool rental = 13;
 * @return {boolean}
 */
proto.product.UpdateProductReq.prototype.getRental = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setRental = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string uom = 14;
 * @return {string}
 */
proto.product.UpdateProductReq.prototype.getUom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setUom = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int32 weight = 15;
 * @return {number}
 */
proto.product.UpdateProductReq.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 volume = 16;
 * @return {number}
 */
proto.product.UpdateProductReq.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setVolume = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional ProductCurrencyInfo currency = 17;
 * @return {?proto.product.ProductCurrencyInfo}
 */
proto.product.UpdateProductReq.prototype.getCurrency = function() {
  return /** @type{?proto.product.ProductCurrencyInfo} */ (
    jspb.Message.getWrapperField(this, proto.product.ProductCurrencyInfo, 17));
};


/**
 * @param {?proto.product.ProductCurrencyInfo|undefined} value
 * @return {!proto.product.UpdateProductReq} returns this
*/
proto.product.UpdateProductReq.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.product.UpdateProductReq.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string brand = 18;
 * @return {string}
 */
proto.product.UpdateProductReq.prototype.getBrand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setBrand = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string description = 19;
 * @return {string}
 */
proto.product.UpdateProductReq.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional bool limited = 20;
 * @return {boolean}
 */
proto.product.UpdateProductReq.prototype.getLimited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.product.UpdateProductReq} returns this
 */
proto.product.UpdateProductReq.prototype.setLimited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.product.DeleteProductReq.prototype.toObject = function(opt_includeInstance) {
  return proto.product.DeleteProductReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.product.DeleteProductReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.DeleteProductReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.product.DeleteProductReq}
 */
proto.product.DeleteProductReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.product.DeleteProductReq;
  return proto.product.DeleteProductReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.product.DeleteProductReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.product.DeleteProductReq}
 */
proto.product.DeleteProductReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.product.DeleteProductReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.product.DeleteProductReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.product.DeleteProductReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.DeleteProductReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.product.DeleteProductReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.DeleteProductReq} returns this
 */
proto.product.DeleteProductReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.product.PublicListProductReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.product.PublicListProductReq.prototype.toObject = function(opt_includeInstance) {
  return proto.product.PublicListProductReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.product.PublicListProductReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.PublicListProductReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    search: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagination: (f = msg.getPagination()) && product_base_pb.PaginationReq.toObject(includeInstance, f),
    orderByList: jspb.Message.toObjectList(msg.getOrderByList(),
    product_base_pb.OrderByItem.toObject, includeInstance),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.product.PublicListProductReq}
 */
proto.product.PublicListProductReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.product.PublicListProductReq;
  return proto.product.PublicListProductReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.product.PublicListProductReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.product.PublicListProductReq}
 */
proto.product.PublicListProductReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 2:
      var value = new product_base_pb.PaginationReq;
      reader.readMessage(value,product_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new product_base_pb.OrderByItem;
      reader.readMessage(value,product_base_pb.OrderByItem.deserializeBinaryFromReader);
      msg.addOrderBy(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.product.PublicListProductReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.product.PublicListProductReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.product.PublicListProductReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.PublicListProductReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      product_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getOrderByList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      product_base_pb.OrderByItem.serializeBinaryToWriter
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string search = 1;
 * @return {string}
 */
proto.product.PublicListProductReq.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.PublicListProductReq} returns this
 */
proto.product.PublicListProductReq.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PaginationReq pagination = 2;
 * @return {?proto.product.PaginationReq}
 */
proto.product.PublicListProductReq.prototype.getPagination = function() {
  return /** @type{?proto.product.PaginationReq} */ (
    jspb.Message.getWrapperField(this, product_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.product.PaginationReq|undefined} value
 * @return {!proto.product.PublicListProductReq} returns this
*/
proto.product.PublicListProductReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.product.PublicListProductReq} returns this
 */
proto.product.PublicListProductReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.product.PublicListProductReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated OrderByItem order_by = 3;
 * @return {!Array<!proto.product.OrderByItem>}
 */
proto.product.PublicListProductReq.prototype.getOrderByList = function() {
  return /** @type{!Array<!proto.product.OrderByItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, product_base_pb.OrderByItem, 3));
};


/**
 * @param {!Array<!proto.product.OrderByItem>} value
 * @return {!proto.product.PublicListProductReq} returns this
*/
proto.product.PublicListProductReq.prototype.setOrderByList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.product.OrderByItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.product.OrderByItem}
 */
proto.product.PublicListProductReq.prototype.addOrderBy = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.product.OrderByItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.product.PublicListProductReq} returns this
 */
proto.product.PublicListProductReq.prototype.clearOrderByList = function() {
  return this.setOrderByList([]);
};


/**
 * optional string partnership_id = 4;
 * @return {string}
 */
proto.product.PublicListProductReq.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.PublicListProductReq} returns this
 */
proto.product.PublicListProductReq.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.product.RetrieveProductReq.prototype.toObject = function(opt_includeInstance) {
  return proto.product.RetrieveProductReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.product.RetrieveProductReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.RetrieveProductReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.product.RetrieveProductReq}
 */
proto.product.RetrieveProductReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.product.RetrieveProductReq;
  return proto.product.RetrieveProductReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.product.RetrieveProductReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.product.RetrieveProductReq}
 */
proto.product.RetrieveProductReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.product.RetrieveProductReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.product.RetrieveProductReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.product.RetrieveProductReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.RetrieveProductReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.product.RetrieveProductReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.RetrieveProductReq} returns this
 */
proto.product.RetrieveProductReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.product.PublicRetrieveProductReq.prototype.toObject = function(opt_includeInstance) {
  return proto.product.PublicRetrieveProductReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.product.PublicRetrieveProductReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.PublicRetrieveProductReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.product.PublicRetrieveProductReq}
 */
proto.product.PublicRetrieveProductReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.product.PublicRetrieveProductReq;
  return proto.product.PublicRetrieveProductReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.product.PublicRetrieveProductReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.product.PublicRetrieveProductReq}
 */
proto.product.PublicRetrieveProductReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.product.PublicRetrieveProductReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.product.PublicRetrieveProductReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.product.PublicRetrieveProductReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.PublicRetrieveProductReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.product.PublicRetrieveProductReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.PublicRetrieveProductReq} returns this
 */
proto.product.PublicRetrieveProductReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string partnership_id = 2;
 * @return {string}
 */
proto.product.PublicRetrieveProductReq.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.PublicRetrieveProductReq} returns this
 */
proto.product.PublicRetrieveProductReq.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.product.InternalListProductByIdsReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.product.InternalListProductByIdsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.product.InternalListProductByIdsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.product.InternalListProductByIdsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.InternalListProductByIdsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.product.InternalListProductByIdsReq}
 */
proto.product.InternalListProductByIdsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.product.InternalListProductByIdsReq;
  return proto.product.InternalListProductByIdsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.product.InternalListProductByIdsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.product.InternalListProductByIdsReq}
 */
proto.product.InternalListProductByIdsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.product.InternalListProductByIdsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.product.InternalListProductByIdsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.product.InternalListProductByIdsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.InternalListProductByIdsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.product.InternalListProductByIdsReq.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.product.InternalListProductByIdsReq} returns this
 */
proto.product.InternalListProductByIdsReq.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.product.InternalListProductByIdsReq} returns this
 */
proto.product.InternalListProductByIdsReq.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.product.InternalListProductByIdsReq} returns this
 */
proto.product.InternalListProductByIdsReq.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.product.CreateProductRes.prototype.toObject = function(opt_includeInstance) {
  return proto.product.CreateProductRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.product.CreateProductRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.CreateProductRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.product.CreateProductRes}
 */
proto.product.CreateProductRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.product.CreateProductRes;
  return proto.product.CreateProductRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.product.CreateProductRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.product.CreateProductRes}
 */
proto.product.CreateProductRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.product.CreateProductRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.product.CreateProductRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.product.CreateProductRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.CreateProductRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.product.CreateProductRes.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.product.CreateProductRes} returns this
 */
proto.product.CreateProductRes.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.product.RetrieveProductRes.prototype.toObject = function(opt_includeInstance) {
  return proto.product.RetrieveProductRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.product.RetrieveProductRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.RetrieveProductRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.product.ProductInfo.toObject(includeInstance, f),
    bought: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    remainingTime: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.product.RetrieveProductRes}
 */
proto.product.RetrieveProductRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.product.RetrieveProductRes;
  return proto.product.RetrieveProductRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.product.RetrieveProductRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.product.RetrieveProductRes}
 */
proto.product.RetrieveProductRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.product.ProductInfo;
      reader.readMessage(value,proto.product.ProductInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBought(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRemainingTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.product.RetrieveProductRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.product.RetrieveProductRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.product.RetrieveProductRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.RetrieveProductRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.product.ProductInfo.serializeBinaryToWriter
    );
  }
  f = message.getBought();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRemainingTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional ProductInfo info = 1;
 * @return {?proto.product.ProductInfo}
 */
proto.product.RetrieveProductRes.prototype.getInfo = function() {
  return /** @type{?proto.product.ProductInfo} */ (
    jspb.Message.getWrapperField(this, proto.product.ProductInfo, 1));
};


/**
 * @param {?proto.product.ProductInfo|undefined} value
 * @return {!proto.product.RetrieveProductRes} returns this
*/
proto.product.RetrieveProductRes.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.product.RetrieveProductRes} returns this
 */
proto.product.RetrieveProductRes.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.product.RetrieveProductRes.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool bought = 2;
 * @return {boolean}
 */
proto.product.RetrieveProductRes.prototype.getBought = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.product.RetrieveProductRes} returns this
 */
proto.product.RetrieveProductRes.prototype.setBought = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int64 remaining_time = 3;
 * @return {number}
 */
proto.product.RetrieveProductRes.prototype.getRemainingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.RetrieveProductRes} returns this
 */
proto.product.RetrieveProductRes.prototype.setRemainingTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.product.PublicRetrieveProductRes.prototype.toObject = function(opt_includeInstance) {
  return proto.product.PublicRetrieveProductRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.product.PublicRetrieveProductRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.PublicRetrieveProductRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.product.ProductInfo.toObject(includeInstance, f),
    bought: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    remainingTime: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.product.PublicRetrieveProductRes}
 */
proto.product.PublicRetrieveProductRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.product.PublicRetrieveProductRes;
  return proto.product.PublicRetrieveProductRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.product.PublicRetrieveProductRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.product.PublicRetrieveProductRes}
 */
proto.product.PublicRetrieveProductRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.product.ProductInfo;
      reader.readMessage(value,proto.product.ProductInfo.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBought(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRemainingTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.product.PublicRetrieveProductRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.product.PublicRetrieveProductRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.product.PublicRetrieveProductRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.PublicRetrieveProductRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.product.ProductInfo.serializeBinaryToWriter
    );
  }
  f = message.getBought();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRemainingTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional ProductInfo info = 1;
 * @return {?proto.product.ProductInfo}
 */
proto.product.PublicRetrieveProductRes.prototype.getInfo = function() {
  return /** @type{?proto.product.ProductInfo} */ (
    jspb.Message.getWrapperField(this, proto.product.ProductInfo, 1));
};


/**
 * @param {?proto.product.ProductInfo|undefined} value
 * @return {!proto.product.PublicRetrieveProductRes} returns this
*/
proto.product.PublicRetrieveProductRes.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.product.PublicRetrieveProductRes} returns this
 */
proto.product.PublicRetrieveProductRes.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.product.PublicRetrieveProductRes.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool bought = 2;
 * @return {boolean}
 */
proto.product.PublicRetrieveProductRes.prototype.getBought = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.product.PublicRetrieveProductRes} returns this
 */
proto.product.PublicRetrieveProductRes.prototype.setBought = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int64 remaining_time = 3;
 * @return {number}
 */
proto.product.PublicRetrieveProductRes.prototype.getRemainingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.product.PublicRetrieveProductRes} returns this
 */
proto.product.PublicRetrieveProductRes.prototype.setRemainingTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.product.InternalListProductByIdsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.product.InternalListProductByIdsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.product.InternalListProductByIdsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.product.InternalListProductByIdsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.InternalListProductByIdsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    infosList: jspb.Message.toObjectList(msg.getInfosList(),
    proto.product.ProductInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.product.InternalListProductByIdsRes}
 */
proto.product.InternalListProductByIdsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.product.InternalListProductByIdsRes;
  return proto.product.InternalListProductByIdsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.product.InternalListProductByIdsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.product.InternalListProductByIdsRes}
 */
proto.product.InternalListProductByIdsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.product.ProductInfo;
      reader.readMessage(value,proto.product.ProductInfo.deserializeBinaryFromReader);
      msg.addInfos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.product.InternalListProductByIdsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.product.InternalListProductByIdsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.product.InternalListProductByIdsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.InternalListProductByIdsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.product.ProductInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProductInfo infos = 1;
 * @return {!Array<!proto.product.ProductInfo>}
 */
proto.product.InternalListProductByIdsRes.prototype.getInfosList = function() {
  return /** @type{!Array<!proto.product.ProductInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.product.ProductInfo, 1));
};


/**
 * @param {!Array<!proto.product.ProductInfo>} value
 * @return {!proto.product.InternalListProductByIdsRes} returns this
*/
proto.product.InternalListProductByIdsRes.prototype.setInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.product.ProductInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.product.ProductInfo}
 */
proto.product.InternalListProductByIdsRes.prototype.addInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.product.ProductInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.product.InternalListProductByIdsRes} returns this
 */
proto.product.InternalListProductByIdsRes.prototype.clearInfosList = function() {
  return this.setInfosList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.product.PublicListProductRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.product.PublicListProductRes.prototype.toObject = function(opt_includeInstance) {
  return proto.product.PublicListProductRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.product.PublicListProductRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.PublicListProductRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoList: jspb.Message.toObjectList(msg.getInfoList(),
    proto.product.ProductInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && product_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.product.PublicListProductRes}
 */
proto.product.PublicListProductRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.product.PublicListProductRes;
  return proto.product.PublicListProductRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.product.PublicListProductRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.product.PublicListProductRes}
 */
proto.product.PublicListProductRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.product.ProductInfo;
      reader.readMessage(value,proto.product.ProductInfo.deserializeBinaryFromReader);
      msg.addInfo(value);
      break;
    case 2:
      var value = new product_base_pb.PaginationRes;
      reader.readMessage(value,product_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.product.PublicListProductRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.product.PublicListProductRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.product.PublicListProductRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.product.PublicListProductRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.product.ProductInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      product_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProductInfo info = 1;
 * @return {!Array<!proto.product.ProductInfo>}
 */
proto.product.PublicListProductRes.prototype.getInfoList = function() {
  return /** @type{!Array<!proto.product.ProductInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.product.ProductInfo, 1));
};


/**
 * @param {!Array<!proto.product.ProductInfo>} value
 * @return {!proto.product.PublicListProductRes} returns this
*/
proto.product.PublicListProductRes.prototype.setInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.product.ProductInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.product.ProductInfo}
 */
proto.product.PublicListProductRes.prototype.addInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.product.ProductInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.product.PublicListProductRes} returns this
 */
proto.product.PublicListProductRes.prototype.clearInfoList = function() {
  return this.setInfoList([]);
};


/**
 * optional PaginationRes pagination = 2;
 * @return {?proto.product.PaginationRes}
 */
proto.product.PublicListProductRes.prototype.getPagination = function() {
  return /** @type{?proto.product.PaginationRes} */ (
    jspb.Message.getWrapperField(this, product_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.product.PaginationRes|undefined} value
 * @return {!proto.product.PublicListProductRes} returns this
*/
proto.product.PublicListProductRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.product.PublicListProductRes} returns this
 */
proto.product.PublicListProductRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.product.PublicListProductRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.product);
