import { jwtDecode } from 'jwt-decode';

import { getLocalStorageData, isServer } from '..';

export class JwtToken {
  private decodedToken: { email: string; exp: number; sub?: string };
  private localKey: string;
  private token: string;

  constructor(token: string, localKey?: string) {
    this.decodedToken = { email: '', exp: 0 };
    this.token = token ?? '';
    this.localKey = localKey ?? '';

    if (this.token) {
      this.decoded();
    }
  }

  decoded() {
    try {
      if (this.token) {
        this.decodedToken = jwtDecode(this.token);
      }
    } catch (e) {
      console.error('Decode token error:', e);
    }
  }

  isExpired() {
    const now = Math.floor(Date.now() / 1000);
    return now > this.decodedToken.exp;
  }

  isValid() {
    return this.token && !this.isExpired();
  }

  storeTokenToLocalStorage() {
    if (isServer) {
      return;
    }
    localStorage.setItem(this.localKey, this.token);
  }

  storeTokenToSessionStorage() {
    if (isServer) {
      return;
    }
    sessionStorage.setItem(this.localKey, this.token);
  }

  removeToken() {
    if (isServer) {
      return;
    }
    localStorage.removeItem(this.localKey);
  }

  readFromLocalStorage() {
    if (isServer) {
      return;
    }
    const localData = getLocalStorageData<string>(this.localKey);

    if (localData) {
      this.token = localData;
      this.decoded();
    }
  }

  get decodedData() {
    return this.decodedToken;
  }

  get remainingTime(): number {
    const now = Math.floor(Date.now() / 1000);
    return this.decodedToken.exp - now;
  }

  set setToken(value: string) {
    this.token = value;
  }
}
