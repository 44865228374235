/**
 * @fileoverview gRPC-Web generated client stub for customer.web
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: customer/web/customer.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')

var customer_customer_pb = require('../../customer/customer_pb.js')

var customer_base_pb = require('../../customer/base_pb.js')
const proto = {};
proto.customer = {};
proto.customer.web = require('./customer_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.customer.web.CustomerServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.customer.web.CustomerServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web.GetMeReq,
 *   !proto.customer.web.GetMeRes>}
 */
const methodDescriptor_CustomerService_GetMe = new grpc.web.MethodDescriptor(
  '/customer.web.CustomerService/GetMe',
  grpc.web.MethodType.UNARY,
  proto.customer.web.GetMeReq,
  proto.customer.web.GetMeRes,
  /**
   * @param {!proto.customer.web.GetMeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.customer.web.GetMeRes.deserializeBinary
);


/**
 * @param {!proto.customer.web.GetMeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.customer.web.GetMeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer.web.GetMeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web.CustomerServiceClient.prototype.getMe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web.CustomerService/GetMe',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetMe,
      callback);
};


/**
 * @param {!proto.customer.web.GetMeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer.web.GetMeRes>}
 *     Promise that resolves to the response
 */
proto.customer.web.CustomerServicePromiseClient.prototype.getMe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web.CustomerService/GetMe',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetMe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web.UpdateMeReq,
 *   !proto.base.Empty>}
 */
const methodDescriptor_CustomerService_UpdateMe = new grpc.web.MethodDescriptor(
  '/customer.web.CustomerService/UpdateMe',
  grpc.web.MethodType.UNARY,
  proto.customer.web.UpdateMeReq,
  base_base_pb.Empty,
  /**
   * @param {!proto.customer.web.UpdateMeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.customer.web.UpdateMeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web.CustomerServiceClient.prototype.updateMe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web.CustomerService/UpdateMe',
      request,
      metadata || {},
      methodDescriptor_CustomerService_UpdateMe,
      callback);
};


/**
 * @param {!proto.customer.web.UpdateMeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.Empty>}
 *     Promise that resolves to the response
 */
proto.customer.web.CustomerServicePromiseClient.prototype.updateMe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web.CustomerService/UpdateMe',
      request,
      metadata || {},
      methodDescriptor_CustomerService_UpdateMe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web.ChangePasswordReq,
 *   !proto.base.Empty>}
 */
const methodDescriptor_CustomerService_ChangePassword = new grpc.web.MethodDescriptor(
  '/customer.web.CustomerService/ChangePassword',
  grpc.web.MethodType.UNARY,
  proto.customer.web.ChangePasswordReq,
  base_base_pb.Empty,
  /**
   * @param {!proto.customer.web.ChangePasswordReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.customer.web.ChangePasswordReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web.CustomerServiceClient.prototype.changePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web.CustomerService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_CustomerService_ChangePassword,
      callback);
};


/**
 * @param {!proto.customer.web.ChangePasswordReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.Empty>}
 *     Promise that resolves to the response
 */
proto.customer.web.CustomerServicePromiseClient.prototype.changePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web.CustomerService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_CustomerService_ChangePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web.ChangePasswordV2Req,
 *   !proto.customer.web.ChangePasswordV2Res>}
 */
const methodDescriptor_CustomerService_ChangePasswordV2 = new grpc.web.MethodDescriptor(
  '/customer.web.CustomerService/ChangePasswordV2',
  grpc.web.MethodType.UNARY,
  proto.customer.web.ChangePasswordV2Req,
  proto.customer.web.ChangePasswordV2Res,
  /**
   * @param {!proto.customer.web.ChangePasswordV2Req} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.customer.web.ChangePasswordV2Res.deserializeBinary
);


/**
 * @param {!proto.customer.web.ChangePasswordV2Req} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.customer.web.ChangePasswordV2Res)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer.web.ChangePasswordV2Res>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web.CustomerServiceClient.prototype.changePasswordV2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web.CustomerService/ChangePasswordV2',
      request,
      metadata || {},
      methodDescriptor_CustomerService_ChangePasswordV2,
      callback);
};


/**
 * @param {!proto.customer.web.ChangePasswordV2Req} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer.web.ChangePasswordV2Res>}
 *     Promise that resolves to the response
 */
proto.customer.web.CustomerServicePromiseClient.prototype.changePasswordV2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web.CustomerService/ChangePasswordV2',
      request,
      metadata || {},
      methodDescriptor_CustomerService_ChangePasswordV2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web.CheckExistPhoneReq,
 *   !proto.customer.DefaultRes>}
 */
const methodDescriptor_CustomerService_CheckAlreadyExistPhone = new grpc.web.MethodDescriptor(
  '/customer.web.CustomerService/CheckAlreadyExistPhone',
  grpc.web.MethodType.UNARY,
  proto.customer.web.CheckExistPhoneReq,
  customer_base_pb.DefaultRes,
  /**
   * @param {!proto.customer.web.CheckExistPhoneReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_base_pb.DefaultRes.deserializeBinary
);


/**
 * @param {!proto.customer.web.CheckExistPhoneReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.customer.DefaultRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer.DefaultRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web.CustomerServiceClient.prototype.checkAlreadyExistPhone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web.CustomerService/CheckAlreadyExistPhone',
      request,
      metadata || {},
      methodDescriptor_CustomerService_CheckAlreadyExistPhone,
      callback);
};


/**
 * @param {!proto.customer.web.CheckExistPhoneReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer.DefaultRes>}
 *     Promise that resolves to the response
 */
proto.customer.web.CustomerServicePromiseClient.prototype.checkAlreadyExistPhone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web.CustomerService/CheckAlreadyExistPhone',
      request,
      metadata || {},
      methodDescriptor_CustomerService_CheckAlreadyExistPhone);
};


module.exports = proto.customer.web;

