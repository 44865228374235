// !REFACTOR
import { useEffect } from 'react';
import InputPhoneNumber, {
  InputPhoneNumberProps,
} from '../UI/InputPhoneNumber';
import TextField, { TextFieldProps } from './TextField';

import { useFormContext } from 'react-hook-form';

import { getCookie } from '@core/utils';
import { useCountry } from '@web-booker/hooks/apps';
import { IP_COUNTRY_DEFAULT, IP_COUNTRY_KEY } from '@web-booker/utils';

export function FormInputPhoneNumber({
  inputProps,
  defaultPhoneCode,
  placeholderCode,
  placeholderPhone,
  ...rest
}: Omit<TextFieldProps, 'placeholder'> & {
  inputProps?: Partial<Omit<InputPhoneNumberProps, 'onChange'>>;
  defaultPhoneCode?: boolean;
  placeholderCode?: InputPhoneNumberProps['placeholderCode'];
  placeholderPhone?: InputPhoneNumberProps['placeholderPhone'];
}) {
  const {
    trigger,
    formState: { defaultValues },
    setValue,
  } = useFormContext();
  const { phoneCodeOptions, formatPhoneCodeValue } = useCountry();

  useEffect(() => {
    if (phoneCodeOptions.length && defaultPhoneCode) {
      const defaultValue = defaultValues?.[rest.name];
      if (!defaultValue?.phoneCode) {
        let ipCountry = getCookie(IP_COUNTRY_KEY);
        if (!ipCountry || ipCountry === 'default') {
          ipCountry = IP_COUNTRY_DEFAULT;
        }
        const defaultPhoneValue = formatPhoneCodeValue(ipCountry);
        setValue(`${rest.name}.phoneCode`, defaultPhoneValue);
        trigger(`${rest.name}.phoneCode`);
      }
    }
  }, [phoneCodeOptions]);

  return (
    <TextField {...rest}>
      <InputPhoneNumber
        {...inputProps}
        phoneCodeOptions={phoneCodeOptions}
        placeholderCode={placeholderCode}
        placeholderPhone={placeholderPhone}
      />
    </TextField>
  );
}

export default FormInputPhoneNumber;
