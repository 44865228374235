import {
  ResetPasswordByPhoneReq,
  SendOtpByPhoneReq,
  SendOtpByPhoneRes,
  VerifyPhoneNumberReq,
} from '@api/customer/web/auth_phone_pb';
import { BaseApiOptions } from '@core/grpc-client';

import { Empty } from '@api/base/base_pb';
import { AuthPhoneServiceClient } from '@api/customer/web/auth_phone_grpc_web_pb';
import {
  VerifyOtpToResetPasswordByPhoneReq,
  VerifyOtpToResetPasswordByPhoneRes,
} from '@api/customer/web/auth_phone_pb';
import { BaseApi } from './base-api';

class AuthPhoneApi extends BaseApi<AuthPhoneServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new AuthPhoneServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  sendOtpByPhone = ({
    phoneCode,
    phoneNumber,
    otpType,
  }: SendOtpByPhoneReq.AsObject): Promise<SendOtpByPhoneRes.AsObject> => {
    const req = new SendOtpByPhoneReq();
    req.setPhoneCode(phoneCode);
    req.setPhoneNumber(phoneNumber);
    req.setOtpType(otpType);

    return this.grpc<
      SendOtpByPhoneReq,
      SendOtpByPhoneRes,
      SendOtpByPhoneRes.AsObject
    >(this.serviceClient?.sendOtpByPhone, req, {});
  };
  verifyPhoneNumber = ({
    phoneCode,
    phoneNumber,
    otp,
  }: VerifyPhoneNumberReq.AsObject): Promise<Empty.AsObject> => {
    const req = new VerifyPhoneNumberReq();
    req.setPhoneCode(phoneCode);
    req.setPhoneNumber(phoneNumber);
    req.setOtp(otp);
    return this.grpc<VerifyPhoneNumberReq, Empty, Empty.AsObject>(
      this.serviceClient?.verifyPhoneNumber,
      req,
      {}
    );
  };

  verifyOtpToResetPasswordByPhone = ({
    otp,
    phoneNumber,
    phoneCode,
  }: VerifyOtpToResetPasswordByPhoneReq.AsObject): Promise<VerifyOtpToResetPasswordByPhoneRes.AsObject> => {
    const req = new VerifyOtpToResetPasswordByPhoneReq();
    req.setOtp(otp);
    req.setPhoneCode(phoneCode);
    req.setPhoneNumber(phoneNumber);

    return this.grpc<
      VerifyOtpToResetPasswordByPhoneReq,
      VerifyOtpToResetPasswordByPhoneRes,
      VerifyOtpToResetPasswordByPhoneRes.AsObject
    >(this.serviceClient?.verifyOtpToResetPasswordByPhone, req, {});
  };

  resetPasswordByPhone = ({
    newPassword,
  }: ResetPasswordByPhoneReq.AsObject): Promise<Empty.AsObject> => {
    const req = new ResetPasswordByPhoneReq();
    req.setNewPassword(newPassword);

    return this.grpc<ResetPasswordByPhoneReq, Empty, Empty.AsObject>(
      this.serviceClient.resetPasswordByPhone,
      req,
      {
        useAnonymousToken: true,
      }
    );
  };
}

export const authPhoneServiceClientApi = new AuthPhoneApi();
export default authPhoneServiceClientApi;
