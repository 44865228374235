import { CheckFillIcon } from '@icon';
import { ToastProps } from '@web-booker/types';
import cn from 'classnames';

function Success({ message, className }: ToastProps) {
  return (
    <div className={cn([className, 'bg-common-success/5 text-common-success'])}>
      <CheckFillIcon className='mr-2 h-6 w-6 shrink-0 ' />

      {message}
    </div>
  );
}

export default Success;
