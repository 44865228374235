import { CustomAxiosResponse } from '@core/types';
import tixlabService from '../axios.tixlabs';
import {
  EPaymentQRMethod,
  TPaymentQRRes,
  TStatusPaymentQRRes,
} from '@tixlabs/types';

export const checkStatusPaymentQR = ({
  transaction_id,
  method,
}: {
  transaction_id: string;
  method: EPaymentQRMethod;
}): Promise<CustomAxiosResponse<TStatusPaymentQRRes>> => {
  return tixlabService.post(`/payment/verify-transaction`, {
    transaction_id,
    method,
  });
};

export const getPaymentQR = ({
  trans_id,
  method,
}: {
  trans_id: string;
  method: EPaymentQRMethod;
}): Promise<CustomAxiosResponse<TPaymentQRRes>> => {
  return tixlabService.post(`/payment/qr/get-code`, { trans_id, method });
};

export const uploadMedia = (data: FormData) => {
  return tixlabService.post(`/media/upload`, data);
};
