import React from 'react';

const NotFoundFlight = () => {
  return (
    <div className='space-y-3 py-6 bg-white'>
      <div className='flex justify-center'>
        <img
          src='./assets/images/flight-not-found.png'
          alt='not-found'
          className='w-40 h-40'
        />
      </div>
      <div className='space-y-2'>
        <div className='text-center text-md font-bold'>
          Không tìm thấy chuyến bay
        </div>
        <div className='text-center flex flex-col'>
          <span>
            Rất tiếc chúng tôi không tìm thấy chuyến bay nào phù hợp với bạn.
          </span>
          <span>Hãy thử chọn ngày bay khác.</span>
        </div>
      </div>
    </div>
  );
};

export default NotFoundFlight;
