import { useEffect } from 'react';
import { useAppLanguage } from '../internals';

import { getErrorMessageList } from '@tixlabs/axios-client';
import { useAppDispatch } from '@web-admin/stores';
import { updateErrors } from '@web-admin/stores/reducers/common';
import { BaseApi } from '@tixlabs/grpc-client/base-api';

export function useErrorMessage() {
  const { language } = useAppLanguage();
  const dispatch = useAppDispatch();

  async function fetchErrorMessageList() {
    try {
      const { data } = await getErrorMessageList({
        locale: language,
      });
      BaseApi.addMessageError(data);
      if (data) {
        dispatch(updateErrors(data));
      }
    } catch (error) {}
  }

  useEffect(() => {
    fetchErrorMessageList();
  }, [language]);

  return {};
}

export default useErrorMessage;
