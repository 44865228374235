import { JwtToken } from '@core/jwt';
import { LOCAL_STORAGE_KEY } from '@core/utils';

// import { getAccessTokenByRefreshToken } from 'api/user';

const PADDING_TIME = 60;

export class JwtTokenTixlabs {
  public jwtAccessToken: JwtToken;
  private jwtRefreshToken: JwtToken;
  private timerRefresh: number | null;

  // private logout: (() => void) | null;

  constructor(token: string, refreshToken: string, logoutFn?: () => void) {
    this.jwtAccessToken = new JwtToken(token, LOCAL_STORAGE_KEY.ACCESS_TOKEN);

    this.jwtAccessToken.storeTokenToLocalStorage();
    this.jwtAccessToken.storeTokenToSessionStorage();

    this.jwtRefreshToken = new JwtToken(
      refreshToken,
      LOCAL_STORAGE_KEY.REFRESH_TOKEN
    );
    this.jwtRefreshToken.storeTokenToLocalStorage();
    this.jwtRefreshToken.storeTokenToSessionStorage();

    this.timerRefresh = null;

    // this.logout = logoutFn ? logoutFn : null;
  }

  isAccessTokenExpired() {
    return this.jwtAccessToken.isExpired();
  }
  isRefreshTokenExpired() {
    return this.jwtRefreshToken.isExpired();
  }

  async fetchNewToken() {
    try {
      if (!this.jwtRefreshToken.isValid) {
        throw new Error('Token expired!');
      }

      // const { data } = await getAccessTokenByRefreshToken(
      //   this.jwtRefreshToken.getToken
      // );
      // if (data && data.accessToken && data.refreshToken) {
      //   this.jwtAccessToken.setToken = data.accessToken;
      //   this.jwtAccessToken.decoded();
      //   this.jwtAccessToken.storeToken();

      //   this.jwtRefreshToken.setToken = data.refreshToken;
      //   this.jwtRefreshToken.decoded();
      //   this.jwtRefreshToken.storeToken();

      //   this.startTimerRefreshToken();
      // } else {
      //   this.clearExpiredToken();
      // }
      this.clearExpiredToken();
    } catch (error) {
      console.error(error);
      this.clearExpiredToken();
    }
  }
  startTimerRefreshToken() {
    const restTime = this.jwtAccessToken.remainingTime - PADDING_TIME;

    if (restTime > 0) {
      if (this.timerRefresh) {
        clearTimeout(this.timerRefresh);
      }
      this.timerRefresh = window.setTimeout(() => {
        this.fetchNewToken();
      }, restTime * 1000);
    } else {
      this.fetchNewToken();
    }
  }

  clearExpiredToken() {
    this.jwtAccessToken.removeToken();
    this.jwtRefreshToken.removeToken();
  }
}
