// import { CalendarLineIcon } from '@/components/Icons'; x

import '../../../styles/react-datepicker.min.css';

import {
  Calendar2LineIcon,
  CrossLineIcon,
  PassPropsType,
  TBaseInputProps,
  configDatePicker,
  defaultConfigDatePicker,
  InputSize,
} from '@common-ui';

import cn from 'classnames';
import { ForwardedRef, forwardRef } from 'react';

import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

export type InputDateProps = TBaseInputProps &
  PassPropsType<Date | null> &
  Omit<ReactDatePickerProps, 'onChange'> & {
    allowClear?: boolean;
    onClear?: () => void;
  };

function InputDateInner(
  {
    // datePickerOptions,
    className,
    isError,
    isDirty,
    inputSize = InputSize.SM,
    value,
    allowClear,
    onClear,
    onChange,
    ...rest
  }: InputDateProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  // const { trigger } = useFormContext();
  const handleClear = () => {
    if (allowClear) {
      onChange && onChange(null);
      onClear && onClear();
    }
  };
  // useEffect(() => {
  //   if (value) {
  //     trigger(rest.name);
  //   }
  // }, [value]);
  return (
    <div
      className={cn(
        `base-input base-input-${inputSize}`,
        'inline-flex items-center focus-within:border-primary focus-within:outline-none',
        {
          error: isError,
          '!focus:border-theme-black/10 !bg-theme-black/5': rest.disabled,
        },
        className
      )}>
      <Calendar2LineIcon
        className={cn('mr-2 h-5 w-5 shrink-0 text-theme-black/50')}
      />

      {onChange && (
        <ReactDatePicker
          {...defaultConfigDatePicker}
          {...rest}
          placeholderText={rest.placeholder}
          onChange={onChange}
          selected={value}
          ref={(dateRef: any) => {
            (ref as any)?.({
              focus: () => {
                dateRef?.setFocus();
                dateRef?.input?.scrollIntoView?.({
                  block: 'center',
                });
              },
            });
          }}
          {...configDatePicker}
        />
      )}
      {allowClear && value && (
        <div className='cursor-pointer' onClick={handleClear}>
          <CrossLineIcon className='h-5 w-5 shrink-0 text-theme-black/50' />
        </div>
      )}
    </div>
  );
}
export const InputDate = forwardRef(InputDateInner);
InputDate.displayName = 'InputDate';

export default InputDate;
