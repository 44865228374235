import { LabelInput } from '@common-ui';
import { IBookingFlightContactInfo } from '@tixlabs/types';
import { PRONOUN_TYPE_LABEL } from '@web-booker/utils';

type Props = {
  data: IBookingFlightContactInfo;
};

export function OrderContactInfo({ data }: Props) {
  return (
    <div className='space-y-5'>
      <LabelInput
        label='Người liên hệ'
        labelClassName='text-neutral-7 '
        className='text-neutral-10 font-semibold'>
        <div className='whitespace-nowrap'>
          <span className='uppercase'>{PRONOUN_TYPE_LABEL[data.title]}</span>,{' '}
          {data.lastName} {data.firstName}
        </div>
      </LabelInput>
      <LabelInput
        label='Số điện thoại'
        labelClassName='text-neutral-7 '
        className='text-neutral-10 font-semibold'>
        <div>
          (+{data.phoneCode}) {data.phoneNumber}
        </div>
      </LabelInput>
      <LabelInput
        label='Email'
        labelClassName='text-neutral-7 '
        className='text-neutral-10 font-semibold'>
        {data.email}
      </LabelInput>

      <LabelInput
        label='Địa chỉ'
        labelClassName='text-neutral-7 '
        className='text-neutral-10 font-semibold'>
        {data.address}
      </LabelInput>

      <LabelInput
        label='Ghi chú'
        labelClassName='text-neutral-7 '
        className='text-neutral-10 font-semibold'>
        {data.note}
      </LabelInput>
    </div>
  );
}

export default OrderContactInfo;
