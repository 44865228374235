import { TLanguageNS } from '@web-booker/configs/i18n.config';
import { useTranslation } from 'react-i18next';

export function useAppTranslate(namespace?: TLanguageNS) {
  const t = useTranslation(namespace);

  return t;
}

export default useAppTranslate;
