import { useContext } from 'react';
import { HideContentContext } from './HideContentContext';

export default function useHideContent() {
  const currentHideContentContext = useContext(HideContentContext);

  if (!currentHideContentContext) {
    throw new Error(
      'useHideContent has to be used within <HideContentContext.Provider>'
    );
  }

  //
  return currentHideContentContext;
}
