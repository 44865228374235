import { UserStatus } from '@api/partner/base_pb';

export type EUserStatus = UserStatus;

export const EUserStatus = {
  ...UserStatus,
} as const;

export enum EStepChangePassword {
  CHANGE_PASSWORD,
  SUCCESS,
}
