import { InputDate, InputDateProps } from '../UI/InputDate';
import TextField, { TextFieldProps } from './TextField';

export function FormInputDate({
  inputProps = {},
  ...rest
}: TextFieldProps & {
  inputProps?: Partial<Omit<InputDateProps, 'onChange'>>;
  // inputProps?: Pick<
  // InputDateProps,
  //   'className' | 'selectButtonClassName' | 'disabled' | 'inputSize'
  // >;
}) {
  return (
    <TextField {...rest}>
      <InputDate {...inputProps} />
    </TextField>
  );
}

export default FormInputDate;
