import { ServiceFeeServiceClient } from '@api/airplane/web_partner/service_fee_grpc_web_pb';
import {
  ListFlightServiceFeeReq,
  ListFlightServiceFeeRes,
} from '@api/airplane/web_partner/service_fee_pb';
import { PaginationReq } from '@api/base/base_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class ServiceFeeApi extends BaseApi<ServiceFeeServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new ServiceFeeServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  listFlightServiceFee = ({
    pageLimit,
    pageNumber,
  }: PaginationReq.AsObject): Promise<ListFlightServiceFeeRes.AsObject> => {
    const req = new ListFlightServiceFeeReq();
    const pagination = new PaginationReq();
    pagination.setPageLimit(pageLimit);
    pagination.setPageNumber(pageNumber);
    req.setPagination(pagination);

    return this.grpc<
      ListFlightServiceFeeReq,
      ListFlightServiceFeeRes,
      ListFlightServiceFeeRes.AsObject
    >(this.serviceClient.listFlightServiceFee, req, {});
  };
}

export const serviceFeeService = new ServiceFeeApi();
export default serviceFeeService;
