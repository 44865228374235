import { IdType } from '@api/order/base_pb';
import { OrderServiceClient } from '@api/order/web/order_grpc_web_pb.d';
import {
  BuyDirectlyReq,
  BuyDirectlyRes,
  OrderCustomerInfo,
  Product,
} from '@api/order/web/order_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from './base-api';

export class OrderApi extends BaseApi<OrderServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new OrderServiceClient(this.url, null, this.optsDev);
  }

  buyDirectly = ({
    productsList,
    passengersList,
    customer,
    timezone,
  }: BuyDirectlyReq.AsObject): Promise<BuyDirectlyRes.AsObject> => {
    const req = new BuyDirectlyReq();
    const products: Product[] = [];

    for (let i = 0; i < productsList.length; i++) {
      const product = new Product();
      product.setProductId(productsList[i].productId);
      product.setQuantity(productsList[i].quantity);
      products.push(product);
    }
    req.setProductsList(products);

    const passengers: OrderCustomerInfo[] = [];
    for (let i = 0; i < passengersList.length; i++) {
      const passenger = new OrderCustomerInfo();
      passenger.setSalutation(passengersList[i]?.salutation as string);
      passenger.setName(passengersList[i]?.name as string);
      passenger.setEmail(passengersList[i]?.email as string);
      passenger.setPhoneCode(passengersList[i]?.phoneCode as string);
      passenger.setPhoneNumber(passengersList[i]?.phoneNumber as string);
      passenger.setIdNumber(passengersList[i]?.idNumber as string);
      passenger.setIdType(passengersList[i]?.idType as IdType);
      passenger.setIsChildren(passengersList[i].isChildren!);
      passenger.setIdExpiredAt(passengersList[i]?.idExpiredAt);
      passenger.setIdGrantCountry(passengersList[i].idGrantCountry);
      passenger.setNationality(passengersList[i].nationality);
      passenger.setDob(passengersList[i].dob);
      passenger.setGender(passengersList[i].gender);
      passengers.push(passenger);
    }
    req.setPassengersList(passengers);

    const customerContact = new OrderCustomerInfo();
    customerContact.setSalutation(customer?.salutation as string);
    customerContact.setName(customer?.name as string);
    customerContact.setEmail(customer?.email as string);
    customerContact.setPhoneCode(customer?.phoneCode as string);
    customerContact.setPhoneNumber(customer?.phoneNumber as string);
    req.setCustomer(customerContact);

    req.setTimezone(timezone);
    return this.grpc<BuyDirectlyReq, BuyDirectlyRes, BuyDirectlyRes.AsObject>(
      this.serviceClient?.buyDirectly,
      req,
      {}
    );
  };
}

export const OrderServiceClientApi = new OrderApi();
export default OrderServiceClientApi;
