import { AuthServiceClient } from '@api/customer/web_tour/auth_grpc_web_pb';
import {
  DirectlyRegisterReq,
  DirectlyRegisterRes,
} from '@api/customer/web_tour/auth_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from './base-api';

class AuthWebTourApi extends BaseApi<AuthServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new AuthServiceClient(this.url, null, this.optsDev);
  }

  buyDirectly = ({
    email,
    name,
    phoneCode,
    phoneNumber,
  }: DirectlyRegisterReq.AsObject): Promise<DirectlyRegisterRes.AsObject> => {
    const req = new DirectlyRegisterReq();
    req.setEmail(email);
    req.setName(name);
    req.setPhoneCode(phoneCode);
    req.setPhoneNumber(phoneNumber);

    return this.grpc<
      DirectlyRegisterReq,
      DirectlyRegisterRes,
      DirectlyRegisterRes.AsObject
    >(this.serviceClient?.directlyRegister, req, {});
  };
}

export const authWebTourServiceClientApi = new AuthWebTourApi();
export default authWebTourServiceClientApi;
