import { LANGUAGES_CODE } from '@tixlabs/types';
import { ModalProps } from 'antd';

export enum ECurrency {
  VND = 'vnd',
  USD = 'usd',
}

export interface ILanguageData {
  code: LANGUAGES_CODE;
  text: string;
}

export enum EModalMode {
  CONFIRM,
  SUCCESS,
  WARNING,
  ERROR,
  ACTION,
}

export interface IModal extends ModalProps {
  mode?: EModalMode;
}
