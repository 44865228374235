/**
 * @fileoverview gRPC-Web generated client stub for product.web
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: product/web/tour.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var product_product_pb = require('../../product/product_pb.js')
const proto = {};
proto.product = {};
proto.product.web = require('./tour_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.product.web.TourServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.product.web.TourServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.product.web.PublicListTourProductReq,
 *   !proto.product.web.PublicListTourProductRes>}
 */
const methodDescriptor_TourService_PublicListTourProduct = new grpc.web.MethodDescriptor(
  '/product.web.TourService/PublicListTourProduct',
  grpc.web.MethodType.UNARY,
  proto.product.web.PublicListTourProductReq,
  proto.product.web.PublicListTourProductRes,
  /**
   * @param {!proto.product.web.PublicListTourProductReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.product.web.PublicListTourProductRes.deserializeBinary
);


/**
 * @param {!proto.product.web.PublicListTourProductReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.product.web.PublicListTourProductRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.product.web.PublicListTourProductRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.product.web.TourServiceClient.prototype.publicListTourProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/product.web.TourService/PublicListTourProduct',
      request,
      metadata || {},
      methodDescriptor_TourService_PublicListTourProduct,
      callback);
};


/**
 * @param {!proto.product.web.PublicListTourProductReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.product.web.PublicListTourProductRes>}
 *     Promise that resolves to the response
 */
proto.product.web.TourServicePromiseClient.prototype.publicListTourProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/product.web.TourService/PublicListTourProduct',
      request,
      metadata || {},
      methodDescriptor_TourService_PublicListTourProduct);
};


module.exports = proto.product.web;

