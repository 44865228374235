import * as grpcWeb from 'grpc-web';

import * as order_web_order_pb from '../../order/web/order_pb';


export class OrderServiceClient {
  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; });

  buyDirectly(
    request: order_web_order_pb.BuyDirectlyReq,
    metadata: grpcWeb.Metadata | undefined,
    callback: (err: grpcWeb.RpcError,
               response: order_web_order_pb.BuyDirectlyRes) => void
  ): grpcWeb.ClientReadableStream<order_web_order_pb.BuyDirectlyRes>;

  placeOrder(
    request: order_web_order_pb.PlaceOrderReq,
    metadata: grpcWeb.Metadata | undefined,
    callback: (err: grpcWeb.RpcError,
               response: order_web_order_pb.PlaceOrderRes) => void
  ): grpcWeb.ClientReadableStream<order_web_order_pb.PlaceOrderRes>;

  retrieveOrder(
    request: order_web_order_pb.RetrieveOrderReq,
    metadata: grpcWeb.Metadata | undefined,
    callback: (err: grpcWeb.RpcError,
               response: order_web_order_pb.RetrieveOrderRes) => void
  ): grpcWeb.ClientReadableStream<order_web_order_pb.RetrieveOrderRes>;

}

export class OrderServicePromiseClient {
  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; });

  buyDirectly(
    request: order_web_order_pb.BuyDirectlyReq,
    metadata?: grpcWeb.Metadata
  ): Promise<order_web_order_pb.BuyDirectlyRes>;

  placeOrder(
    request: order_web_order_pb.PlaceOrderReq,
    metadata?: grpcWeb.Metadata
  ): Promise<order_web_order_pb.PlaceOrderRes>;

  retrieveOrder(
    request: order_web_order_pb.RetrieveOrderReq,
    metadata?: grpcWeb.Metadata
  ): Promise<order_web_order_pb.RetrieveOrderRes>;

}

