import AxiosService from '@core/axios/axios.base';
import { BASE_API, PARTNERSHIP_ID } from '@core/constants';
import axios, { AxiosInstance } from 'axios';

class AxiosTixlabsService extends AxiosService {
  private static asInstance: AxiosService;

  private constructor() {
    const instance: AxiosInstance = axios.create({
      baseURL: BASE_API,
      timeout: 20000,
      headers: {
        Accept: 'application/json',
        'x-partnership-id': PARTNERSHIP_ID || '',
      },
    });

    super(instance);
  }

  public static getInstance(): AxiosTixlabsService {
    if (!AxiosTixlabsService.asInstance) {
      AxiosTixlabsService.asInstance = new AxiosTixlabsService();
    }
    return AxiosTixlabsService.asInstance;
  }
}

export default AxiosTixlabsService.getInstance();
