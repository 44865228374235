import { Card, FormCheckbox, Ticket2FillIcon } from '@common-ui';

import { IFormFilterFlight } from '@web-booker/types/ui/flight';
import { useFieldArray, useFormContext } from 'react-hook-form';

type Props = {
  //
};

const TicketClass = (props: Props) => {
  const methods = useFormContext<IFormFilterFlight>();
  const { fields } = useFieldArray({
    control: methods.control,
    name: 'ticketClass',
  });

  if (fields.length === 0) return;

  return (
    <div className='shrink-0'>
      <Card
        isCollapse
        icon={<Ticket2FillIcon />}
        title='Loại vé'
        classNameTitle='bg-[#E8E8E8]'
        classNameChildren='p-2.5'
        defaultShow>
        <div className='flex min-w-[340px] flex-col space-y-2.5'>
          {fields.map((item, index) => {
            return (
              <FormCheckbox
                key={item.id}
                className='col-span-1'
                name={`ticketClass[${index}].isSelected`}
                label={item.label}
              />
            );
          })}
        </div>
      </Card>
    </div>
  );
};

export default TicketClass;
