export enum EMappingType {
  TEXT,
  INPUT,
  SELECT,
  RENDER,
}

export interface IFormControlSelectOptions<TValue = string | number | boolean> {
  label: string;
  value: TValue;
  disabled?: boolean;
}

export type TInputRadioValue =
  | string
  | number
  | boolean
  | readonly string[]
  | undefined;
export interface IFormControlGroupRadioOptions<TValue = TInputRadioValue> {
  label: string;
  value: TValue;
  disabled?: boolean;
}

export type TInputCheckboxValue =
  | string
  | number
  | readonly string[]
  | undefined;
export interface IFormControlGroupCheckboxOptions<
  TValue = TInputCheckboxValue
> {
  label: string;
  value: TValue;
  disabled?: boolean;
}

export type TPhoneValue = {
  phoneCode?: string;
  phoneNumber?: string;
};

export type TFromToDate = {
  startDate?: Date | null;
  endDate?: Date | null;
};
