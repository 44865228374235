import { LANGUAGES_CODE } from '@tixlabs/types';
import { Callback } from 'i18next';
// import useAppRouter from './useAppRouter';
import useAppTranslate from './useAppTranslate';

export function useAppLanguage() {
  const {
    i18n: { language, changeLanguage: changeLanguageI18n },
  } = useAppTranslate();
  // const router = useAppRouter();

  function changeLanguage(lng: LANGUAGES_CODE, callback?: Callback) {
    const { pathname, search } = window.location;
    // router.shallow(`${pathname.replace(language, lng)}${search || ''}`);
    return changeLanguageI18n(lng, callback);
  }

  return {
    language: language as LANGUAGES_CODE,
    changeLanguage,
  };
}
