import { OrderByItem, PaginationReq } from '@api/product/base_pb';
import { TourServiceClient } from '@api/product/web/tour_grpc_web_pb';
import {
  PublicListTourProductReq,
  PublicListTourProductRes,
} from '@api/product/web/tour_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from './base-api';

export class PublicListTourApi extends BaseApi<TourServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new TourServiceClient(this.url, null, this.optsDev);
  }

  publicListTourProduct = ({
    search,
    orderByList,
    pagination,
  }: PublicListTourProductReq.AsObject): Promise<PublicListTourProductRes.AsObject> => {
    const req = new PublicListTourProductReq();
    req.setSearch(search);

    //orderByList
    const item = new OrderByItem();
    req.setOrderByList([item]);

    //pagination
    const paginationObj = new PaginationReq();
    req.setPagination(paginationObj);

    return this.grpc<
      PublicListTourProductReq,
      PublicListTourProductRes,
      PublicListTourProductRes.AsObject
    >(this.serviceClient?.publicListTourProduct, req, {});
  };
}

export const tourApiService = new PublicListTourApi();
export default tourApiService;
