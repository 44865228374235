import { isServer } from '.';
import dayjs from 'dayjs';
const key = 'unknow';
export const LOCAL_STORAGE_KEY = {
  ACCESS_TOKEN: `${key}_access_token`,
  REFRESH_TOKEN: `${key}_refresh_token`,
  TOKEN_TYPE: `${key}_token_type`,
  ACCESS_TOKEN_ANONYMOUS: `${key}_access_token_anonymous`,
  REFRESH_TOKEN_ANONYMOUS: `${key}_refresh_token_anonymous`,
  TESTER_KEY: `${key}_tester_key`,
  ERROR_MESSAGE_DATA: `${key}_error_message`,
} as const;

export const SESSION_STORAGE_KEY = {
  ACCESS_TOKEN: `${key}_access_token`,
  REFRESH_TOKEN: `${key}_refresh_token`,
  TOKEN_TYPE: `${key}_token_type`,
  ACCESS_TOKEN_ANONYMOUS: `${key}_access_token_anonymous`,
  REFRESH_TOKEN_ANONYMOUS: `${key}_refresh_token_anonymous`,
  TESTER_KEY: `${key}_tester_key`,
} as const;

export const getLocalStorageData = <T>(key: string): T | null | string => {
  if (!key || isServer) {
    return null;
  }

  const storageData = window.localStorage.getItem(key);
  if (!storageData) {
    return null;
  }

  try {
    const data: T = JSON.parse(storageData);
    return data;
  } catch (error) {
    // console.error(error);
    return storageData ?? null;
  }
};

export const setLocalStorageData = (
  key: string,
  value: any,
  expireTime?: number
) => {
  if (key && !isServer) {
    //expire time should be in 30 minutes
    const data = JSON.stringify({
      data: value,
      expireTime: expireTime ?? dayjs().unix() * 1000 + 1800000,
    });
    window.localStorage.setItem(key, data);
  }
};

export const getSessionStorageData = <T>(key: string): T | null | string => {
  if (!key || isServer) {
    return null;
  }

  const storageData = window.sessionStorage.getItem(key);
  if (!storageData) {
    return null;
  }

  try {
    const data: T = JSON.parse(storageData);
    return data;
  } catch (error) {
    // console.error(error);
    return storageData ?? null;
  }
};

export const setSessionStorageData = (
  key: string,
  value: any,
  expireTime?: number
) => {
  if (key && !isServer) {
    //expire time should be in 30 minutes
    const data = JSON.stringify({
      data: value,
      expireTime: expireTime ?? dayjs().unix() * 1000 + 1800000,
    });
    window.sessionStorage.setItem(key, data);
  }
};
