import { Card, DisplayModeIcon, FormGroupRadio } from '@common-ui';

import { EFilterDisplayMode } from '@web-booker/types/ui/flight';

type Props = {
  //
};

const DISPLAY_MODE_OPTION = [
  {
    label: 'Tổng giá cho tổng hành khách',
    value: EFilterDisplayMode.TOTAL_PASSENGER,
  },
  { label: 'Giá cơ bản cho 1 người lớn', value: EFilterDisplayMode.BASIC_ADT },
  {
    label: 'Giá + thuế, phí cho 1 người lớn',
    value: EFilterDisplayMode.TOTAL_PER_ADT,
  },
];

const DisplayMode = (props: Props) => {
  return (
    <Card
      isCollapse
      icon={<DisplayModeIcon />}
      title='Chế độ hiển thị'
      classNameTitle='bg-[#E8E8E8]'
      classNameChildren='p-2.5'
      defaultShow>
      <div className='flex flex-col space-y-6'>
        <div className='space-y-1'>
          <div className='text-sm font-semibold'>Giá vé</div>
          <FormGroupRadio
            name='displayMode'
            className='space-y-2.5'
            radioOptions={DISPLAY_MODE_OPTION}
          />
        </div>
      </div>
    </Card>
  );
};

export default DisplayMode;
