import i18n from '../../configs/i18n.config';
// import { useRouter } from 'next/router';
import { I18nextProvider } from 'react-i18next';
// import { isServer } from '@/utils';

type Props = {
  children: React.ReactNode;
};

export function TranslationProvider({ children }: Props) {
  // const router = useRouter();

  // if (isServer) {
  //   i18n.changeLanguage(router.locale);
  // }

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}
